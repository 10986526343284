import React, { Component, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { ShoppingOutlined } from '@ant-design/icons';
import { Layout, Col, Row, Typography, Badge } from 'antd';


//componentes
import MyHeader from './Header';
import FooterSne from './Footer';
import { User } from "../../Hooks/Logged"
import { Carrito, SetCarrito } from "../../Hooks/Carrito"
import FloatingButton from "../Widgets/Floating Button/FloatingButton";

//styles
import '../../Styles/Modules/Landing.scss'

const { Content } = Layout
const { Title, Text, Paragraph } = Typography

const Section = (props) => <section {...props}>{props.children}</section>


class TerminosCondiciones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            openDrawer: false
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <Layout style={{
                    background: 'rgb(127,135,203)',
                    background: 'linear-gradient(180deg, rgba(127,135,203,1) 0%, rgba(255,255,255,1) 100%)',
                    height: '100vh',
                }}
                    className='landing'>
                    <MyHeader
                        {...this.props}
                        openDrawer={this.state.openDrawer}
                        setOpenDrawer={(value) => this.setState({ openDrawer: value })}
                    />
                    <Content >
                        <Row justify="center" style={{ marginTop: "130px", marginBottom: "130px" }}>
                            <Col xs={23} md={20}>
                                <Title level={3}>TERMS AND CONDITIONS</Title>
                                <Title level={5}>Welcome to SneaCare, Say Hello To Clean Shoes!</Title>
                                <Paragraph>These are the terms and conditions for:</Paragraph>
                                <ul>
                                    <li>SneaCare App (Available on Google Play and App store).</li>
                                    <li><a href="https://sneacaremiami.com" target="_blank" rel="noopener noreferrer">https://sneacaremiami.com</a></li>
                                </ul>
                                <Paragraph>
                                    By using the platform and services, you agree to be bound by these terms and conditions and our privacy policy. In these terms and conditions, the words “platform” refer to the SneaCare app and website together, “we”, “us”, “our” and “SneaCare” refer to SneaCare and “you” and “user” refer to you, the SneaCare user.
                                </Paragraph>
                                <Paragraph>
                                    The following terms and conditions apply to the platform and services offered by SneaCare. This includes the mobile and tablet versions as well as any other version of SneaCare accessible via desktop, mobile, tablet, social media, or other devices.
                                </Paragraph>
                                <Text strong>
                                    READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING OR OBTAINING ANY INFORMATION OR SERVICE FROM SNEACARE.
                                </Text>
                                <Title level={5}>ELIGIBLITY</Title>
                                <Paragraph>You may use the platform and purchase the services only in compliance with these terms and all applicable local, state, national, and international laws, rules, and regulations.</Paragraph>
                                <Paragraph>Access and registration to the platform and purchase of services are available for all ages. It is the responsibility of parents and legal guardians to determine whether any of the content and services are appropriate for their children or minors in custody. </Paragraph>
                                <Paragraph>By using the platform and purchase the services, you represent and warrant that you have the full right, power and authority to enter into these terms and to fully perform all of your obligations hereunder. You further represent and warrant that you are under no legal disability or contractual restriction that prevents you from entering into these terms.</Paragraph>
                                <Paragraph>You represent and warrant that your use of the platform does not violate any applicable law or regulation. SneaCare may, in its sole discretion, refuse to offer the platform and services to any user and change its eligibility criteria at any time. This provision is void where prohibited by law and the right to access the service and the platform is revoked in such jurisdictions.</Paragraph>
                                <Paragraph>Promotions, contests and sweepstakes are subject to their own terms and conditions and may be terminated without notice. Before entering any contest or sweepstakes, please refer to the terms and conditions of each contest or sweepstakes, which shall form an integral part of these terms and conditions.</Paragraph>
                                <Title level={5}>ACCOUNT</Title>

                                <Paragraph>When registering on the platform, the user must choose a password and may be asked for additional information such as email address. You are responsible for maintaining the confidentiality of your password and account information, and are fully responsible for all activities that occur under your password or account. You agree to (a) immediately notify SneaCare of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you log out of your account at the end of each session. You may never use another user’s account without SneaCare’ prior authorization. SneaCare will not be liable for any loss or damage arising from your breach of this agreement.</Paragraph>

                                <Paragraph>Users may cancel their accounts at any time and for any reason by sending us their request through our contact information. Such cancellation will only result in the deletion of the account and the deletion of all personal data provided to SneaCare.</Paragraph>

                                <Paragraph>SneaCare reserves the right to terminate your account or your access immediately, with or without notice, and without liability to you, if SneaCare believes that you have violated any of these terms, provided SneaCare with false or misleading information, or interfered with any other party’s use of the platform or service.</Paragraph>

                                <Title level={5}>NOTIFICATIONS AND NEWSLETTER</Title>
                                <Paragraph>By providing SneaCare with your email address and phone number, you agree that we may use your email address to send you notifications about the status of your order, important communications about our service or services, news and special content. We may also use your email address and phone number to send you notifications via SMS messages, push notifications and other messages, such as changes to service features, service news and special offers on our services. If you do not wish to receive these emails, SMS messages or push notifications, you may opt-out of receiving them by sending us your request through our contact information or by using the “unsubscribe” option in emails or by replying with the word “STOP” to SMS messages. Opting out may prevent you from receiving emails, SMS messages and push notifications about updates to your order, news or special offers on our services.</Paragraph>

                                <Title level={5}>SNEACARE, SAY HELLO TO CLEAN SHOES</Title>
                                <Paragraph>We are a shoe cleaning company that is committed to make cleaner and cooler steps by bringing the best service to your shoes, sneakers or anything to walk on.</Paragraph>
                                <Paragraph>How it works:</Paragraph>
                                <ol>
                                    <li><strong>Booking:</strong> Create an order and schedule your preferred pickup date and time through our App or website.</li>
                                    <li><strong>Pick up:</strong> A specialist will pick up your service in detail. Your shoes are transported to our SneaCare Center. Once there, we proceed to sort, document and assign cleaning technicians.</li>
                                    <li><strong>Cleaning:</strong> Our four-step cleaning process includes diagnosis, preparation, cleaning and detailing. All shoes are hand-washed by trained SneaCare technicians using only specialized products and finishing with a final quality control by our service technician.</li>
                                    <li><strong>Delivery:</strong> Your clean and fresh sneakers are photographically documented and packaged for delivery to you by our delivery team. You will be notified with a message to your cell phone.</li>
                                </ol>
                                <Paragraph><Text strong>Important:</Text> Our standard turnaround time for service is approximately one week from the pick-up of the tennis shoes. In case the order takes longer, please contact us through our contact information.</Paragraph>
                                <Paragraph>When you place an order, you offer to buy the services for the price advertised and indicated on the platform at the time of purchase. Check prices and service features before placing your order.</Paragraph>
                                <Paragraph>When a customer places an order, SneaCare will send that customer an email which aims to confirm the purchase and payment. This email confirmation will be produced automatically so that the user has the confirmation of his purchase and the details of the order.</Paragraph>
                                <Paragraph>SneaCare may cancel any order and not supply services if it is reasonable to do so and may change or discontinue the availability of services at any time in its sole discretion. If an order is cancelled, any payments made for services will be refunded in full. This does not affect your statutory rights.</Paragraph>
                                <Paragraph>All new orders are considered separately and each is treated individually.</Paragraph>

                                <Title level={5}>SATISFACTION AND GUARANTEE</Title>

                                <Paragraph>In case the customer is not satisfied with the cleaning service provided, SneaCare will offer a second cleaning free of charge. If the customer is still not satisfied with the second cleaning, SneaCare will make an assessment according to the reasonable facts provided by the customer and if no agreement is reached, SneaCare will refund 20% of the total order.</Paragraph>
                                <Paragraph>If for any reason or extraordinary reason, SneaCare damages a pair of sneakers or any of them, SneaCare will proceed to perform an internal valuation of the pair of shoes with the conditions in which the sneakers were received and provide a fair compensation for the pair of shoes to the affected customer. Compensation for damage may be paid by cash, wire transfer, or credit facility.</Paragraph>
                                <Paragraph>SneaCare is not responsible for scuffs, stains or any kind of damage to the sneakers once the order has been delivered.</Paragraph>
                                <Paragraph>These warranties do not apply to any pair of sneakers of illegitimate manufacture or purchase.</Paragraph>

                                <Title level={5}>PRICES</Title>
                                <Paragraph>SneaCare reserves the right to determine the price for the services. Service prices are subject to change at any time according to the value of exchange rates.</Paragraph>
                                <Paragraph>SneaCare will make reasonable efforts to keep the price information published on the platform up to date. We encourage you to check our platform periodically for current pricing information.</Paragraph>
                                <Paragraph>Will always try to make sure that the prices on the platform is accurate. However, some errors in terms of price may occur from time to time, including but not limited to human error, mechanical error or the like. Should an error in pricing be discovered, the customer will be informed of such error. The option to reconfirm the order will be given to the user at the correct price. The user also has the option to cancel the order should the user not be satisfied with the correct price communicated on the said service order.</Paragraph>

                                <Title level={5}>PAYMENTS</Title>
                                <Paragraph>Services will be paid through the following payment methods:</Paragraph>
                                <ul>
                                    <li>Credit/debit card (Visa, Master, Discover, Amex, Diners, etc.)</li>
                                </ul>
                                <Paragraph>Payments will be processed by our payment processor Stripe. Payment will be charged to the customer’s credit/debit card immediately after placing the order for the service(s) purchased. Once the transaction has been processed, we will send an electronic receipt of the transaction to the customer’s email address.</Paragraph>
                                <Paragraph>If you find any inconsistency in your billing, please contact us through our contact information or you can make the claim through the customer service of the corresponding payment platform.</Paragraph>
                                <Paragraph>If your card is declined, you will receive an error message. No payment will be charged to your card and no order will be processed. There may be a pending transaction on your account until your card issuing bank withdraws the authorization. This usually takes 2 to 5 business days. Your card may be declined for various reasons such as insufficient funds, AVS (Address Verification System) mismatch or you have entered an incorrect security code.</Paragraph>
                                <Paragraph>If your payment is declined, you must provide an alternative payment method or provide another card where payment can be charged and processed.</Paragraph>
                                <Paragraph>Your payment information will be treated and safeguarded with total security and with the exclusive purpose of processing the purchase of the services. SneaCare reserves the right to contract any payment platform available in the market, which treats your data for the exclusive purpose of processing the purchase of the services.</Paragraph>

                                <Title level={5}>DISCLAIMER</Title>
                                <Paragraph>By accessing the platform and the content available on the platform, you accept personal responsibility for the results of using the information available on the content.  You agree that SneaCare has not guaranteed the results of any actions taken, whether or not advised by this platform or the content. SneaCare provides resources and content for informational purposes only. We do not guarantee that the information available on the platform is accurate, complete or updated. The content of this platform is provided for general information and should not be taken as a professional advice. Any use of the material provided on this platform is at your own risk.</Paragraph>
                                <Title level={5}>THIRD-PARTY MATERIALS.</Title>
                                <Paragraph>“Third-Party Materials” means any content, images, videos, texts or other material that is owned by a third party, such as stock images, videos and texts. Such Third-Party Materials are subject to the applicable third-party terms and licenses, and may only be used as permitted by such terms and licenses.</Paragraph>
                                <Title level={5}>COPYRIGHT</Title>
                                <Paragraph>All materials on SneaCare, including, without limitation, names, logos, trademarks, images, text, columns, graphics, videos, photographs, illustrations, software and other elements are protected by copyrights, trademarks and/or other intellectual property rights owned and controlled by SneaCare or by third parties that have licensed or otherwise provided their material to the platform. You acknowledge and agree that all materials on SneaCare are made available for limited, non-commercial, personal use only. Except as specifically provided herein. No material may be copied, reproduced, republished, sold, downloaded, posted, transmitted, or distributed in any way, or otherwise used for any purpose, by any person or entity, without SneaCare prior express written permission. You may not add, delete, distort, or otherwise modify the material. Any unauthorized attempt to modify any material, to defeat or circumvent any security features, or to utilize SneaCare or any part of the material for any purpose other than its intended purposes is strictly prohibited.</Paragraph>

                                <Title level={5}>COPYRIGHT INFRINGEMENT </Title>
                                <Paragraph>
                                    SneaCare will respond to all inquiries, complaints, and claims regarding alleged infringement for failure to comply with or violate the provisions contained in the Digital Millennium Copyright Act (DMCA). SneaCare respects the intellectual property of others, and expects users to do the same.
                                    If you believe, in good faith, that any material provided on or in connection with the SneaCare platform infringes your copyright or other intellectual property right, please send us your copyright infringement request pursuant to Section 512 of the Digital Millennium Copyright Act (DMCA), via our contact information, with the following information:
                                </Paragraph>
                                <ul>
                                    <li>Identification of the intellectual property right that is allegedly infringed. All relevant registration numbers or a statement regarding ownership of the work should be included.</li>
                                    <li>A statement that specifically identifies the location of the infringing material, in sufficient detail so that SneaCare can find it on the platform.</li>
                                    <li>Your name, address, telephone number, and email address.</li>
                                    <li>A statement by you that you have a good faith belief that the use of the allegedly infringing material is not authorized by the copyright owner, or its agents, or by law.</li>
                                    <li>A statement by you, made under penalty of perjury, that the information in your notification is accurate, and that you are the copyright owner or authorized to act on its behalf.</li>
                                    <li>An electronic or physical signature of the copyright owner or of the person authorized to act on the copyright owner’s behalf.</li>
                                </ul>

                                <Title level={5}>CONFIDENTIAL INFORMATION</Title>
                                <Paragraph>Information provided by our clients through our services will be treated as confidential information and will be used solely for the purpose of providing our services properly. SneaCare will protect such confidential Information from disclosure to third parties by employing the same degree of care used to protect its own confidentiality or proprietary information of similar importance. If necessary to carry out a prospective business relationship, SneaCare may disclose confidential information received pursuant to our services to employees and/or consultants with a need to know, provided that the consultants are obligated to protect such confidential information from unauthorized use and disclosure. Confidential information shall not be disclosed to any third party without the prior written consent of the client who is the owner of the information.</Paragraph>

                                <Title level={5}>PERSONAL DATA</Title>
                                <Paragraph>Any personal information you post or submit in connection with the services and use of the platform will be used in accordance with our privacy policy. By using the services, you agree that we may collect and store your personal information. Please see our privacy policy.</Paragraph>

                                <Title level={5}>LICENSE TO USE THE PLATFORM AND SERVICES</Title>
                                <Paragraph>SneaCare grants you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the platform that SneaCare provides to you as part of the services. This license is for the sole purpose of allowing you to use and enjoy the benefit of the services provided by SneaCare, in the manner permitted by these terms. You may not copy, modify, distribute, sell or rent any part of our services or the included software, nor may you reverse engineer or attempt to extract the source code of such software, unless such restrictions are prohibited by law or you have our written permission.</Paragraph>
                                <Paragraph>You agree not to use the platform and services in a negligent, fraudulent or unlawful manner. The user also agrees not to engage in any conduct or action that may damage the image, interests or rights of the SneaCare platform or third parties.</Paragraph>
                                <Paragraph>SneaCare reserves the right to terminate your access immediately, with or without notice, and without liability to you, if SneaCare believes that you have violated any of these terms or interfered with use of the platform or services.</Paragraph>


                                <Title level={5}>PROHIBITED ACTIVITIES</Title>
                                <Paragraph>The content and information available on the platform (including, but not limited to, data, information, text, music, sound, photos, graphics, video, maps, icons or other material), as well as the infrastructure used to provide such Content and information, is proprietary to SneaCare or licensed to the SneaCare by third parties. For all content other than your content, you agree not to otherwise modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell or re-sell any information, software or services obtained from or through the platform. In addition, the following activities are prohibited:</Paragraph>
                                <ul>
                                    <li>Access, monitor, reproduce, distribute, transmit, broadcast, display, sell, license, copy or otherwise exploit any content of the services, including but not limited to, using any robot, spider, scraper or other automated means or any manual process for any purpose not in accordance with this agreement or without our express written permission.</li>
                                    <li>Violate the restrictions in any robot exclusion headers on the services or bypass or circumvent other measures employed to prevent or limit access to the services.</li>
                                    <li>Take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure.</li>
                                    <li>Deep-link to any portion of the services for any purpose without our express written permission.</li>
                                    <li>“Frame”, “mirror” or otherwise incorporate any part of the platform into any other platforms or service without our prior written authorization.</li>
                                    <li>Attempt to modify, translate, adapt, edit, decompile, disassemble, or reverse engineer any software programs used by SneaCare.</li>
                                    <li>Circumvent, disable or otherwise interfere with security-related features of the platform or features that prevent or restrict use or copying of any content.</li>
                                    <li>Download any content unless it’s expressly made available for download by SneaCare.</li>
                                </ul>

                                <Title level={5}>DISCLAIMER OF WARRANTIES </Title>
                                <Paragraph>Because of the nature of the Internet SneaCare provides and maintains the platform on an “as is”, “as available” basis and makes no promise that use of the platform will be uninterrupted or entirely error free. We are not responsible to you if we are unable to provide our Internet services for any reason beyond our control.</Paragraph>
                                <Paragraph>Our platform may from time to time contain links to other platforms which are not under the control of and are not maintained by us. These links are provided for your convenience only and we are not responsible for the content of those sites.</Paragraph>
                                <Paragraph>Except as provided above we can give no other warranties, conditions or other terms, express or implied, statutory or otherwise and all such terms are hereby excluded to the maximum extent permitted by law.</Paragraph>
                                <Paragraph>You will be responsible for any breach of these terms by you and if you use the platform in breach of these terms you will be liable to and will reimburse SneaCare for any loss or damage caused as a result.</Paragraph>
                                <Paragraph>SneaCare will not be liable in any amount for failure to perform any obligation under this agreement if such failure is caused by the occurrence of any unforeseen event beyond its reasonable control including without limitation Internet outages, communications outages, fire, flood, war or act of God.</Paragraph>
                                <Paragraph>These terms do not affect your statutory rights as a consumer which are available to you.</Paragraph>
                                <Paragraph>Subject as aforesaid, to the maximum extent permitted by law, SneaCare excludes liability for any loss or damage of any kind howsoever arising, including without limitation any direct, indirect or consequential loss whether or not such arises out of any problem you notify to SneaCare and SneaCare shall have no liability to pay any money by way of compensation, including without limitation all liability in relation to:</Paragraph>
                                <ul>
                                    <li>Any incorrect or inaccurate information on the platform.</li>
                                    <li>The infringement by any person of any Intellectual Property Rights of any third party caused by their use of the platform or service purchased through the platform.</li>
                                    <li>Any loss or damage resulting from your use or the inability to use the platform or resulting from unauthorized access to, or alteration of your transmissions or data in circumstances which are beyond our control.</li>
                                    <li>Any loss of profit, wasted expenditure, corruption or destruction of data or any other loss which does not directly result from something we have done wrong.</li>
                                    <li>Any amount or kind of loss or damage due to viruses or other malicious software that may infect a user’s computer equipment, software, data or other property caused by persons accessing or using content from the platform or from transmissions via emails or attachments received from SneaCare.</li>
                                    <li>All representations, warranties, conditions and other terms which but for this notice would have effect.</li>
                                </ul>

                                <Title level={5}>ELECTRONIC COMMUNICATIONS </Title>
                                <Paragraph>No responsibility will be accepted by SneaCare for failed, partial or garbled computer transmissions, for any computer, telephone, cable, network, electronic or internet hardware or software malfunctions, failures, connections, availability, for the acts or omissions of any Cleaner User, internet accessibility or availability or for traffic congestion or unauthorized human act, including any errors or mistakes.</Paragraph>

                                <Title level={5}>INDEMNIFICATION</Title>
                                <Paragraph>You agree to defend and indemnify SneaCare from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees, brought by third parties as a result of:</Paragraph>
                                <ul>
                                    <li>Your breach of this agreement or the documents referenced herein.</li>
                                    <li>Your violation of any law or the rights of a third party.</li>
                                    <li>Your use of the services.</li>
                                </ul>

                                <Title level={5}>CHANGES AND TERMINATION</Title>
                                <Paragraph>We may modify the platform and these terms at any time, at our sole discretion and without notice. You are responsible for keeping yourself informed of these terms. Your continued use of the platform constitutes your acceptance of any changes to these terms and any changes will supersede all previous versions of the terms. Unless otherwise specified, all changes to these terms apply to all users and clients. In addition, we may terminate our agreement with you under these terms at any time by notifying you in writing (including by email) or without notice.</Paragraph>

                                <Title level={5}>ASSIGNMENT</Title>
                                <Paragraph>This agreement and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by SneaCare without restriction.</Paragraph>

                                <Title level={5}>NO PARTNERSHIP</Title>
                                <Paragraph>You agree that no joint venture, partnership, employment, or agency relationship exists between you and SneaCare as a result of these terms or your use of the services.</Paragraph>

                                <Title level={5}>INTEGRATION CLAUSE</Title>
                                <Paragraph>This agreement together with the privacy policy and any other legal notices published by SneaCare, shall constitute the entire agreement between you and SneaCare concerning and governs your use of the platform and the services.</Paragraph>

                                <Title level={5}>DISPUTES</Title>
                                <Paragraph>The user agrees that any dispute, claim or controversy arising out of or relating to these terms and conditions, or the breach, termination, enforcement, interpretation or validity thereof or the use of the services, shall be resolved by binding arbitration between the user and SneaCare, provided that each party retains the right to bring an individual action in a court of competent jurisdiction.</Paragraph>
                                <Paragraph>In the event of a dispute arising in connection with the use of the services or the breach of these conditions, the parties agree to submit their dispute to arbitration resolution before a reputable arbitration organization as mutually agreed by the parties and in accordance with applicable commercial arbitration rules.</Paragraph>
                                <Paragraph>You agree to initiate a formal dispute proceeding by sending us a communication through our contact information. SneaCare may choose to send you a written offer after receiving your initial communication. If we offer and send you a settlement offer and you do not accept the offer, or we are unable to resolve your dispute satisfactorily and you wish to continue with the dispute process, you must initiate the dispute resolution process before an accredited arbitration organization and file a separate Demand for Arbitration. Any award rendered by the arbitration tribunal shall be final and conclusive on the parties.</Paragraph>
                                <Paragraph>To the fullest extent permitted by law, you agree that you will not file, join or participate in any class action lawsuit in connection with any claim, dispute or controversy that may arise in connection with your use of the platform and services.</Paragraph>
                                <Paragraph>The courts of the United States, specifically the courts located in the State of Florida, shall have jurisdiction over any dispute, controversy or claim relating to SneaCare and its business operations. Any such dispute or controversy shall be brought and resolved in the courts of the United States, specifically the courts located in the State of Florida.</Paragraph>

                                <Title level={5}>FINAL PROVISIONS</Title>
                                <Paragraph>These terms and conditions are governed by the laws of the United States. Use of the platform and services are not authorized in any jurisdiction that does not give effect to all of the provisions of these terms.</Paragraph>
                                <Paragraph>Our performance of these terms is subject to existing laws and legal process, and nothing contained in these terms limits our right to comply with law enforcement or other governmental or legal requests or requirements relating to your use of our platform or information provided to or gathered by us with respect to such use.</Paragraph>
                                <Paragraph>If any part of these terms is found to be invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions will not in any way be affected or impaired. Our failure or delay in enforcing any provision of these terms at any time does not waive our right to enforce the same or any other provision(s) hereof in the future.</Paragraph>

                                <Paragraph>Any rights not expressly granted herein are reserved.</Paragraph>

                                <Title level={5}>CONTACT US </Title>
                                <Paragraph>If you have questions or concerns about this privacy policy and the handling and security of your data, please contact us through our contact forms, our chat or by using the contact information below:</Paragraph>

                                <Paragraph><Text strong>SneaCare, Say Hello To Clean Shoes.</Text></Paragraph>

                                <Paragraph><Text>Email: <a href="mailto:hello@sneacaremiami.com">hello@sneacaremiami.com</a></Text></Paragraph>

                            </Col>
                        </Row>
                        <FooterSne />
                    </Content>

                    {this.props.cart?.detalles?.length > 0 ? <FloatingButton
                        title="Nuevo registro"
                        onClick={() => this.setState({ openDrawer: true })}
                        icon={<Badge count={this.props.cart?.detalles?.length}>
                            <ShoppingOutlined style={{ color: "white", fontSize: "40px" }} />
                        </Badge>}
                    /> : null}
                </Layout>

            </>
        )
    }
}

export default props => {
    const navigate = useNavigate()

    let cart = useContext(Carrito);
    let setCart = useContext(SetCarrito)

    let user = useContext(User)
    const [cookies, setCookie] = useCookies(['cart']);

    return <TerminosCondiciones
        {...props}
        cart={cart}
        setCart={setCart}
        cookies={cookies}
        setCookie={setCookie}
        user={user}
        navigate={navigate} />
}