import React, { useEffect, useState, useContext } from 'react'
import { Modal, Steps, Spin, Button, Row, message, Card, Descriptions } from 'antd';

import Label from './Label';
import Carriers from './Carriers';
import StepPackage from './Package';
import Cost from './Cost';
import Final from './Final';


import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { getResponseError, getResponseErrorAPI } from '../../../Utils';

dayjs.extend(relativeTime)
const contentStyle = {
    lineHeight: '260px',
    textAlign: 'center',
    marginTop: 16,
};

/**
/**
 *
 *
 * @export
 * @param {*} props
 * @returns Component Modal
 */
const EnviosConfiguracion = (props) => {

    const [currentStep, setCurrentStep] = useState(props.current ?? 0)
    const [is_label_return, disabledLabelReturn] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(true)

    const [data, setData] = useState({
        carrier: {},
        services: {},
        cost: {},
        package: {
            weight_unit: "pound",
            dimension_unit: "inch"
        },
        timelapse: null,
        label: {}
    })

    useEffect(() => {

        if (props.label_id) {
            setCurrentStep(4)
            getTracking()
        }
    }, [props.label_id])

    useEffect(() => {
        getTracking()
    }, [])

    /**Actualiza el step para el siguiente elemento */
    const next = () => {
        setCurrentStep(currentStep + 1);
    };
    /**Actualiza el step para el anterior elemento */
    const prev = () => {
        setCurrentStep(currentStep - 1);
        setDisabled(false)
    };


    const update = (step, data) => {
        setData((prevData) => ({
            ...prevData,
            [step]: data,
        }));

    };

    const getTracking = () => {
        setUpdating(true)
        axios.get('/envios/' + props.label_id)
            .then(res => {
                console.log('tracking get', res.data)
                update("label", res.data)
                if (res.data.is_return_label == false)
                    disabledLabelReturn(false)
            })
            .catch(error => {
                console.log('error', error)
                getResponseErrorAPI(error.response)
            })
            .finally(() => setUpdating(false))
    }

    const generateReturnLabel = () => {
        axios.put('/envios/return', {
            venta_id: props.venta?._id,
            cotizacion_id: props.cotizacion_id
        })
            .then(res => {
                update("label", res.data)
                disabledLabelReturn(true)
                props.refresh()
                message.success('Label return generated successfully')
            })
            .catch(error => {
                getResponseErrorAPI(error.response)
            })
    }

    /**Lista de pasos a seguir para realizar la cotizacion */
    const steps = [
        {
            title: "Carrrier",//0
            content: <Carriers currentStep={currentStep} update={update} setDisabled={setDisabled} data={data.carrier} />

        },
        {
            title: "Package",//1
            content: <StepPackage currentStep={currentStep} update={update} setDisabled={setDisabled} data={data} {...props} />

        },
        {
            title: "Rate Estimated",//2
            content: <Cost currentStep={currentStep} update={update} setDisabled={setDisabled} data={data} {...props} />

        },
        {
            title: "Create Label",//3
            content: <Label currentStep={currentStep} update={update} setDisabled={setDisabled} data={data} {...props} />
        },
        {
            title: "Final",//0
            content: <Final currentStep={currentStep} update={update} data={data} loading={updating} />
        },
    ]



    return <>
        <Steps
            current={currentStep}
            direction='horizontal'
        >
        </Steps>
        <Row>
            <Card >
                <Descriptions title="Shipment Information">
                    <Descriptions.Item label="Carrier">{data.carrier?.carrier_id || data.label.carrier_id}</Descriptions.Item>]
                    <Descriptions.Item label="Services">{data.services?.service_code || data.label.service_code}</Descriptions.Item>
                    <Descriptions.Item label="Shipment Date">{data.carrier?.ship_date || data.label.ship_date ? dayjs(data.carrier?.ship_date || data.label.ship_date).format('MM/DD/YYYY') : null}</Descriptions.Item>
                    <Descriptions.Item label="Tracking Number">{data.label?.tracking_number ? data.label?.tracking_number : null}</Descriptions.Item>
                    {props.can_view ? <Descriptions.Item ><Button block onClick={() => generateReturnLabel()} disabled={data.label.is_return_label || is_label_return || !data.label.label_id}> {(data.label.is_return_label || is_label_return) ? "The return label has already been generated" : "Generate Label Return"}</Button></Descriptions.Item> : null}
                </Descriptions>
            </Card>
        </Row >
        {!props.disabled_configuration ?
            <Spin spinning={loading}>
                <div className='width-100 step-container bg-white pd-2' style={contentStyle}>{steps[currentStep]?.content}</div>
                <div className='mt-1 flex flex-right'>
                    {currentStep > 0 && !props.label_id && (
                        <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                            Previous
                        </Button>
                    )}
                    {currentStep < steps.length - 1 && currentStep <= 3 && (
                        <Button type="primary" onClick={() => next()} disabled={disabled} >
                            Next
                        </Button>
                    )}

                </div>
            </Spin> : null}
    </>

}
export default EnviosConfiguracion
