
import React, { Component } from 'react'
import { DatePicker, Form, Select, Typography, message } from 'antd';
import { getResponseError } from '../../../Utils';

import axios from "axios";
const { Option } = Select;
const {Title} = Typography

/**
 *
 *
 * @export
 * @class StepCarriers
 * @extends {Component}
 */
export default class StepCarriers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            carriers: [],
            services: []

        }
    }
    formRef = React.createRef();
    componentDidMount() {
        console.log('se monto carrier step')
        this.getCarriers()
    }



    getCarriers = () => {
        axios.get('/transportistas')
            .then(({ data }) => {
                console.log("res trans", data)
                this.setState({ carriers: data.carriers })
            })
            .catch(error => {
                message.error(getResponseError(error.response, "An error occurred while loading the carriers"))
            })

    }

    getCarrier = (id) => {
        axios.get('/transportista/' + id)
            .then(({ data }) => {
                console.log("res trabs id", data)
                this.setState({ carrier: data.carriers })
            })
            .catch(error => {
                message.error(getResponseError(error.response, "An error occurred while loading the carriers"))
            })
    }


    loadServices = (id) => {
        let carriers = this.state.carriers;
        let selected = carriers.find(c => c.carrier_id == id);

        this.props.update('carrier', selected)

        this.getCarrier(selected.carrier_id)
        this.setState({ services: selected.services })

    }

    onChangeServices = (id) => {
        let services = this.state.services;
        let selected = services.find(c => c.service_code == id);
        console.log('service selected', selected)

        this.props.update('services', selected)

    }


    handleFieldsChange = (_, allFields) => {
        // Verificar si todos los campos requeridos están llenos
        const isFilled = allFields?.every((field) => field.errors.length == 0);
        console.log('isFilled',!isFilled)
        this.props.setDisabled(!isFilled);
    };

    onValuesChange = (changedValues, allValues) => {
        this.props.update('carrier', allValues)
    }

     disabledDates = current => {
        const hoy = new Date();
        hoy.setHours(0, 0, 0, 0); 
        return current && current < hoy || current.day() === 0;
      };
    render() {
        return (
            <>
            <Title level={3}>Please fill the carrier information for proceed with the shipment</Title>
                <Form
                    layout={"vertical"}
                    ref={this.formRef}
                    initialValues={this.props.data}
                    onValuesChange={this.onValuesChange}
                    onFieldsChange={this.handleFieldsChange}
                    validateTrigger="onChange"
                >
                    <Form.Item
                        name="carrier_id"
                        label="Carrier to Shipment"
                        rules={[
                            {
                                required: true,
                                message: 'please select a Carrier ',
                            },
                        ]}
                    >
                        <Select className='width-100' onChange={this.loadServices} placeholder="Select carrier">
                            {this.state.carriers?.map(item => <Option value={item.carrier_id}>{item.nickname}</Option>)}
                        </Select>


                    </Form.Item>
                    <Form.Item
                        name="services_code"
                        label="Carrier Services to Shipment"
                        rules={[
                            {
                                required: true,
                                message: 'please select a Services Carrier ',
                            },
                        ]}
                    >
                        <Select className='width-100' onChange={this.onChangeServices} placeholder="Select services">
                            {this.state?.services?.map(item => <Option value={item.service_code}>{item.name}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="ship_date"
                        label="Shipment Date"
                        rules={[
                            {
                                required: true,
                                message: 'please select a Ship Date ',
                            },
                        ]}
                    >
                        <DatePicker  className='width-100' format={'YYYY-MM-DD'} disabledDate={this.disabledDates}/>
                    </Form.Item>
                </Form>
            </>

        )
    }
}