import React, { Component } from 'react'
import { Form, Input, Row, Col, Button, message } from 'antd';
import axios from 'axios'

import { User } from "../../../Hooks/Logged"


export default class StepCliente extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cliente: this.props.client,
            updating: false,
            disabled: false
        }
    }

    formRef = React.createRef();

    static contextType = User;

    componentDidMount() {

        const user = this.context;

        if (user && user.cliente_id) {
            this.getCliente(user.cliente_id)
        }

        if (Object.keys(this.props.client).length > 0)
            this.formRef.current.submit()
    }



    onValuesChange = (changedValues, allValues) => {
        this.setState({ cliente: allValues })
        this.formRef.current.submit()
        this.props.updateQuotationData('client', allValues)
    }
    handleFieldsChange = (_, allFields) => {
        // Verificar si todos los campos requeridos están llenos
        const isFilled = allFields?.every((field) => field.errors.length == 0);
        this.props.setDisabled(!isFilled);
    };

    getCliente = (id) => {
        axios.get('/client/' + id)
            .then(({ data }) => {
                console.log("getCliente", data);
                this.formRef.current.setFieldsValue(data)

                this.setState({ disabled: true })
                this.props.updateQuotationData('client', data)
                this.props.next()
            }).catch(error => {
                message.error('Error getting client')
            })

    }
    render() {

        return (
            <Form
                layout={"vertical"}
                ref={this.formRef}
                initialValues={this.props.client}
                onValuesChange={this.onValuesChange}
                onFieldsChange={this.handleFieldsChange}
                validateTrigger="onChange"
            >
                <Row justify="center" className="center" gutter={[8, 8]}>
                    <Col span={12}>
                        <Form.Item
                            name="nombre"
                            label="Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Name is required',
                                },
                            ]}
                            help={""}
                        >
                            <Input disabled={this.state.disabled}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="apellidos"
                            label="Last Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Last Name is required',
                                },
                            ]}
                        >
                            <Input disabled={this.state.disabled}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>

                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Email is not valid',
                                },
                                {
                                    required: true,
                                    message: 'Enter the email',
                                }, {
                                    max: 50,
                                    message: "Maximum 50 characters"
                                },{
                                    validator: (_, value) => {
                                        // Transformar el valor a minúsculas
                                        const lowercaseValue = value.toLowerCase();
                                        if (value !== lowercaseValue) this.formRef.current.setFieldsValue({ email: lowercaseValue });
                                        return Promise.resolve();
                                    },
                                }
                            ]}
                        >
                            <Input disabled={this.state.disabled}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>

                        <Form.Item
                            name="telefono"
                            label="Phone"
                            rules={[
                                {
                                    required: true,
                                    message: 'Phone is required',
                                },
                            ]}
                        >
                            <Input disabled={this.state.disabled}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    }
}