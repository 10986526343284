import React, { Component, useContext } from 'react';
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom';
import { ShoppingOutlined } from '@ant-design/icons';
import { Layout, Col, Row, Typography, Badge } from 'antd';

//componentes
import MyHeader from './Header';
import FooterSne from './Footer';
import { User } from "../../Hooks/Logged"
import { Carrito, SetCarrito } from "../../Hooks/Carrito"
import FloatingButton from "../Widgets/Floating Button/FloatingButton";

//styles
import '../../Styles/Modules/Landing.scss'

const { Content } = Layout
const { Title, Text, Paragraph } = Typography

const Section = (props) => <section {...props}>{props.children}</section>


class LandingPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            openDrawer: false
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }


    render() {
        return (
            <>
                <Layout style={{
                    background: 'rgb(127,135,203)',
                    background: 'linear-gradient(180deg, rgba(127,135,203,1) 0%, rgba(255,255,255,1) 100%)',
                    height: '100vh',
                }}
                    className='landing'>
                    <MyHeader
                        {...this.props}
                        openDrawer={this.state.openDrawer}
                        setOpenDrawer={(value) => this.setState({ openDrawer: value })}
                    />
                    <Content >
                        <Row justify="center" style={{ marginTop: "130px", marginBottom: "130px" }}>
                            <Col xs={23} md={20}>
                                <Title level={4}>COOKIE POLICY</Title>
                                <Title level={5}>Welcome to SneaCare, Say Hello To Clean Shoes!</Title>
                                <Paragraph>This cookie policy explains how and why cookies and other similar technologies may be stored on and accessed from your device when you use or visit:</Paragraph>
                                <ul>
                                    <li> <a href="https://sneacaremiami.com" target="_blank" rel="noopener noreferrer"> https://sneacaremiami.com </a> </li>
                                </ul>
                                <Paragraph>(Hereinafter referred to as “SneaCare”).</Paragraph>
                                <Paragraph>The information collected through cookies will be under responsibility and in charge of:</Paragraph>
                                <ul>
                                    <li> SneaCare, Say Hello To Clean Shoes. </li>
                                    <li> Email: hello@sneacaremiami.com </li>
                                </ul>
                                <Paragraph>This cookie policy should be read together with our privacy policy and our terms and conditions.</Paragraph>
                                <Paragraph>By using the website, you accept the use of cookies by SneaCare, in the terms contained in this policy.</Paragraph>
                                <Text strong>WHAT ARE COOKIES?</Text>
                                <Paragraph>Cookies are small text files that are stored on your computer or mobile device when you visit a website. They allow the website to recognize your device and remember if you have been to the website before. Cookies are a very common web technology; most websites use cookies and have done so for years. Cookies are widely used to make the website work more efficiently. Cookies are used to measure which parts of the website users visit and to personalize their experience. Cookies also provide information that helps us monitor and improve the performance of the website.</Paragraph>

                                <Text strong>REFUSING OR WITHDRAWING CONSENT TO THE USE OF COOKIES</Text>
                                <Paragraph>If you do not want cookies to be dropped on your device, you can adjust the setting of your Internet browser to reject the setting of all or some cookies and to alert you when a cookie is placed on your device. For further information about how to do so, please refer to your browser ‘help’, ‘tool’, or ‘edit’ section. Please note that if you use your browser settings to block all cookies, including strictly necessary cookies, you may not be able to access or use all or parts of the functionalities of https://sneacaremiami.com.</Paragraph>
                                <Paragraph>If you want to remove previously-stored cookies, you can manually delete the cookies at any time. However, this will not prevent https://sneacaremiami.com from placing further cookies on your device unless and until you adjust your Internet browser setting as described above.</Paragraph>
                                <Paragraph>We provide the links for the management and blocking of cookies depending on the browser you use:</Paragraph>
                                <ul>
                                    <li><a href="https://support.microsoft.com/en-us/office/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09?ui=en-us&rs=en-us&ad=us" target="_blank" rel="noopener noreferrer">Microsoft Edge</a></li>
                                    <li><a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox" target="_blank" rel="noopener noreferrer">Firefox</a></li>
                                    <li><a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank" rel="noopener noreferrer">Chrome</a></li>
                                    <li><a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank" rel="noopener noreferrer">Safari</a></li>
                                </ul>
                                <Paragraph>In cases where you access the website through an iOS or Android mobile device please follow the instructions below to delete or block cookies on your device:</Paragraph>
                                <ul>
                                    <li><a href="https://support.google.com/answer/32050" target="_blank" rel="noopener noreferrer">Android</a></li>
                                    <li><a href="https://support.apple.com/en-us/HT201265" target="_blank" rel="noopener noreferrer">iOS</a></li>
                                </ul>

                                <Text strong>FIRST-PARTY COOKIES</Text>
                                <Paragraph>We use cookies to enhance the performance of our website and personalize your online SneaCare experience. Cookies help us to collect information on how people use our website and which pages they visit. They enable us to monitor the number of visitors and to analyze website usage patterns and trends. We collect this information anonymously, so it does not identify anyone as an individual and no personal information is stored in our cookies. We always use cookie data in a responsible way.</Paragraph>


                                <Text strong>THIRD-PARTY COOKIES</Text>
                                <Paragraph>Third-party cookies may come from partners or third-party companies that provide functional web services or tools for our website and the optimal functioning and operation of our services. We use third party cookies responsibly and for the sole purpose of providing optimal functioning of the platform and services. You may opt out of these cookies by following the cookie removal information contained in this document or the technical information of the browser from which you access our website and services.</Paragraph>

                                <Text strong>SOCIAL COOKIES</Text>
                                <Paragraph>These cookies allow you to interact with social networks through certain actions such as the “Like” button. They also allow you to interact with the contents of each social network and share website content on social networks. The way these cookies are used, and the information collected is governed by the privacy policy of each social network.</Paragraph>

                                <Text strong>PURPOSES OF OUR COOKIES</Text>
                                <Paragraph>Our cookies are used for the following purposes:</Paragraph>

                                <Paragraph><Text strong>Strictly Necessary:</Text> These cookies are essential for https://sneacaremiami.com to perform its basic functions.</Paragraph>
                                <Paragraph><Text strong>Security:</Text> We use these cookies to help identify and prevent potential security risks.</Paragraph>
                                <Paragraph><Text strong>Analytics and Performance:</Text> Performance cookies collect information on how users interact with our website, including what pages are visited most, as well as other analytical data. We use these details to improve how our website functions and to understand how users interact with them.</Paragraph>
                                <Paragraph><Text strong>GOOGLE Analytics.</Text> We use Google Analytics provided by Google, Inc., USA (“Google”). These tool and technologies collect and analyze certain types of information, including IP addresses, device and software identifiers, referring and exit URLs, feature use metrics and statistics, usage and purchase history, media access control address (MAC Address), mobile unique device identifiers, and other similar information via the use of cookies. The information generated by Google Analytics (including your IP address) may be transmitted to and stored by Google on servers in the United States. We use the GOOGLE Analytics collection of data to enhance the website and improve our service.</Paragraph>

                                <Text strong>CONTACT US</Text>
                                <Paragraph>If you have questions or concerns about this privacy policy and the handling and security of your data, please contact us through our contact forms, our chat or by using the contact information below:</Paragraph>

                                <Paragraph><Text strong>SneaCare, Say Hello To Clean Shoes.</Text></Paragraph>

                                <Paragraph><Text>Email: <a href="mailto:hello@sneacaremiami.com">hello@sneacaremiami.com</a></Text></Paragraph>
                            </Col>
                        </Row>
                        <FooterSne />
                    </Content>

                    {this.props.cart?.detalles?.length > 0 ? <FloatingButton
                        title="Nuevo registro"
                        onClick={() => this.setState({ openDrawer: true })}
                        icon={<Badge count={this.props.cart?.detalles?.length}>
                            <ShoppingOutlined style={{ color: "white", fontSize: "40px" }} />
                        </Badge>}
                    /> : null}
                </Layout>

            </>
        )
    }
}

export default props => {
    const navigate = useNavigate()

    let cart = useContext(Carrito);
    let setCart = useContext(SetCarrito)

    let user = useContext(User)
    const [cookies, setCookie] = useCookies(['cart']);

    return <LandingPage
        {...props}
        cart={cart}
        setCart={setCart}
        cookies={cookies}
        setCookie={setCookie}
        user={user}
        navigate={navigate} />
}