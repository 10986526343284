import React, { Component, } from 'react';
import { Row, Col, Layout, Card, List, Typography, Space, message } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { useNavigate, useSearchParams } from 'react-router-dom';

//componentes
import useSearch from '../../../Hooks/useSearch';
import { ButtonView } from '../../Widgets/Buttons';
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import { getResponseError, renderEstatusVenta } from '../../Utils';

//Modales
import ModalVentaDetalles from "../Transacciones/ModalVentaDetalles"

//css
import '../../../Styles/Modules/Customer/Dashboard.scss'
const { Content } = Layout;
const { Text, Title } = Typography;

/**
 *
 *
 * @export
 * @class Dashboard
 * @extends {Component}
 * @description Vista donde se muestra un resumen de las estadisticas del sistema
 */
class Dashboard extends Component {

    /**
     *Creates an instance of Dashboard.
     * @param {*} props
     * @memberof Dashboard
     */
    constructor(props) {
        super(props)
        this.state = {
            ventas: [],
            servicios: []
        }
    }


    componentDidMount() {
        this.props.setShowSearch(false)
        this.props.setTitle("Dashboard")
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

        this.getSales()
        this.getInfo()
    }

    componentWillUnmount() {
        this.props.setShowSearch(true)
        this.props.setTitle(null)
    }


    /**
    *
    * @memberof Dashboard
    * @method getSales
    * @description Obtiene la lista de ventas
    */
    getSales = () => {
        this.setState({ loading: true })
        axios.get('/customer/ventas', {
            params: {
                limit: 10
            }
        })
            .then(({ data }) => {
                this.setState({ ventas: data.data })
            })
            .catch(error => {
                message.error("Error getting the latest sales")
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    /**
    *
    * @memberof Dashboard
    * @method getInfo
    * @description Obtiene la lista de los ultimos servicios
    */
    getInfo = () => {
        this.setState({ loading: true })
        axios.get('/customer/dashboard', {
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({ servicios: data.servicios })
            })
            .catch(error => {
                message.error(getResponseError(error.response, "Error getting the latest services"))
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    renderVenta = (item) => {
        return (
            <List.Item >
                <Row className="width-100" gutter={[6, 8]} justify={"space-between"} >
                    <Col xs={{ span: 20, order: 1 }} md={{ span: 4, order: 1 }} lg={{ span: 4, order: 1 }} >
                        <Space direction='vertical'>
                        <Text>{item.folio ?? '-'}</Text>
                        <small className="text-gray">{dayjs(item.createdAt).format('MM/DD/YYYY HH:mm')}</small>
                        </Space>
                    </Col>
                    <Col xs={{ span: 8, order: 3 }} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} >
                        {renderEstatusVenta(item.status)}
                    </Col>
                    <Col xs={{ span: 12, order: 5 }} md={{ span: 5, order: 3 }} lg={{ span: 5, order: 3 }} >
                        <Text ellipsis>{item.tracking_number ?? "No tracking number"}</Text>
                    </Col>
                    <Col xs={{ span: 12, order: 4 }} md={{ span: 5, order: 4 }} lg={{ span: 5, order: 4 }} >
                        <Text>$ {item.total?.toMoney(true)}</Text>
                    </Col>
                    <Col xs={{ span: 4, order: 2 }} md={{ span: 2, order: 5 }} lg={{ span: 2, order: 5 }} >
                        <ButtonView
                            title="View Details"
                            onClick={() => this.setState({ modalDetalleVisible: true, venta_id: item._id })}
                        />
                    </Col>
                </Row >
            </List.Item >
        )
    }

    renderServices = (item) => {
        return (
            <List.Item className="dashboard-services-item">
                <Row className="width-100" gutter={[6, 8]} align={"middle"}>
                    <Col span={21}>
                        <Space size={4} direction='vertical'>
                            <Text strong>{item?.servicio_id?.nombre}</Text>
                            <Text ellipsis>{item.servicio_id?.sku}</Text>
                        </Space>
                    </Col>
                    <Col span={3} className="flex-right">
                        {renderEstatusVenta(item.status)}
                    </Col>
                </Row>
            </List.Item>
        )
    }


    render() {


        return (
            <>
                
                <Content className="customer-content dashboard content-bg pd-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Card>
                                <Row>
                                    <Col span={24}>
                                        <Title level={3}>Welcome Back Customer!</Title>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} md={24} lg={16}>
                            <Card title={'Order Status'} className="dashboard-card">
                                <List
                                    loading={this.state.loading}
                                    className="dashboard-transactions-list"
                                    itemLayout="horizontal"
                                    locale={{ emptyText: "Sells not found" }}
                                    dataSource={this.state.ventas}
                                    renderItem={item => this.renderVenta(item)}
                                />
                            </Card>
                        </Col>
                        <Col xs={24} md={24} lg={8}>
                            <Card title={'Last Services'} className="dashboard-card">
                                <List
                                    loading={this.state.loading}
                                    className="dashboard-services-list"
                                    itemLayout="horizontal"
                                    locale={{ emptyText: "Services not found" }}
                                    dataSource={this.state.servicios}
                                    renderItem={item => this.renderServices(item)}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Content>
                <ModalVentaDetalles
                    open={this.state.modalDetalleVisible}
                    onClose={(flag) => {
                        this.setState({ modalDetalleVisible: false, venta_id: undefined })
                    }}
                    venta_id={this.state.venta_id}
                />
            </>
        )
    }
}

export default props => {
    const { setShow } = useSearch()
    const navigate = useNavigate()
    const [params, setParams] = useSearchParams();



    return <Dashboard {...props} setShowSearch={setShow} navigate={navigate} params={params} />
}
