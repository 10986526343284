
import React, { Component } from 'react'
import { Form, Select, message, Skeleton, Spin, Result, Space, Card, Button, Row, Col, Typography } from 'antd';
import { getResponseError, getResponseErrorAPI } from '../../../Utils';

import axios from "axios";
import dayjs from 'dayjs';
const { Option } = Select
const { Text, Paragraph } = Typography

/**
 *
 *
 * @export
 * @class StepLabel
 * @extends {Component}
 */
export default class StepLabel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            label: {},
            loading: true,
            error: false,
        }
    }
    componentDidMount() {
        console.log('se monto final step')
        this.generateLabel()
    }

    handleDownloadClick = (label_download) => {
        // Aquí puedes agregar lógica para manejar la descarga del PDF
        window.open(label_download, '_blank');
    };

    generateLabel = () => {
        this.setState({ loading: true })
        axios.post('/envios', {
            cotizacion_id: this.props.cotizacion_id,
            ...this.props.data,
        })
            .then(res => {
                console.log(' res label', res)
                this.props.update('label', res.data)
                this.setState({ label: res.data, error: false })
            })
            .catch(error => {
                console.log('error', error)
                getResponseErrorAPI(error.response)
                this.props.setDisabled(true)
                this.setState({ error: true })
            })
            .finally(() => this.setState({ loading: false }))
    }
    render() {
        const { label } = this.state
        return (
            <Spin spinning={this.state.loading}>

                {this.state.loading ? <Skeleton active /> :
                    <> {(!this.state.error) ?
                        <Row>
                            <Col span={12}>
                                <Card title="Shipping Label Information" style={{ width: 500 }}>
                                    <Row align={"middle"} justify={"start"}>
                                        <Col span={8} className='flex-left'> <Text strong>Label ID:</Text></Col>
                                        <Col span={16}><Text>  {label.label_id}</Text></Col>
                                    </Row>
                                    <Row>
                                        <Col span={8} className='flex-left'> <Text strong>Status:</Text></Col>
                                        <Col span={16}><Text>  {label.status}</Text></Col>
                                    </Row>
                                    <Row>
                                        <Col span={8} className='flex-left'> <Text strong>Ship Date:</Text></Col>
                                        <Col span={16}><Text>  {dayjs(label.ship_date).format('MM/DD/YYYY')}</Text></Col>
                                    </Row>
                                    <Row>
                                        <Col span={8} className='flex-left'> <Text strong>Shipment Cost:</Text></Col>
                                        <Col span={16}><Text>{label.shipment_cost?.amount} {label.shipment_cost?.currency}</Text></Col>
                                    </Row>
                                    <Row>
                                        <Col span={8} className='flex-left'> <Text strong>Is Return Label:</Text></Col>
                                        <Col span={16}><Text>  {label.is_return_label ? 'Yes' : 'No'}</Text></Col>
                                    </Row>
                                    <Row>
                                        <Col span={8} className='flex-left'> <Text strong>Tracking Number:</Text></Col>
                                        <Col span={16}><Text>  {label.tracking_number}</Text></Col>
                                    </Row>
                                </Card >

                            </Col>
                            <Col span={12}>
                                <Card>
                                    <Row direction="vertical" gutter={[8, 8]}>
                                        <Col span={12}>
                                            <Button block className='btn-block' size="large" type="primary" onClick={() => this.handleDownloadClick(label.label_download.pdf)}>
                                                Download PDF
                                            </Button>
                                        </Col>
                                        <Col span={12}>
                                            <Button block className='btn-block' size="large" type="primary" onClick={() => this.handleDownloadClick(label.label_download.png)}>
                                                Download PNG
                                            </Button>
                                        </Col>
                                        <Col span={12}>
                                            <Button block className='btn-block' size="large" type="primary" onClick={() => this.handleDownloadClick(label.label_download.href)}>
                                                Download LINK
                                            </Button>
                                        </Col>
                                        <Col span={12}>
                                            <Button block className='btn-block' size="large" type="primary" onClick={() => this.handleDownloadClick(label.label_download.zpl)}>
                                                Download ZPL
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        : <Result
                            status="error"
                            title="Label Error"
                            subTitle=" An error occurred while generating label"
                            extra={[
                                <Button type="primary" key="console" onClick={() => this.generateLabel()} loading={this.state.loading}>
                                    Try Again
                                </Button>,
                            ]}
                        >
                        </Result>}
                    </>
                }
            </Spin>

        )
    }
}