import React from 'react'
import { Route, Routes } from "react-router-dom";

import Inventario from '../../Components/Admin/Inventario/Inventario';
import Movimientos from '../../Components/Admin/Inventario/Movimientos';

/**
 * 
 * @export
 * @function RouterInventarios
 * @description Router para Inverntario 
 */
function RouterInventarios(props) {
  return (
    <Routes>
      <Route path="" element={<Inventario {...props} />} />
      <Route path="/movements/:inventario_id" element={<Movimientos {...props} />} />
    </Routes>
  )
}

export default RouterInventarios