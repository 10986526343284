
import React, { Component, createRef } from 'react';
import { Button, Space, Image, Typography, InputNumber, Table, Form, message, Modal, Row, Col, Select, Spin, Input } from 'antd';
import axios from 'axios';

const Decimal = require('decimal.js');
const { Text } = Typography

/**
 *
 *
 * @export
 * @class TableOrdenCompra
 * @extends {Component}
 * 
 * @description Permite gestionar la tabla de productos de la orden de Compra
 */
export default class TableOrdenCompra extends Component {

	/**
	 *
	 *
	 * @memberof TableOrdenCompra
	 * @state orden Almacen la orden Actual
	 * @state dataSource Lista de elementos de la tabla
	 * @state visibleModalAgregarProductos Permite agregar y eliminar productos. 
	 */
	state = {
		orden: {},
		dataSource: [],
		visibleModalAgregarProductos: false,
		loading: false,
		loadingClientInfo: false,
		disableEdit: false
	}

	/**
	 *
	 *
	 * @memberof TableOrdenCompra
	 * @description Obtenemos la orden de compra
	 */
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
		this.getVenta()
	}

	/**
   *
   *
   * @memberof TableOrdenCompra
   * @description Formatea el arreglo a un objeto de indices para que pueda ser interprestado por el formulario.
   */
	setOrdenesDetalles = (arreglo = []) => {
		let values = {}
		for (let index = 0; index < arreglo.length; index++) {
			const data_orden_detalle = arreglo[index]
			values[index] = data_orden_detalle
		}
		return values
	}

	/**
	 *
	 *
	 * @memberof TableOrdenCompra
	 * @description Obtenemos la orden de compra
	 */
	getVenta = () => {
		this.setState({ loading: true })
		axios.get('/customer/venta',{
			params: {venta_id: this.props.venta_id}
		})
			.then(({ data }) => {
				console.log(data)
				
				this.setState({
					dataSource: data.detalles,
					disableEdit: data.square_payment ?? false
				})

				console.log("data", data);
				
			})
			.catch(e => {
				console.log('datadatadata', e)
			})
	}

	handleClientSelect = (client_id) => {
		console.log("client_id", client_id);
		if (client_id === 'new' || client_id == undefined || client_id == null) return
		this.setState({ loadingClientInfo: true })
		axios.get('/clientes/' + client_id).then(response => {
			console.log(response)
			this.formTableOrdenCompra.current.setFieldsValue({
				apellidos: response.data.apellidos,
				email: response.data.email,
				telefono: response.data.telefono
			})
		}).catch(error => {
			message.error("There's been an error loading the values")
			console.log(error)
		}).finally(() => this.setState({ loadingClientInfo: false }))
	}

	/**
     *
     *
     * @memberof Cart
     */
    deleteItem = (id) => {
        this.setState({ loading: true })
        axios.delete('/carrito', {
            params: {
                venta_id: this.props.venta_id,
                detalle_id: id
            }
        }).then(({ data }) => {
            this.getVenta()
        }).catch((error) => {
            console.log(error)
            message.error("Error delete")
        }).finally(()=>this.setState({loading: false}))
    }


	/**
 *
 *
 * @memberof TableOrdenCompra
 * @description Este metodo se encarga de calcular las ordenes de compra.
 */
	onSave = (values) => {
		this.setState({ loading: true })
		axios.put('/ventas', {
			venta_id: this.props.venta_id,
			...values
		})
			.then(({ data }) => {
				message.success("The sale" + this.props.folio + " has been updated successfully.")
				this.props.onSave()
			})
			.catch(e => {
				console.log('datadatadata', e)
				message.error("Error updating sale")
			})
	}


	/**
	 *
	 *
	 * @memberof TableOrdenCompra
	 * @description Permite mostarar y actualizar los valores de la tabla
	 */
	columns = [
		{
			title: 'Product',
			dataIndex: ['variante_id', 'nombre'],
			key: 'name',
			width: "20%",
			render: (value, item) => {
				console.log(item)
				let producto = item?.variante_id;
				return (
					<Space direction="vertical" size={0}>
						<Text className="product-text-name">
							{producto ? producto.nombre : ""}
						</Text>
					</Space>
				);
			},
		},
		{
			title: 'Service',
			dataIndex: ['servicio_id', 'nombre'],
			key: 'name',
			width: "20%",
			render: (value, item) => {
				let servicio = item?.servicio_id;
				return (
					<Space direction="vertical" size={0}>
						<Text className="product-text-name">
							{servicio ? servicio.nombre : ""}
						</Text>
					</Space>
				);
			},
		},
		{
			title: "Quantity",
			dataIndex: "cantidad",
			key: "cantidad",
			render: (value, record, index) => (
				<span>
					{(this.state.dataSource[index]?.cantidad) ? this.state.dataSource[index]?.cantidad : "0"}
				</span>
			),
		},
		{
			title: "Base price",
			key: "otros",
			render: (value, record, index) => (
				<span>
					$ {(this.state.dataSource[index]?.precio) ? this.state.dataSource[index]?.precio.toMoney(true) : "0"}
				</span>
			),
		},
		{
			title: "Net Amount",
			key: "net_amount",
			width: "150px",
			shouldCellUpdate: () => true,
			render: (value, record, index) => {
				return (
					<span>
						$ {(this.state.dataSource[index]?.total) ? this.state.dataSource[index]?.total.toMoney(true) : "0"}
					</span>
				);
			},
		},
		{
			title: "Tax",
			key: "utilidad_total",
			shouldCellUpdate: () => true,
			render: (value, record, index) => {
				return (
					<span>
						$ {(!this.state.dataSource[index]?.servicio_id) ? Decimal(this.state.dataSource[index]?.total).mul(0.07).toNumber().toMoney(true) : "0"}
					</span>
				);
			},
		},
		// {
		// 	title: "Actions",
		// 	key: "acciones",
		// 	render: (value, record, index) => {
		// 		return <Button disabled={this.state.disableEdit} type="primary" size='small' danger icon={<DeleteOutlined />} onClick={() => this.deleteItem(record._id)}></Button> 
				
		// 	},
		// },
	];

	formTableOrdenCompra = createRef()

	refTableOrdenCompra = createRef()

	render() {

		const { dataSource, orden, visibleModalAgregarProductos, visibleModalAgregarServicios } = this.state
		const { columns, formTableOrdenCompra, refTableOrdenCompra } = this

		return (
			<>
				<Form
					ref={formTableOrdenCompra}
					rowKey={record => record._id}
					onFinish={this.onSave}
				>
					<Table
						className="table-orden-compra"
						dataSource={dataSource}
						columns={columns}
						pagination={false}
						
					/>
				</Form>

				
			</>
		)
	}
}