
import React, { Component } from 'react'
import { Form, Select, message, Spin, Space, Card, Button, Row, Col, Result, Typography } from 'antd';
import { getResponseError, getResponseErrorAPI } from '../../../Utils';

import axios from "axios";
import dayjs from 'dayjs';
const { Option } = Select
const { Text } = Typography


/**
 *
 *
 * @export
 * @class StepCarriers
 * @extends {Component}
 */
export default class StepCarriers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            label: {},
        }
    }
    componentDidMount() {
    }


    handleDownloadClick = (label_download) => {
        // Aquí puedes agregar lógica para manejar la descarga del PDF
        window.open(label_download, '_blank');
    };

    render() {
        const { label } = this.props.data


        return (
            <Spin spinning={this.props.loading}>
                <Row>
                    <Col span={12}>
                        <Card title="Shipping Label Information" style={{ width: 500 }}>
                            <Row align={"middle"}>
                                <Col span={8} className='flex-left text-uppercase'> <Text strong>Label ID:</Text></Col>
                                <Col span={16}><Text>  {label.label_id}</Text></Col>
                            </Row>
                            <Row>
                                <Col span={8} className='flex-left text-uppercase'> <Text strong>Status:</Text></Col>
                                <Col span={16}><Text>  {label.status}</Text></Col>
                            </Row>
                            <Row>
                                <Col span={8} className='flex-left text-uppercase'> <Text strong>Ship Date:</Text></Col>
                                <Col span={16}><Text>  {dayjs(label.ship_date).format('MM/DD/YYYY')}</Text></Col>
                            </Row>
                            <Row>
                                <Col span={8} className='flex-left text-uppercase'> <Text strong>Shipment Cost:</Text></Col>
                                <Col span={16}><Text>{label.shipment_cost?.amount} {label.shipment_cost?.currency}</Text></Col>
                            </Row>
                            <Row>
                                <Col span={8} className='flex-left text-uppercase'> <Text strong>Is Return Label:</Text></Col>
                                <Col span={16}><Text>  {label.is_return_label ? 'Yes' : 'No'}</Text></Col>
                            </Row>
                            <Row>
                                <Col span={8} className='flex-left text-uppercase'> <Text strong>Tracking Number:</Text></Col>
                                <Col span={16}><Text>  {label.tracking_number}</Text></Col>
                            </Row>
                        </Card >

                    </Col>
                    <Col span={12}>
                        <Card>
                            <Row direction="vertical" gutter={[8,8]}>
                                <Col span={12}>
                                    <Button block className='btn-block' size="large" type="primary" onClick={() => this.handleDownloadClick(label.label_download.pdf)}>
                                        Download PDF
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Button block className='btn-block' size="large" type="primary" onClick={() => this.handleDownloadClick(label.label_download.png)}>
                                        Download PNG
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Button block className='btn-block' size="large" type="primary" onClick={() => this.handleDownloadClick(label.label_download.href)}>
                                        Download LINK
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Button block className='btn-block' size="large" type="primary" onClick={() => this.handleDownloadClick(label.label_download.zpl)}>
                                        Download ZPL
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Spin>

        )
    }
}