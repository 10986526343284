import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Button, Typography, Switch } from 'antd';
import axios from "axios"

//componentes

const { Title, Text, Paragraph } = Typography


/**
 * @class ModalAvisoPrivacidad
 * @description Modal para el CRUD de Productos
 */
class ModalAvisoPrivacidad extends Component {

	constructor(props) {
		super(props)
	}


	render() {
		return (
			<Row>
				<Col span={24}>
					<Title level={5}>Welcome to SneaCare, Say Hello To Clean Shoes!</Title>
					<Paragraph>SneaCare values your privacy and the protection of your personal data. This privacy policy describes what information we collect from you, how we collect it, how we use it, how we obtain your consent, how long we keep it in our databases and, if necessary, with whom we share it.</Paragraph>
					<Paragraph>By using the website and services, you are accepting the practices described in this privacy policy. Use of the website and services is also subject to our terms and conditions. In this privacy policy, the words “platform” refers to the SneaCare app and website together, “we”, “us”, “our” and “SneaCare” refers to SneaCare and “you” and “user” refers to you, the SneaCare user.</Paragraph>
					<Paragraph>This privacy policy may change from time to time. Your continued use of the website and services after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates. This privacy policy has been developed and is maintained in accordance with the Florida Privacy Protection Act (FPPA).</Paragraph>

					<Title level={5}>GENERAL INFORMATION</Title>
					<Text> The personal data of the users that are collected and processed through the website:</Text>
					<ul>
						<li>SneaCare App (Available on Google Play and App store).</li>
						<li> <a href="https://sneacaremiami.com" target="_blank" rel="noopener noreferrer"> https://sneacaremiami.com </a> </li>
					</ul>
					<Text> Will be under responsibility and in charge of: </Text>
					<ul>
						<li>SneaCare, Say Hello To Clean Shoes.</li>
						<li>Email: <a href="mailto:hello@sneacaremiami.com">hello@sneacaremiami.com</a></li>
					</ul>
					<Text> (Hereinafter referred to as SneaCare).</Text>

					<Title level={5}>TYPES OF INFORMATION GATHERED</Title>
					<Text>
						The information we learn from customers helps personalize and continually improve your experience at SneaCare. Here are the types of information we gather:
					</Text>
					<br />
					<br />
					<Text strong>Information You Give Us:</Text>
					<Text>
						You provide information when you register as a user, purchase our services, provide information through our services and/or communicate with us through our contact information or our contact forms. As a result of those actions, you might supply us with the following information:
					</Text>
					<ul>
						<li>First and last name</li>
						<li>Email address</li>
						<li>Phone number</li>
						<li>Address</li>
						<li>
							Any additional information relating to you that you provide to us directly or indirectly through our website and services.
						</li>
					</ul>
					<Text>
						SneaCare will not collect any personally identifiable information about you unless you provide it.
					</Text>
					<br />
					<br />
					<Text>
						<Text strong>Information Collected Automatically: </Text>By accessing and using the website, you automatically provide us with the following information:
					</Text>
					<ul>
						<li>The device and usage information you use to access the website</li>
						<li>Your IP address</li>
						<li>Browser and device characteristics</li>
						<li>Operating system</li>
						<li>Referring URLs</li>
					</ul>
					<Text>
						If you access the website through a mobile phone, we will collect the following information:
					</Text>
					<ul>
						<li>Mobile device ID</li>
						<li>Model and manufacturer</li>
						<li>Operating system</li>
						<li>Version information</li>
						<li>IP address</li>
					</ul>
					<Paragraph><Text strong>Payment Information:</Text> Your payment data will be processed by the payment processors available at SneaCare (Stripe), which will process and store your data securely and for the sole purpose of processing the purchase of the services. SneaCare reserves the right to contract any payment platform available in the market, which processes your data for the sole purpose of processing the purchase of the services.</Paragraph>
					<Paragraph>View the Stripe privacy policy here:</Paragraph>
					<ul>
						<li> <a href="https://stripe.com/gb/privacy" target="_blank" rel="noopener noreferrer"> https://stripe.com/gb/privacy </a> </li>
					</ul>
					<Paragraph><Text strong>GOOGLE Analytics.</Text> We use Google Analytics provided by Google, Inc., USA (“Google”). These tool and technologies collect and analyze certain types of information, including IP addresses, device and software identifiers, referring and exit URLs, feature use metrics and statistics, usage history, media access control address (MAC Address), mobile unique device identifiers, and other similar information via the use of cookies. The information generated by Google Analytics (including your IP address) may be transmitted to and stored by Google on servers in the United States. We use the GOOGLE Analytics collection of data to enhance the website and improve our service.</Paragraph>
					<Paragraph>Please consult Google’s privacy policy here:</Paragraph>
					<ul>
						<li> <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer"> https://policies.google.com/privacy </a> </li>
					</ul>
					<Paragraph><Text strong>Facebook Pixel:</Text> Our website uses the Facebook Pixel. Through the Facebook Pixel we can collect user information for different purposes. We use the Facebook Pixel for the following purposes:</Paragraph>
					<ul>
						<li>Collect statistics about our platform (for example, the number of users who visited a page).</li>
						<li>Collect information about how you interact with our platform (for example, whether you opened or followed links contained in them).</li>
						<li>Personalize online services and marketing communications.</li>
						<li>Version information</li>
						<li>Tailor advertisements to users and optimize advertising campaigns.</li>
					</ul>
					<p>The information collected through the Facebook Pixel will be collected and stored by Facebook and will be treated in accordance with its privacy policy. The information we collect through the Facebook Pixel does not personally identify the user and will never be used for purposes other than those contained in this privacy policy and Facebook’s privacy policy.</p>
					<p>Please consult Facebook’s privacy policy here:</p>
					<ul>
						<li>
							<a href="https://www.facebook.com/privacy/exsubscriptionation" target="_blank" rel="noopener noreferrer">
								Facebook Privacy Policy
							</a>
						</li>
					</ul>
					<Text>
						<Text strong>Social Media: </Text>On our website, you will find links and functions linked to different social media, where you can share your information.
					</Text>
					<Text>
						It is advisable to consult the privacy policy and data protection of each social network used on our website.
					</Text>
					<ul>
						<li>
							Facebook: <a href="https://www.facebook.com/privacy/exsubscriptionation" target="_blank" rel="noopener noreferrer">Facebook Privacy Policy</a>
						</li>
						<li>
							Instagram: <a href="http://instagram.com/about/legal/privacy/" target="_blank" rel="noopener noreferrer">Instagram Privacy Policy</a>
						</li>
					</ul>
					<Title level={5}>HOW LONG WE KEEP YOUR DATA</Title>
					<Paragraph>Personal data provided by users through the website and services will be retained for as long as necessary for the provision of the services or until the user decides to close the user account or until SneaCare decides to close the user account. SneaCare may be allowed to retain personal data for a longer period whenever the user has given consent to such processing, as long as such consent is not withdrawn. Furthermore, SneaCare may be obliged to retain personal data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority. Once the retention period expires, personal data shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after expiration of the retention period.</Paragraph>

					<Title level={5}>HOW WE USE YOUR INFORMATION.</Title>
					<Paragraph>In general, we use the information we collect primarily to provide, maintain, protect and improve our current website and services. We use personal information collected through our website as described below:</Paragraph>
					<ul>
						<li>Provide the app (Available on Google Play and App store).</li>
						<li>Identify you as a user in our system.</li>
						<li>User registration.</li>
						<li>Provide the services.</li>
						<li>Pick up and deliver the sneakers.</li>
						<li>Process payments.</li>
						<li>Send invoices for services to clients.</li>
						<li>Improve our services, website, and how we operate our business.</li>
						<li>Understand and enhance your experience using our website and services.</li>
						<li>Respond to your comments or questions through our support team.</li>
						<li>
							Send you related information, including confirmations, invoices, technical notices, updates, security alerts, and support and administrative messages.
						</li>
						<li>
							Communicate with you about upcoming events, offers, and news about services offered by SneaCare and our selected partners.
						</li>
						<li>Marketing purposes of SneaCare.</li>
						<li>
							Link or combine your information with other information we get from third parties to help understand your needs and provide you with better service.
						</li>
						<li>Protect, investigate, and deter against fraudulent, unauthorized, or illegal activity.</li>
					</ul>

					<Title level={5}>HOW DO YOU GET MY CONSENT?</Title>
					<Paragraph>By registering on the website, purchasing our services, providing information through our services, contacting us through our contact forms or our contact information, and providing personal information to us to contact you, you consent to our collection, storage and use of your information on the terms contained in this privacy policy. You may withdraw your consent by sending us your request via the contact information or the contact page.</Paragraph>

					<Title level={5}>HOW WE SHARE YOUR INFORMATION</Title>

					<Paragraph>The personal information of our customers and users is an important and fundamental part of our business. Under no circumstances will we sell or share information with third parties that has not been previously authorized by the user, client or owner of the personal data. We share user and customer information only and exclusively as described below.</Paragraph>
					<Paragraph><Text strong>Third-Party Service Providers.</Text> We use third-party services to perform functions on our website. Examples include: creating and hosting the website, processing payments (Stripe), ending emails, analyzing data (Google Analytics), creating ads (Facebook, Google), providing marketing services and providing search results.</Paragraph>
					<Paragraph>These third-party services and tools may have access to personal information needed to perform their functions, but may not use that information for other purposes. Information shared with these third-party services will be treated and stored in accordance with their respective privacy policies and our privacy policy. Please refer to the privacy policy of these third-party services.</Paragraph>
					<Paragraph><Text strong>Email marketing:</Text> We use the information provided by users to conduct marketing campaigns and send relevant information to our users. By providing us with your email address, you authorize us to use your information for email marketing. We will use third party services to conduct email marketing, so we may share certain information with some of these third parties for the sole and exclusive purpose of sending emails through email marketing and in accordance with our privacy policy.</Paragraph>
					<Paragraph><Text strong>SMS text messages.</Text> We use SMS text messaging, to send different types of messages such as notifications about your order or special content such as offers on our products. By placing an order and opting to receive our SMS messages, you agree to these terms and conditions.</Paragraph>
					<Paragraph>By entering your phone number on the checkout form and subscribing through our subscription form, you consent to our sending you SMS text notifications (order notifications, including abandoned cart reminders) and offers for our products. Please note that your consent to opt-in or opt-out of receiving these messages is not a condition of any purchase on our website. Your phone number, name and purchase information will be shared with the SMS marketing platform we use. This data will be used to send you targeted marketing messages and notifications. When sending text messages, your phone number will be shared with our applicable text messaging carrier for the purpose of fulfilling the sending and delivery of messages.</Paragraph>
					<Paragraph>If you wish to unsubscribe from receiving marketing text messages and notifications, please reply with STOP to any SMS message sent by us. You understand and agree that alternative methods of unsubscribing, such as the use of alternative words or requests, will not be considered a reasonable means of unsubscribing. Message and data usage fees may apply.</Paragraph>
					<Paragraph>If you have any questions, you may contact us for more information.</Paragraph>
					<Paragraph><Text strong>Business Transfers. </Text> In the event that SneaCare creates, merges with, or is acquired by another entity or company, your information will likely be transferred. SneaCare will send you an email or post a prominent notice on our platform before your information becomes subject to another privacy policy and the transfer and handling of your private information will comply with all applicable requirements.</Paragraph>
					<Paragraph><Text strong>Protection of SneaCare and others. </Text>We release personal information when we believe release is appropriate to comply with the law, enforce or apply our Terms and conditions and other agreements, or protect the rights, property, or safety of SneaCare, our users or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.</Paragraph>
					<Paragraph><Text strong>With Your Consent.</Text> Other than as set out above, you will receive notice when personally identifiable information about you might go to third parties, and you will have an opportunity to choose not to share the information.</Paragraph>
					<Paragraph><Text strong>Anonymous Information.</Text> SneaCare uses the anonymous browsing information collected automatically by our servers primarily to help us administer and improve the Website. We may also use aggregated anonymous information to provide information about the Website to potential business partners and other unaffiliated entities. This information is not personally identifiable.</Paragraph>
					<Paragraph><Text strong>Email Address. </Text> The email address that you supply to us for purposes of receiving our email communications will never be rented or sold to a third party.</Paragraph>


					<Title level={5}>PROTECTING YOUR INFORMATION</Title>
					<Paragraph>We work to protect the security of your information during transmission by using Secure Sockets Layer (SSL) software, which encrypts information you provide through the website. We restrict authorized access to your personal information to those persons who have a legitimate purpose to know that information and to those persons you have authorized to have access to that information. SneaCare follows generally accepted industry standards to protect the personal information submitted to us, both during transmission and once SneaCare receives it. No method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while SneaCare strives to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security. We will not sell, distribute, or lease your personal information to third parties unless we have your permission or are required by law to do so.</Paragraph>

					<Title level={5}>RIGHTS</Title>
					<Paragraph>Users who provide information through our website, as data subjects and data owners, have the right to access, rectify, download or delete their information, as well as to restrict and object to certain processing of their information. While some of these rights apply generally, others apply only in certain limited circumstances. We describe these rights below:</Paragraph>

					<ul>
						<li>
							Access and Portability: To access and know what information is stored in our servers, you can send us your request through our contact information.
						</li>
						<li>Rectify, Restrict, Limit, Delete: You can also rectify, restrict, limit, or delete much of your information.</li>
						<li>
							Right to be Informed: Users of our website will be informed, upon request, about what data we collect, how it is used, how long it is retained, and whether it is shared with third parties.
						</li>
						<li>
							Object: When we process your information based on our legitimate interests as explained above, or in the public interest, you may object to this processing in certain circumstances. In such cases, we will stop processing your information unless we have compelling legitimate reasons to continue processing it or where it is necessary for legal reasons.
						</li>
						<li>
							Revoke Consent: Where you have previously given your consent, such as to allow us to process and store your personal information, you have the right to revoke your consent to the processing and storage of your information at any time. For example, you may withdraw your consent by updating your settings. In certain cases, we may continue to process your information after you have withdrawn your consent if we have a legal basis for doing so or if your withdrawal of consent was limited to certain processing activities.
						</li>
						<li>
							Complaint: If you wish to file a complaint about our use of your information (and without prejudice to any other rights you may have), you have the right to do so with your local supervisory authority. Users can exercise all these rights by contacting us through the contact information or the contact page.
						</li>
						<li>
							Rights Related to Automated Decision-Making, Including Profiling: Website users may request that we provide a copy of the automated processing activities we conduct if they believe that data is being unlawfully processed.
						</li>
					</ul>

					<Paragraph>Users may exercise their rights in relation to the personal data they provide to SneaCare, at any time, by sending us their request through our contact information and we will process and respond to their request as soon as possible.</Paragraph>

					<Title level={5}>CHILDREN’S ONLINE PRIVACY PROTECTION </Title>
					<Paragraph>We comply with the requirements of the Florida Privacy Protection Act (FPPA), regarding the protection of the personal data of minors. Although the website and services are available to all ages, we do not collect any information from children under the age of 13 without the respective permission of their parents or legal guardians. If you become aware that a child under the age of 13 has provided us with personal information without the permission of a parent or legal guardian, please contact us. If we become aware that a minor has provided us with personal information without parental or legal guardian permission, we will take steps to delete that information, terminate that person’s account, and restrict access to that person’s account.</Paragraph>

					<Title level={5}>THIRD PARTIES</Title>
					<Paragraph>Except as otherwise expressly included in this privacy policy, this document addresses only the use and disclosure of information SneaCare collects from you. If you disclose your information to others, whether other users or suppliers on SneaCare, different rules may apply to their use or disclosure of the information you disclose to them. SneaCare does not control the privacy policies of third parties, and you are subject to the privacy policies of those third parties where applicable. SneaCare is not responsible for the privacy or security practices of other websites on the Internet, even those linked to or from the “SneaCare” website. SneaCare encourages you to ask questions before you disclose your personal information to others.</Paragraph>

					<Title level={5}>CONTACT US </Title>
					<Paragraph>If you have questions or concerns about this privacy policy and the handling and security of your data, please contact us through our contact forms, our chat or by using the contact information below:</Paragraph>

					<Paragraph><Text strong>SneaCare, Say Hello To Clean Shoes.</Text></Paragraph>

					<Paragraph><Text>Email: <a href="mailto:hello@sneacaremiami.com">hello@sneacaremiami.com</a></Text></Paragraph>
				
				</Col>
			</Row>
		)
	}


}



export default function Modals(props) {

	const { visible = false, onClose = () => { } } = props

	return <Modal
		open={visible}
		onCancel={onClose}
		title={"Privacy Policy"}
		closable={true}
		destroyOnClose={true}
		zIndex={1000}
		okText="OK"
		width={800}
		onOk={onClose}
		cancelButtonProps={{display: "none"}}
	>
		<ModalAvisoPrivacidad  {...props} />
	</Modal>

}