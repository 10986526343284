import React, { Component, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Col, Row, Space, Typography, Pagination, Input, message } from 'antd';
import { useCookies } from "react-cookie";
import { User } from "../../../Hooks/Logged"
import { Carrito, SetCarrito } from "../../../Hooks/Carrito"

import axios from 'axios';

import MyHeader from '../Header';
import FooterSne from '../Footer';
import ProductCard from '../ProductItem';
import { getResponseError } from '../../Utils';
const { Content, Footer } = Layout
const { Title, Text } = Typography

const { Search } = Input;
const Section = (props) => <section {...props}>{props.children}</section>


class Tienda extends Component {

    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            visible_cart: false,
            productos: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
                search: null,
                filters: []
            },
        }
    }

    componentDidMount() {
        this.getProductos()
    }
    setVisible = () => {
        this.setState({ visible_cart: true })
    }

    /**
       *
       * @memberof Productos
       * @method getProductos
       * @description Obtiene la lista de productos
       */
    getProductos = async ({
        page = this.state.productos.page,
        limit = this.state.productos.limit,
        search = this.state.productos.search
    } = this.state.productos) => {

        axios.get('/products', {
            params: {
                limit,
                page,
                search,
                store: true
            }
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({ productos: data })
            })
            .catch(error => {
                console.log("error", error);
                message.error(getResponseError(error.response, 'Can\'t get the products'))
            })
    }


    /**
     *
     *
     * @memberof Tienda
     * @description Añade un elemento al carrito
     */
    addToCart = (servicio = {}) => {

        console.log('add service to cart', servicio)

        if (Object.keys(servicio).length > 0) {
            console.log('add service ')

            //abrir cotizador
            if (servicio.limpieza) {
                this.setState({ servicio_cotizar: servicio, visible: true })
            }
            else {
                this.sendQuote(servicio)
            }
        }

    }


    render() {
        return (
            <>
                <Layout style={{
                    background: 'rgb(127,135,203)',
                    background: 'linear-gradient(180deg, rgba(127,135,203,1) 0%, rgba(255,255,255,1) 100%)',
                    height: '100vh',
                }}
                    className='landing'>
                    <MyHeader
                        {...this.props}
                    />
                    <Content className='landing' style={{ marginTop: 100 }}>
                        <Section className="section  section-reduce" >
                            <div className='section-container'>
                                <Row gutter={[8, 8]} className='pt-2 pb-2' style={{ justifyContent: "end" }}>
                                    <Col span={12} lg={12} md={24} xs={24}>
                                        <Search placeholder="Search by name, model, sku, etc..." allowClear onSearch={(search) => this.getProductos({ page: 1, search })} className='width-100' />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} >
                                        <Title level={2} className="text-white title custom ">List of Products</Title>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]} align={"middle"} justify={"center"} className='width-100 mb-2'>
                                    {this.state.productos?.data?.map(producto => <Col className='center' xxl={4} xl={6} lg={8} md={12} xs={24}>
                                        <ProductCard producto={producto} onClick={() => this.props.navigate('/product/detail/' + producto?._id)} />
                                    </Col>)}
                                </Row>
                                <Row style={{ marginBottom: 60, justifyContent: "end" }}>
                                    <Pagination
                                        defaultCurrent={1}
                                        total={this.state.productos.total}
                                        onChange={(page, limit) => this.getProductos({ page, limit })}
                                        current={this.state.productos.page}
                                        pageSize={this.state.productos.limit}
                                        hideOnSinglePage={false}
                                        position={'bottom'}
                                    />
                                </Row>
                            </div>
                        </Section>
                        <FooterSne />
                    </Content>
                </Layout>
            </>
        )
    }
}

export default props => {
    const navigate = useNavigate()

    let cart = useContext(Carrito);
    let setCart = useContext(SetCarrito)

    let user = useContext(User)
    const [cookies, setCookie] = useCookies(['cart']);

    return <Tienda
        {...props}
        cart={cart}
        setCart={setCart}
        cookies={cookies}
        setCookie={setCookie}
        user={user}
        navigate={navigate} />
}