import React from 'react'
import { Route, Routes } from "react-router-dom";

import VentasList from '../../Components/Admin/Ventas/VentasList';
import VentasTab from '../../Components/Admin/Ventas/VentasTab'
import VentaDetalle from '../../Components/Admin/Ventas/VentaDetalle';

/**
 * 
 * @export
 * @function RouterCategorias
 * @description Router for Categorias routes 
 */
function RouterVentas(props) {
  return (
    <Routes>
      <Route path="" element={<VentasTab {...props} />} />
      <Route path="detalle/:venta_id" element={<VentaDetalle {...props} />} />

      <Route path="/list" element={<VentasList {...props} />} />
    </Routes>
  )
}

export default RouterVentas