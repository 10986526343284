import React, { Component } from 'react'
import { Row, Col, Form, Input, Button, Typography, Modal, Spin } from 'antd';
import '../../Styles/Global/auth.scss';

import axios from 'axios';

const { Title, Text } = Typography

/**
 * 
 * @class Recovery
 * @extends {Component}
 * @description Componente Recovery para recuperar contraseñas
 */
class Recovery extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		}
	}

	form = React.createRef()

	handleSubmit = (values) => {
		this.setState({ loading: true })
		axios.put("/password/recovery", { email: values.email })
			.then(res => {
				Modal.success({
					title: 'Password Recovery.',
					content: 'The email has been sent!'
				})
			})
			.catch(res => {
				console.log(res)
				Modal.warning({
					title: 'Error',
					content: 'The email could not be sent. Check that the email is correct'
				});
			})
			.finally(() => {
				this.setState({
					loading: false
				})
			})
	}



	render() {
		return (
			<Row className='layout-login'>
				<Col xs={24} lg={10} className="col-welcome">
					<Row>
						<a href={'/'}>
							<img width="400" src="/logo-white.png" alt="Sneacare" />
						</a>
					</Row>
				</Col>
				<Col xs={24} lg={14}>
					<Spin spinning={this.state.loading}>
						<div className='login-form-container'>
							<Form onFinish={this.handleSubmit} layout={"vertical"} requiredMark={false} className='login-form' ref={this.form}>
								<Title level={3} className="h3-title">Password Recovery</Title>
								<div className="mb-3">Enter your email and we will send you a reset link</div>
								<Form.Item className='input-name' name="email" label="Email"
									rules={[
										{ required: true, message: 'Please, enter your email' },
										{
											type: 'email',
											message: 'Email is not valid',
										},
										{
							            	validator: (_, value) => {
								              	// Transformar el valor a minúsculas
								              	const lowercaseValue = value.toLowerCase();
								              	if (value !== lowercaseValue) this.form.current.setFieldsValue({ email: lowercaseValue });
							          			return Promise.resolve();
							            	},
							        	}
									]}>
									<Input placeholder="example@gmail.com" size="large" />
								</Form.Item>
								<Form.Item>
									<Button
										type="primary"
										htmlType="submit" block size="large">Send me the link</Button>
								</Form.Item>
							</Form>
						</div>
					</Spin>
				</Col>
			</Row>
		)
	}
}

export default function View(props) {

	return <Recovery {...props} />
}