import React from 'react';
import { Input, Space, Button, } from 'antd';
import { PlusOutlined, MinusOutlined, DeleteOutlined } from '@ant-design/icons';
import './ProductControl.scss';


export default function ShoppingCart(props) {

    const {
        onQuantityChange = () => { },
        deleteProduct = () => { },
        index = 0,
        quantity = 0,
        className = "",
        limit = 0,
        deleteOption
    } = props

    return <Input
            size="large"
            value={quantity}
            max={limit}
            onChange={e => onQuantityChange(index, e.target.value)}
            className="control-input-producto width-100  "
            addonBefore={<Button
                onClick={e => onQuantityChange(index, parseInt(quantity) - 1)}
                className="control-button-minus-producto"
                type="text"
                icon={<MinusOutlined  style={{color: "#426cb4"}}/>}
            />}
            addonAfter={<Button
                onClick={e => onQuantityChange(index, parseInt(quantity) + 1)}
                className="control-button-plus-producto"
                type="text"
                icon={<PlusOutlined  style={{color: "#426cb4"}}/>}
            />}
        />


}
