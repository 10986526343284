
import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { User, SetUser } from './Hooks/Logged';
import axios from 'axios'
import Routes from './Routes';
import { ConfigProvider, theme } from 'antd';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { CookiesProvider } from 'react-cookie';


// import './Styles/Fonts/Satoshi/Satoshi.scss';
import './Styles/Fonts/Satoshi/stylesheet.css';

axios.defaults.baseURL = process.env.REACT_APP_WEB_SERVICE
axios.defaults.withCredentials = true
axios.defaults.headers.common["Content-Type"] = "application/json";

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  if (response?.headers?.authorization) {
    sessionStorage.setItem("token", response?.headers?.authorization)
  }
  return response
}, function (error) {
  return Promise.reject(error);
})

axios.interceptors.request.use(function (config) {
  if(sessionStorage.getItem("token"))
    config.headers.Authorization = sessionStorage.getItem("token")
  return config;
})



/**
 * 
 * @class App
 * @extends {Component}
 */
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: 0,
      // socket: createSocket()
    }
  }

  componentDidMount() {
    axios.get('/user/logged', {
      headers: { Authorization: sessionStorage.getItem('token') }
    }).then(({ data }) => {
      this.setUser(data.data)
    }).catch((error) => {
      console.log('error', error.response)
    })
  }

  setUser = (user) => this.setState({ user })

  render() {
    const { setUser } = this;
    const { user, socket } = this.state;
    return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENTID}>
        <ConfigProvider
          theme={{
            colorPrimary: 'red',
            token: {
              algorithm: theme.darkAlgorithm,
              fontFamily: "Satoshi",
              colorPrimary: 'rgba(66,108,180,0.75)',
            },
          }}
        >
          <BrowserRouter>
            <CookiesProvider>
              <User.Provider value={user}>
                <SetUser.Provider value={setUser}>
                  <Routes />
                </SetUser.Provider>
              </User.Provider>
            </CookiesProvider>
          </BrowserRouter>
        </ConfigProvider >
      </GoogleOAuthProvider>
    )
  }
}

export default function (props) {

  return <App {...props} />

}