import React from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';

const ButtonDelete = (props) => {

	let {
		type = "primary",
		className,
		icon = <DeleteOutlined style={{ color: 'currentcolor' }} />,
		onConfirm = () => { },
		disabled = false
	} = props

	return <Popconfirm
		placement="topRight"
		title="Do you want to delete this record?"
		onConfirm={onConfirm}
		okText="Yes"
		cancelText="No"
	>
		<Button
			{...props}
			type={type}
			danger
			className={className}
			icon={icon}
			disabled={disabled}
		/>
	</Popconfirm>
}

const ButtonView = (props) => {

	let {
		className,
		icon = <EyeOutlined style={{ color: 'currentcolor' }} />,
		onClick = () => { },
		text=null
	} = props

	return <Tooltip placement="topRight" title={text}> 
		<Button
			type='primary'
			{...props}
			className={className}
			icon={icon}
			onClick={onClick}
		/>
	</Tooltip>
}

const ButtonEdit = (props) => {

	let {
		type = "primary",
		className,
		icon = <EditOutlined style={{ color: 'currentcolor' }} />,
		onClick = () => { },
		text = null
	} = props

	return <Tooltip placement="topRight" title={text}>
		<Button
			{...props}
			// type={type}
			className={className}
			icon={icon}
			onClick={onClick}
		/>
	</Tooltip>
}


export {
	ButtonDelete,
	ButtonView,
	ButtonEdit,
}