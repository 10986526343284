import React, { useState, useEffect, useContext } from "react";
import { Badge, Button, Layout, Menu, Dropdown, Row, Col, Avatar, Space, Typography, Drawer } from "antd";
import { UserOutlined, ShoppingOutlined, DownOutlined, MenuOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import DrawerCarrito from "./Carrito/Drawer";
import { User, SetUser } from "../../Hooks/Logged";

import axios from "axios";

import "../../Styles/Modules/headers.scss";
// import  from 'antd/es/skeleton/Paragraph';

const { Header } = Layout;
const { Paragraph } = Typography;

/**
 * @const Header header publico de la landing page
 * @param {*} props
 * @returns Component
 */
const HeaderPublic = (props) => {

	let { openDrawer, setOpenDrawer = () => {} } = props;

	const navigate = useNavigate();
	const user = useContext(User);
	const setUser = useContext(SetUser);

	const [isVisible, setIsVisible] = useState(false);
	const [hasScrolled, setHasScrolled] = useState(false);
	const [openDrawerMenu, setOpenDrawerMenu] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			// Si el usuario ha hecho scroll hacia abajo
			if (window.scrollY > 0) {
				setHasScrolled(true);
			} else {
				// Si el usuario vuelve al inicio de la página
				setHasScrolled(false);
			}
		};

		// Añadir el listener de scroll
		window.addEventListener("scroll", handleScroll);

		// Limpiar el listener al desmontar el componente
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	useEffect(() => {
		if (props.cookies.cart) {
			axios
				.get("/carrito/" + props.cookies.cart, {})
				.then(({ data }) => {
					console.log("carrito HEADER", data);
					props.setCart(data);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, []);

	useEffect(() => {
		if (openDrawer) {
			setVisible();
		}
	}, [openDrawer]);


	const setVisible = () => {
		setIsVisible(!isVisible);
		if (!isVisible === false) {
			setOpenDrawer(!isVisible);
		}
	};

	const redirect = () => {
		if (user?.cliente_id) {
			navigate("/customer/dashboard");
		} else if (user) {
			navigate("/admin/dashboard");
		} else {
			navigate("/login");
		}
	};

	/**
	 * @const cerrarSesion
	 * @description Cierra la sesion
	 */
	const cerrarSesion = () => {
		setUser(undefined);
		sessionStorage.clear();
		navigate("/");

		if (window.FB) {
			window.FB.logout(function (response) {
				console.log("response", response);
			});
		}
	};

	const renderMenu = (vertical) => {
		let items = [
			{
				className: "header-public-menu-logo",
				label: (
					<img
						src="/img/logo-color.png"
						className="menu-logo"
						color={hasScrolled ? "black" : "white"}
						
					/>
				),
				key: "start",
				onClick: () => {
					const section = document.getElementById("main");

					if (section) {
						section.scrollIntoView({
							behavior: "smooth",
							block: "start",
						});
					} else {
						navigate("/");
					}
				},
			},
			{
				className: !vertical ? "menu-item-responsive" : "",
				label: "Services",
				key: "services",
				onClick: () => {
					const section = document.getElementById("services");

					if (section) {
						section.scrollIntoView({
							behavior: "smooth",
							block: "start",
						});
					} else {
						navigate("/");
					}
				},
			},
			{
				className: !vertical ? "menu-item-responsive" : "",
				label: "Our process",
				key: "process",
				onClick: () => {
					const section = document.getElementById("process");

					if (section) {
						section.scrollIntoView({
							behavior: "smooth",
							block: "start",
						});
					} else {
						navigate("/");
					}
				},
			},
			{
				className: !vertical ? "menu-item-responsive" : "",
				label: "Shop",
				key: "shop",
				onClick: () => navigate("/store"),
			},
			{
				className: !vertical ? "menu-item-responsive" : "",
				label: "Contact",
				key: "contact",
				onClick: () => {
					const section = document.getElementById("footer");

					if (section) {
						section.scrollIntoView({
							behavior: "smooth",
							block: "start",
						});
					} else {
						navigate("/");
					}
				},
			},
		];

		let buttonCart = (
			<Badge
				className={`badge-cart ${hasScrolled ? "scrolled" : ""}`}
				count={props.cart?.detalles?.length}
			>
				<Button
					type="link"
					onClick={setVisible}
					icon={
						<ShoppingOutlined
							style={{
								color:  "black",
								fontSize: "20px",
							}}
						/>
					}
				/>
			</Badge>
		);

		if (user){
			items.push({
				className: `header-public-menu-item ${!vertical ? "menu-item-responsive menu-item-logged" : ""}`,
				key: "user",
				label: (
					<Dropdown
						className=""
						arrow={true}
						placement="bottomRight"
						overlay={
							<Menu>
								<Menu.Item key="0" onClick={redirect}>
									Dashboard
								</Menu.Item>
								<Menu.Item key="1" onClick={cerrarSesion}>
									Log Out
								</Menu.Item>
							</Menu>
						}
						trigger={["click"]}
					>
						<Space onClick={(e) => e.preventDefault()} className="user-menu">
							{user?.nombre}
							<Avatar
								src={
									<UserOutlined
										style={{
											color: "black",
											padding: 0,
										}}
									/>
								}
							/>
						</Space>
					</Dropdown>
				),
			});
		}else
			items.push({
				className: `header-public-menu-item ${!vertical ? "menu-item-responsive" : ""}`,
				key: "test",
				label: (
					<Space size={2}>
						<Button
								size="large"
								type="primary"
								onClick={() => navigate("/login")}
						>
							Sign In / Log In
						</Button>
					</Space>
				),
			});

		items.push({
			className: !vertical ? "menu-item-responsive" : "",
			key: "cart",
			label: buttonCart,
		});

		if(!vertical)
			items.push({
				className: "menu-item-responsive-small",
				key: "cart",
				label: <Button icon={<MenuOutlined />} onClick={()=>setOpenDrawerMenu(true)}/>,
			});

		return items;
	};

	return (
		<>
			<Header
				className={"header-public " + (hasScrolled ? "is-scrolling" : "")}
			>
				{/* <Row className="general-ribbon">
					<Paragraph className="ribbon">
						SHOP NOW TO GET FREE SHIPPING AND DISCOUNT 40% ON ALL ORDER TODAY!
					</Paragraph>
				</Row> */}
				<Row className="header-public-container">
					<div className="header-public-menu">
						<Menu
							style={{ background: "white" }}
							className="header-public-menu"
							mode="horizontal"
							//theme={hasScrolled ? "light" : "dark"}
							items={renderMenu()}
						/>
					</div>
				</Row>
			</Header>

			{isVisible && (
				<DrawerCarrito open={isVisible} close={setVisible} {...props} />
			)}

			<Drawer
		        placement={"left"}
		        width={250}
		        open={openDrawerMenu}
				onClose={()=>setOpenDrawerMenu(false)}
		    >
		        <Menu
		        	className="menu-vertical"
					style={{ background: "white" }}
					mode="vertical"
					items={renderMenu("vertical")}
				/>
		    </Drawer>
		</>
	);
};

export default HeaderPublic;
