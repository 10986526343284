import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, Input, Select, message, Spin, } from 'antd';

import { getResponseError } from "../../Utils";
import axios from "axios"

/**
 *
 *
 * @class FormCostoQuotation
 * @extends {Component}
 */
class FormCostoQuotation extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    formRef = React.createRef();


    /**
     * @memberof FormCostoQuotation
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {
        axios.put(`/cotizacion`, {
            ...values,
            id: this.props.cotizacion_id,
        })
            .then(res => {
                message.success("Quotation Cost updated!")
                this.props.update()
            })
            .catch(error => {
                console.log('error', error.response)
                message.error(getResponseError(error.response, "Error updating quotation cost"))
            })
    }

    render() {

        return (
            <Form
                layout="vertical"
                name="form-cost-quotation"
                id="form-cost-quotation"
                ref={this.formRef}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item
                                label="New Cost"
                                name="costo"
                                rules={[{
                                    required: true,
                                    message: 'Enter the new cost of quotation'
                                }]}
                            >
                                <Input  type="number"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={"Change Quotation Cost"}
        destroyOnClose={true}
        cancelText="Cancel"
        okText="Save"
        centered={true}
        okButtonProps={{ form: 'form-cost-quotation', key: 'submit', htmlType: 'submit' }}
    >
        <FormCostoQuotation {...props} />
    </Modal>

}