import React, { Component, } from 'react';
import { Row, Col, Layout, Card, Tooltip, Space, Button, Typography, Select, Switch } from 'antd'
import axios from 'axios';
import dayjs from "dayjs";
import ReactECharts from 'echarts-for-react';
import { FaCreditCard } from "react-icons/fa";
import { QuestionCircleOutlined, FilterOutlined, DownloadOutlined } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MdPointOfSale, MdAttachMoney, MdOutlineCleaningServices } from "react-icons/md";

//Componentes
import useSearch from '../../../Hooks/useSearch';
import { CardStatistics } from '../../Widgets/Cards';
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import DrawerFiltros from '../../Widgets/DrawerFiltros/DrawerFiltros'
import { IconWallet, IconGraphic, IconCuenta, IconShield } from '../../Widgets/Iconos'

const { Content } = Layout;
const { Text, Title } = Typography;


/**
 *
 *
 * @export
 * @class Analytics
 * @extends {Component}
 * @description Vista donde se muestra un resumen de las estadisticas del sistema
 */
class Analytics extends Component {

    back = () => {
        this.props.navigate(-1)
    }


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            total_ventas: 0,
            ventas_pagadas: 0,
            ventas_pendientes: 0,
            pares_limpiados: 0,
            total_ventas_total: 0,
            ventas_por_año: [],
            servicios_lux : { ids: [], servicios: 0},
            servicios_everyday: { ids: [], servicios: 0 },
            filters: [],
            ventas_tipo: "Monthly Income",



            ventas_count: 0,
            clientes_count: 0,
            cotizaciones_count: 0,
            productos_count: 0,
            ventas_servicio: {},
            ventas_clientes: {},
            serie_anual: [],
            fechas: [],
        }
    }


    componentDidMount() {
        this.props.setShowSearch(false)
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getInfo()
        this.getSalesPerYear()
    }

    /**
    * @memberof Analytics
    * @method getInfo
    * @description Obtiene la informacion del sistema general
    */
    getInfo = ({filters} = {}) => {
        console.log("getInfo");
        this.setState({ loading: true })
        axios.get('/analytics-dashboard',{
            params:{
                filters
            }
        })
        .then(({data}) => {
        	console.log("data", data);
        	this.setState({...data, filters})

        }).catch(error => {
            console.log("error", error);

        }).finally(()=>this.setState({loading: false}))
    }

    /**
    * @memberof Analytics
    * @method getYears
    * @description Retorna una lista de años para el Select de la grafica
    */
    getYears = () => {

        let año_inicio = parseInt(dayjs("2023-01-01").format("YYYY"))
        let año_actual = parseInt(dayjs().format("YYYY"))

        let options = []

        for (let i = año_inicio; i <= año_actual; i++) {
            options.push({
                value: i,
                label: i
            })
        }

        return options

    }

    getSalesPerYear = (year) => {
        this.setState({loading: true})
        axios.get('/analytics-grafica',{
            params:{
                year
            }
        }).then(response => {
            console.log("response", response.data);
            this.setState({
                ...response.data,
            })

        }).catch(error => {
            console.log("error", error);

        }).finally(()=>{
            this.setState({loading: false})
        })
    }

    /**
     *
     *
     * @memberof FiltrosDrawer
     * @method downloadCSV
     */
    downloadCSV = () => {
        let url = new URL(axios.defaults.baseURL + "/analytics-csv/ventas")

        let { filters } = this.state;

        if (filters) {
            filters.forEach(f => {
                url.searchParams.append('filters[]', JSON.stringify(f))
            });
        }

        url.searchParams.set('Authorization', sessionStorage.getItem('token'))

        window.open(url, '_blank');
    }

    render() {

        return (
            <>
                <Content>
                    <Row gutter={[16, 16]}>
                    	<Col span={24}>
                    		<Space>
                    			<Button
                            		onClick={()=>this.setState({visibleFilters: true})}
                            		icon={<FilterOutlined />}
                        		/>
                                <Button
                                    onClick={this.downloadCSV}
                                    icon={<DownloadOutlined color='#' />}
                                >CSV</Button>
                    		</Space> 
                    	</Col>
                        <Col xs={24} sm={12} md={12} lg={6} className="grid">
                            <CardStatistics 
                            	title={<>Total Sales <Tooltip placement="topRight" title={"Total sales recorded in the system"}> <QuestionCircleOutlined style={{fontSize: "14px", color: "black"}}/> </Tooltip></>}
                            	suffix={""} 
                            	monto={<>{this.state?.total_ventas?.toMoney(true, false) ?? 0} <br/> <small>$ {this.state?.total_ventas_total?.toMoney(true, false) ?? 0}</small> </>} 
                            	icon={(color) => <MdPointOfSale style={{color: "white"}} />}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6} className="grid">
                            <CardStatistics 
                            	title={<>Paid Sales <Tooltip placement="topRight" title={"Total sales with paid status"}> <QuestionCircleOutlined style={{fontSize: "14px", color: "black"}}/> </Tooltip></>}
                            	suffix={""} 
                                monto={<>{this.state?.ventas_pagadas?.toMoney(true, false) ?? 0} <br/> <small>$ {this.state?.ventas_pagadas_total?.toMoney(true, false) ?? 0}</small> </>} 
                            	icon={(color) => <MdAttachMoney style={{color: "white"}} />} 
                            />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6} className="grid">
                            <CardStatistics
                            	title={<>Pending Sales <Tooltip placement="topRight" title={"Total sales with pending payment status"}> <QuestionCircleOutlined style={{fontSize: "14px", color: "black"}}/> </Tooltip></>}
                            	monto={this.state?.ventas_pendientes?.toMoney(true, false) ?? 0}
                                monto={<>{this.state?.ventas_pendientes?.toMoney(true, false) ?? 0} <br/> <small>$ {this.state?.ventas_pendientes_total?.toMoney(true, false) ?? 0}</small> </>} 
                            	icon={(color) => <FaCreditCard style={{color: "white"}} />} 
                            />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6} className="grid">
                            <CardStatistics
                            	title={<>Cleaned Pairs <Tooltip placement="topRight" title={'Total pairs cleaned from sales with status of "Order ready" and "Order Completed"'}> <QuestionCircleOutlined style={{fontSize: "14px", color: "black"}}/> </Tooltip></>}
                            	suffix={""}  
                            	monto={this.state?.pares_limpiados?.toMoney(true, false) ?? 0} 
                            	icon={(color) => <MdOutlineCleaningServices style={{color: "white"}} />} 
                            />
                        </Col>
                        <Col xs={24} lg={16}>
                        	<Card 
                                title={<Title level={4}>Sales</Title>}
                                size="small" 
                                extra={<Space>
                                    <Text>{this.state.ventas_tipo}</Text>
                                    <Switch onChange={(check)=>{
                                        if(check)
                                            this.setState({ventas_tipo: "Sales per Month"})
                                        else
                                            this.setState({ventas_tipo: "Monthly Income"})

                                    }}/>
                                    <Select
                                        style={{minWidth: "100px"}}
                                        defaultValue={2024}
                                        options={this.getYears()}
                                        onChange={(year)=>this.getSalesPerYear(year)}
                                    />
                                </Space>}
                            >
                                <ReactECharts
                                    option={{
                                        grid: {
                                            containLabel: true,
                                        },
                                        title: {
                                            text: ''
                                        },
                                        tooltip: {
                                            formatter: '{b0}: {c0}',
                                        },
                                        xAxis: {
                                            type: 'category',
                                            data: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                                        },
                                        yAxis: {},
                                        series: [
                                            {
                                              data: this.state.ventas_por_año.map(e => {return this.state.ventas_tipo === "Monthly Income" ? e.total : e.count }),
                                              type: 'bar'
                                            }
                                        ]
                                    }}
                                    style={{ height: 450 }}
                                    //opts={{ renderer: 'svg' }}
                                           
                                />
                        	</Card>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Row gutter={[0,12]}>
                                <Col span={24}>
                                    <Card>
                                        <div className="flex-column">
                                            <Title level={4}>
                                                Sales whit SneaCare Lux
                                            </Title>
                                            <Text> {this.state.servicios_lux.ids?.length} Sales </Text>
                                            <Text> {this.state.servicios_lux.servicios} Services</Text>
                                        </div>
                                    </Card>
                                </Col>
                                 <Col span={24}>
                                    <Card>
                                        <div className="flex-column">
                                            <Title level={4}>
                                                Sales whit EveryDay Service
                                            </Title>
                                            <Text> {this.state.servicios_everyday.ids?.length} Sales </Text>
                                            <Text> {this.state.servicios_everyday.servicios} Services</Text>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Content>
                <DrawerFiltros
                    title="Filter Sales"
                    visible={this.state.visibleFilters}
                    onClose={() => this.setState({ visibleFilters: false })}
                    updateFilters={filters => this.getInfo({
                        filters
                    })}
                    fechas={true}
                    clientes={true}
                    servicios={true}
                />
            </>
        )
    }
}

export default props => {
    const { setShow } = useSearch()
    const navigate = useNavigate()
    const [params, setParams] = useSearchParams();



    return <Analytics {...props} setShowSearch={setShow}  navigate={navigate} params={params} />
}
