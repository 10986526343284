import React, { Component, useContext } from 'react';
import { Row, Col, Button, Popconfirm, Space, Spin, Layout, message, Typography, Tooltip, Card, List, Switch, Badge } from 'antd'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SquareLogo from '../../Widgets/Icons/Square.js';

//Componentes
import User from "../../../Hooks/Logged";
import { getResponseError } from "../../Utils.js"
import usePermissions from "../../../Hooks/usePermissions";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import ModalServicios from './ModalServicios.js'
import { ButtonDelete, ButtonEdit } from '../../Widgets/Buttons';
import useSearch from '../../../Hooks/useSearch';


const { Content } = Layout;
const { Text } = Typography;
/**
 *
 *
 * @export
 * @class Servicios
 * @extends {Component}
 * @description Vista de Project Manager
 */
class Servicios extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,
            servicio_id: undefined,

            servicios: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            }
        }
    }

    componentDidMount() {
        this.props.setShowSearch(true)
        this.get();
    }

    componentWillUnmount() {
        this.props.setShowSearch(false)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.search !== this.props.search) {
            this.get({ page: 1 })
        }
    }


    /**
    * @memberof Servicios
    * @method get
    * @description Trae las servicios 
    * 
    */
    get = ({
        page = this.state.servicios.page,
        limit = this.state.servicios.limit,
        search = this.props.search

    } = this.state.servicios) => {
        this.setState({ loading: true, servicios: { ...this.state.servicios, page, limit } })
        axios.get('/servicios', {
            params: { page, limit, search }
        }).then(({ data }) => {
            this.setState({ servicios: data })
        }).catch(error => {
            message.error(error.response?.data?.message)
        }).finally(() => this.setState({ loading: false }))
    }

    toggleActivo = (id, activo) => {
        axios.put('/servicios', {
            id,
            activo
        }).then(() => {
            message.success(`Service ${activo ? 'activated!' : 'inactivaded!'}`)
        }).catch(() => {
            message.error("Error toggling active from service")
        })
    }

    render() {

        const { servicios } = this.state

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="admin-page-header"
                        title="Services"
                    />

                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list responsive"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Services not found" }}
                            dataSource={servicios.data}
                            pagination={{
                                current: servicios.page,
                                pageSize: servicios.limit,
                                total: servicios.total,
                                showSizeChanger: true,
                                position: 'bottom',
                                onChange: (page, limit) => {
                                    this.get({ page, limit })
                                }
                            }}
                            header={<Row className="width-100 pr-1 pl-1 component-list-header" >
                                <Col xs={12} md={6}>
                                    <Text strong>Name</Text>
                                </Col>
                                <Col xs={12} md={7}>
                                    <Text strong>Description</Text>
                                </Col>
                                <Col xs={12} md={6} >
                                    <Text strong>Category</Text>
                                </Col>
                                <Col xs={12} md={2} >
                                    <Text strong>Active</Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100" gutter={[6, 8]} justify={"space-around"}>
                                            <Col xs={{ span: 24, order: 1 }} md={{ span: 6, order: 1 }} lg={{ span: 6, order: 1 }}>
                                                <Text>{item.nombre}</Text>
                                            </Col>
                                            <Col xs={{ span: 24, order: 2 }} md={{ span: 7, order: 2 }} lg={{ span: 7, order: 2 }}>
                                                <Text ellipsis >{item.descripcion}</Text>
                                            </Col>
                                            <Col xs={{ span: 16, order: 4 }} md={{ span: 6, order: 3 }} lg={{ span: 6, order: 3 }} >
                                                <Text>{item.categoria_id?.nombre}</Text>
                                            </Col>
                                            <Col xs={{ span: 8, order: 5 }} md={{ span: 2, order: 4 }} lg={{ span: 2, order: 4 }} >
                                                <Switch
                                                    disabled={item.tipo === 3 || item.tipo === 4}
                                                    defaultChecked={item.activo}
                                                    onChange={(value) => this.toggleActivo(item._id, value)}
                                                />
                                            </Col>
                                            <Col xs={{ span: 24, order: 6 }} md={{ span: 3, order: 5 }} lg={{ span: 3, order: 5 }} >
                                                <Space>
                                                    <Tooltip title={`This service is ${item.square_item_id ? "" : "not"} registered on square`}>
                                                        <Badge count={item.square_item_id ? <CheckCircleOutlined color='#31A078' /> : <CloseCircleOutlined color='red' />}>
                                                            <Button type='primary'
                                                                disabled={true}
                                                                icon={<SquareLogo size="20px" />}
                                                            />
                                                        </Badge>
                                                    </Tooltip>
                                                    <ButtonEdit
                                                        title="Edit"
                                                        onClick={() => { this.setState({ modalVisible: true, servicio_id: item._id }) }}
                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="Do you want to delete this Service?"
                                                        onConfirm={() => axios.delete('/servicios/', {
                                                            params: {
                                                                id: item._id
                                                            }
                                                        }).then(() => {
                                                            this.get()
                                                            message.success('Service deleted!')
                                                        }).catch(error => message.error(getResponseError(error.response, "Service can't deleted!")))}
                                                        okText="Yes"
                                                        cancelText="Cancel"
                                                    >
                                                        <ButtonDelete
                                                            title="Delete"
                                                        />
                                                    </Popconfirm>


                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />


                    </Content>

                    <FloatingButton title="New register" onClick={() => this.setState({ modalVisible: true })} />


                </Spin>
                <ModalServicios
                    visible={this.state.modalVisible}
                    onClose={() => {
                        this.setState({ modalVisible: false, servicio_id: undefined })
                        this.get()
                    }}
                    servicio_id={this.state.servicio_id}
                />
            </>
        )

    }
}

export default function Vista(props) {

    const user = useContext(User);
    const { search, setShow } = useSearch()

    const permisos = usePermissions(user?.rol_id?.permisos, {
        editarServicios: ["servicios", "edit"],
        deleteServicios: ["servicios", "delete"],
        createServicios: ["servicios", "create"]

    });

    return <Servicios {...props} search={search} setShowSearch={setShow} navigate={useNavigate()} {...permisos} />

}