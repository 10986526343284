import React from 'react';
import { Card, Space, Typography, Button } from 'antd';
import { EyeOutlined } from "@ant-design/icons";
import { SiHotjar } from "react-icons/si";

import { renderImage } from '../Utils';
const { Text, Paragraph } = Typography;



function ProductCard({ producto = {}, onClick = () => { } }) {
    return (<Card className='card-productos'
        title={<Text ellipsis strong className='card-productos-title'>{producto.nombre}</Text>}
        extra={producto.hot ? <><SiHotjar /></> : null}
        cover={<img className='img-cover'
            alt={producto.nombre}
            src={renderImage(producto._id, producto.imagenes, 'product')}
        />}
        hoverable
        onClick={onClick}

    >
        <Paragraph ellipsis={{ rows: 3, expandable: false }} >
            {producto.descripcion}
        </Paragraph>
        <Space direction='horizontal' className='ant-row ant-row-space-between width-100' classNames={{ item: 'ant-col-12' }}>
            <Text type="secondary" strong className='amount'>{producto.variante?.precio ? <> ${producto.variante.precio} {<small>USD</small>}</> : null}  </Text>

        </Space>

    </Card>
    )
};

export default ProductCard;