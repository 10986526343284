
import React, { Component } from 'react'
import { Form, Input, Divider, Col, Row, Radio, Checkbox, Typography, Space, Spin } from 'antd';
import Busqueda from "../../Widgets/Busqueda";
import User from '../../../Hooks/Logged'


import axios from 'axios'

const { Title, Text, Paragraph } = Typography;


export default class StepDireccion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            address: {},
            loading: false,
            direcciones: {
                data: []
            },
            disabled: false,
            new_address: false,
        }
    }

    formRef = React.createRef();
    directionsRef = React.createRef();

    static contextType = User;

    componentDidMount() {

        if (Object.keys(this.props.address).length > 0) {
            //this.formRef.current.submit()
            if (this.props.address?.direccion_id || this.props.address?.entrega_local || this.props.address.pick_and_deliver) {
                this.setState({ disabled: true })
            }
            this.props.setDisabled(false);
        } else {
            this.props.setDisabled(true);
        }

        const user = this.context;
        if (user.cliente_id)
            this.getDirecciones()

        this.getZipCodes()

    }
    onValuesChange = (changedValues, allValues) => {
        this.setState({ address: allValues })
        this.props.updateQuotationData('address', allValues)
    }
    updateAddress = (address) => {
        this.setState(state => {
            state.address.localization = address
            return state
        })
    }
    handleFieldsChange = (_, allFields) => {
        // Verificar si todos los campos requeridos están llenos
        const isFilled = allFields?.every((field) => field.errors.length == 0);
        console.log("isFilled", isFilled);
        this.props.setDisabled(!isFilled);
    };

    /**
     * @memberof StepDireccion
     * @method getDirecciones
     * @description Obtiene las direcciones del cliente
     */
    getDirecciones = () => {
        const user = this.context;
        this.setState({ loading: true })
        axios.get('/address', {
            params: {
                cliente_id: user.cliente_id
            }
        })
            .then(({ data }) => {
                this.setState({ direcciones: { ...data } })
            })
            .catch(res => {
                console.log("res", res);
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof StepDireccion
     * @method getZipCodes
     * @description Obtiene las direcciones del cliente
     */
    getZipCodes = () => {
        axios.get('/zip-codes')
            .then(({ data }) => {
                console.log("data", data);
                data = data.two_pair

                let zip_code_PUD = false
                if (this.props.address?.codigo_postal) {
                    let zip_code = data.find(e => e === this.props.address?.codigo_postal)
                    if (zip_code) zip_code_PUD = true
                }

                this.setState({
                    zipCodes: data,
                    zip_code_PUD
                })
            })
            .catch(res => {
                console.log("res", res);
            })
    }


    /**
     *
     *
     * @memberof StepDireccion
     * @method selectAddress
     * @description Al seleccionar una direccion previa o nueva se actualiza el props y el formulario
     */
    selectAddress = ({ target }) => {

        if (target.value != null) {
            let address = this.state.direcciones.data.find(d => d._id == target.value)
            this.onValuesChange({}, address)
            this.props.setDisabled(false);
            //buscamos si la direccion esta en un codigo postal que permite pick up & delivery
            let zip_code = this.state.zipCodes.find(e => e === address.codigo_postal)
            this.setState({ disabled: true, zip_code_PUD: zip_code })
            this.formRef.current.setFieldsValue({ ...address, entrega_local: false, pick_and_deliver: false })

        }
        else {
            this.onValuesChange({}, {})
            this.cleanForm()
            this.setState({ disabled: false, zip_code_PUD: undefined })
            this.props.setDisabled(true);

        }

    }


    onChangePickup = ({ target }) => {
        this.setState({ disabled: target.checked })
        this.props.setDisabled(!target.checked);
        console.log("target.checked", target.checked);
        if (target.checked === true) {
            this.formRef.current.setFieldsValue({
                direccion_id: undefined,
                pick_and_deliver: false,
                nombre: undefined,
                codigo_postal: undefined,
                localizacion: undefined,
                notas: undefined,
                especificaciones: undefined,
            })
            this.setState({ zip_code_PUD: undefined })
        }

    }

    onChangeDeliver = ({ target }) => {
        if (target.checked === true) {
            this.formRef.current.setFieldsValue({ entrega_local: false })
        }

    }

    /**
     *
     *
     * @memberof StepDireccion
     * @method cleanForm
     * @description Limpia el formulario para ingresar una nueva direccion
     */
    cleanForm = () => {
        const emptyAddress = {
            nombre: '',
            localizacion: undefined,
            codigo_postal: '',
            notas: '',
            especificaciones: '',
            entrega_local: false,
            pick_and_deliver: false,
        };
        this.formRef.current.setFieldsValue(emptyAddress);

        this.setState({ new_address: true })
    }

    renderText = (codigo_postal) => {
        let zip_code = this.state.zipCodes?.find((e) => e === codigo_postal)
        let amount = "$12.00"
        if (zip_code) return "Pick Up & Delivery available for " + amount
    }

    render() {
        return (
            <>

                <Form
                    layout={"vertical"}
                    ref={this.formRef}
                    className='mt-1'
                    initialValues={{
                        ...this.props.address,

                    }}
                    onValuesChange={this.onValuesChange}
                    onFieldsChange={this.handleFieldsChange}
                    validateTrigger="onChange"
                >
                    <Row justify="center">
                        <Col span={24}>
                            <Title level={5}>Choose address</Title>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                className="m-0"
                                name="direccion_id"
                            >
                                <Radio.Group className='width-100' onChange={this.selectAddress} >
                                    {this.state.direcciones.data?.map(item => {
                                        return <Col span={24} className='width-100 radio-group-address'>
                                            <Radio value={item._id} rootClassName='radio-card-item width-100'>
                                                <div className="flex-left-column">
                                                    <Text ellipsis className="text-left">{item.localizacion?.place_name}</Text>
                                                    <div className="width-100 flex-between">
                                                        <Text strong className="text-left">Zip Code: {item.codigo_postal}</Text>
                                                        <Text strong underline italic className="text-right"> {this.renderText(item.codigo_postal)}  </Text>
                                                    </div>
                                                </div>
                                            </Radio>
                                        </Col>
                                    })}
                                    <Col span={24} className='width-100'>
                                        <Radio value={null} rootClassName='radio-card-item width-100'>+ Add new Address</Radio>
                                    </Col>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={22}  className="flex-left">
                            <Form.Item
                                name="entrega_local"
                                valuePropName='checked'
                                className="m-0"
                            >
                                <Checkbox
                                    onChange={this.onChangePickup}> <Text strong>Store Pickup</Text>
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        {this.state.zip_code_PUD ? <Col span={22}  className="flex-left">
                            <Form.Item
                                name="pick_and_deliver"
                                valuePropName='checked'
                                className="m-0"
                            >
                                <Checkbox onChange={this.onChangeDeliver}>
                                    <Text>Use the Pick Up & Delivery service</Text>
                                </Checkbox>
                            </Form.Item>
                        </Col> : null}
                    </Row>
                    <Divider />
                    <Row gutter={[12, 0]}>
                        <Col span={12} md={22} xs={24}>
                            <Form.Item
                                label="Name"
                                name={'nombre'}
                                rules={[{ required: true, message: 'name is required' }]}
                            >
                                <Input disabled={this.state.disabled} placeholder="Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12} md={22} xs={24}>
                            <Form.Item
                                label='Zip Code'
                                name='codigo_postal'
                                rules={[{ required: true, message: 'Postal Code is required' }]}
                            >
                                <Input disabled={this.state.disabled} placeholder="Postal Code" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        label="Address"
                        name='localizacion'
                    >
                        <Busqueda
                            placeholder="Please write a address."
                            enterButton={true}
                            className="width-100"
                            inputClassName="width-100"
                            address={this.state.address}
                            onSelect={address => this.updateAddress(address)}
                            disabled={this.state.disabled}
                        />
                    </Form.Item>
                    <Form.Item
                        label='Notes'
                        name='notas'
                    >
                        <Input.TextArea disabled={this.state.disabled} placeholder="Notes" />
                    </Form.Item>
                    <Form.Item
                        label='Specifications'
                        name='especificaciones'
                    >
                        <Input.TextArea disabled={this.state.disabled} placeholder="Specifications" />
                    </Form.Item>
                </Form>
            </>
        )
    }
}