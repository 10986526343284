import React, { Component } from 'react';
import { Row, Col, Typography, Button, Modal, Layout, Space, message, List, Card, Select, } from 'antd'
import { Link, useNavigate } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';

//componentes
import User from '../../../../../Hooks/Logged'
import useSearch from '../../../../../Hooks/useSearch';
import usePermissions from '../../../../../Hooks/usePermissions';
import { ButtonEdit, ButtonView } from '../../../../Widgets/Buttons';
import { getResponseError, renderEstatusVenta } from '../../../../Utils';

//modales
import ModalVentas from '../../../Ventas/ModalVentas'

import ModalVentasDetalles from "../../../Ventas/ModalVentasDetalles";

//css
import '../../../../../Styles/Modules/Admin/clientes.scss'


const { Content } = Layout;
const { Text, Title } = Typography
const { Option } = Select
const { confirm } = Modal


/**
 *
 *
 * @export
 * @class Clientes
 * @extends {Component}
 * @description Vista donde se listan todos los Clientes
 */
class ClientesPagos extends Component {



    /**
     *Creates an instance of Clientes.
     * @param {*} props
     * @memberof Clientes
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            ventas: {
                data: [],
                page: 1,
                limit: 10,
                filters: [],
                total: 0,
                sort: {
                    createdAt: -1
                },
            },
        }
    }

    drawerFiltros = React.createRef()

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getVentas()
    }

    /**
    * @memberof Categorias
    * @method getCategorias
    * @description Trae las ventas 
    * 
    */
    getVentas = ({
        page = this.state.ventas.page,
        limit = this.state.ventas.limit,

        search = this.props.search

    } = this.state.ventas) => {
        this.setState({ loading: true })
        axios.get('/ventas', {
            params: {
                page,
                limit,
                search,
                cliente_id: this.props.cliente_id
            }
        }).then(({ data }) => {
            console.log("data", data);
            this.setState({ ventas: data })
        }).catch(error => {
            message.error(error.response?.data?.message)
        }).finally(() => this.setState({ loading: false }))
    }



    render() {
        const { ventas, loading } = this.state
        return (
            <>
                <Row gutter={[24, 24]}>
                    <Col xs={24} className="">
                        <List
                            loading={this.state.loading}
                            className="component-list responsive"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sales not found" }}
                            dataSource={ventas.data}
                            pagination={{
                                current: ventas.page,
                                pageSize: ventas.limit,
                                total: ventas.total,
                                pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                                position: 'bottom',
                                className: "flex-left ml-1",
                                onChange: (page, limit) => this.getClientes({ page, limit })
                            }}
                            header={
                                <Row align="middle">

                                    <Col flex="auto">
                                        <Row className="width-100 " align="middle" gutter={[0, 12]}>
                                            <Col xs={5} style={{ color: "gray" }} >
                                                <Text ellipsis strong>Date</Text>
                                            </Col>
                                            <Col xs={5}  >
                                                <Text ellipsis strong>Items</Text>
                                            </Col>
                                            <Col xs={5}  >
                                                <Text ellipsis strong>Estatus</Text>
                                            </Col>
                                            <Col xs={5}  >
                                                <Text ellipsis strong>Price</Text>
                                            </Col>
                                            <Col xs={3}  >
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row align="middle" justify={"space-around"}>
                                            <Col flex="auto">
                                                <Row className="width-100 " align="middle" gutter={[0, 12]}>
                                                    <Col xs={{ span: 18, order: 1 }} md={{ span: 5, order: 1 }} >
                                                        <Text strong ellipsis>{moment(item.createdAt).format("MM/DD/YYYY HH:mm")}</Text>
                                                    </Col>
                                                    <Col xs={{ span: 12, order: 4 }} md={{ span: 5, order: 2 }} >
                                                        <Text ellipsis>{item.detalles?.length ?? 0} Items</Text>
                                                    </Col>
                                                    <Col xs={{ span: 24, order: 3 }} md={{ span: 5, order: 3 }} >
                                                        <Text ellipsis>{renderEstatusVenta(item.status)}</Text>
                                                    </Col>
                                                    <Col xs={{ span: 12, order: 5 }} md={{ span: 5, order: 4 }} >
                                                        <Text ellipsis>$ {item.total?.toMoney(true) ?? 0}</Text>
                                                    </Col>
                                                    <Col xs={{ span: 6, order: 2 }} md={{ span: 4, order: 5 }}  >
                                                        <Space>
                                                            <ButtonView
                                                                onClick={() => this.setState({ modal_visible_detalle: true, venta_id: item._id })}
                                                            />
                                                            <ButtonEdit
                                                                onClick={() => this.setState({ modal_visible: true, venta_id: item._id })}
                                                            />
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
                <ModalVentas
                    visible={this.state.modal_visible}
                    onClose={(flag) => {
                        this.setState({ modal_visible: false, venta_id: undefined })
                        if (flag) {
                            this.getVentas()
                        }
                    }}
                    venta_id={this.state.venta_id}
                />

                <ModalVentasDetalles
                    visible={this.state.modal_visible_detalle}
                    onClose={() => {
                        this.setState({ modal_visible_detalle: false, venta_id: undefined })
                    }}
                    venta_id={this.state.venta_id}
                />
            </>
        )
    }
}



export default function (props) {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canCreate: ["clientes", "create"],
        canEdit: ["clientes", "edit"],
        canDelete: ["clientes", "delete"],
        canViewDetails: ["clientes", "cliente-detalles", "access"],
        canAssignAsesor: ["clientes", "assign-asesor"]
    })

    const navigate = useNavigate();


    return <ClientesPagos {...props}
        {...permisos}
        setShowSearch={setShow}
        navigate={navigate}
    />
}       