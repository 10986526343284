import React, { Component } from 'react';
import { Row, Col, Typography, Spin, Layout, Space, message, Card, List, Badge, Button, Tooltip } from 'antd'
import { CopyOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import SquareLogo from '../../Widgets/Icons/Square';
import Logged, { User } from "../../../Hooks/Logged";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import useSearch from '../../../Hooks/useSearch';

import ModalProductos from './ModalProductos'
import { ButtonDelete, ButtonEdit, ButtonView } from '../../Widgets/Buttons';
import usePermissions from '../../../Hooks/usePermissions';
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import ModalCSV from './ModalCSV';


const { Content } = Layout;
const { Text } = Typography

/**
 *
 *
 * @export
 * @class Productos
 * @extends {Component}
 * @description Vista donde se listan todos los productos
 */
class Productos extends Component {
    static contextType = Logged;

    back = () => {
        this.props.history.goBack();
    }


    /**
     *Creates an instance of Productos.
     * @param {*} props
     * @memberof Productos
     */
    constructor(props) {
        super(props)
        this.state = {

            loading: false,
            productos: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
                filters: []
            },
            producto_id: null
        }
    }

    componentDidMount() {
        this.props.setShowSearch(true)

        this.getProductos()
    }

    componentDidUpdate(prevProps) {
        if (this.props.search != prevProps.search) {
            this.getProductos()
        }
    }

    /**
    *
    * @memberof Productos
    * @method getProductos
    * @description Obtiene la lista de productos
    */
    getProductos = async ({
        page = this.state.productos.page,
        limit = this.state.productos.limit,
        search = this.props.search,
    } = this.state.productos) => {

        this.setState({ loading: true })
        axios.get('/productos', {
            params: {
                limit,
                page,
                search
            }
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({ productos: data })
            })
            .catch(res => {
                console.log("res", res);
                message.error(res.response?.data?.message ?? "Error")
            })
            .finally(() => this.setState({ loading: false }))
    }




    render() {

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="admin-page-header"
                        title="Products"
                    >

                    </PageHeader>

                    <Content className="admin-content content-bg pd-1">
                        <List
                            className="component-list responsive"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Products not found" }}
                            dataSource={this.state.productos.data}
                            pagination={{
                                current: this.state.productos.page,
                                pageSize: this.state.productos.limit,
                                total: this.state.productos.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.getProductos({ page, limit })

                            }}
                            header={<Row className="component-list-header width-100 pr-1 pl-1" >
                                <Col xs={12} md={5} span={5}  className="center">
                                    <Text strong>Name</Text>
                                </Col>
                                <Col xs={12} md={5} span={5}  className="center">
                                    <Text strong>Model</Text>
                                </Col>
                                <Col xs={12} md={5} span={5}  className="center">
                                    <Text strong>Brand</Text>
                                </Col>
                                <Col xs={12} md={5} span={5}  className="center">
                                    <Text strong>SKU</Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100" gutter={[8, 8]}>
                                            <Col xs={12} md={5} className="center">
                                                <Text ellipsis>{item.nombre}</Text>
                                            </Col>
                                            <Col xs={12} md={5} className="center">
                                                <Text ellipsis>{item.modelo}</Text>
                                            </Col>
                                            <Col xs={12} md={5} className="center">
                                                <Text ellipsis>{item.marca}</Text>
                                            </Col>
                                            <Col xs={12} md={5} className="center">
                                                <Text ellipsis>{item.sku}</Text>
                                            </Col>
                                            <Col xs={24} md={4} className="center">
                                                <Space>
                                                    <Tooltip title={`This product is ${item.square_item_id ? "" : "not"} registered on square`}>
                                                        <Badge count={item.square_item_id ? <CheckCircleOutlined color='#31A078' /> : <CloseCircleOutlined color='red' />}>
                                                            <Button type='primary'
                                                                disabled={true}
                                                                icon={<SquareLogo size="20px" />}
                                                            />
                                                        </Badge>
                                                    </Tooltip>
                                                    <ButtonView
                                                        text="Variants"
                                                        onClick={() => this.props.navigate('/admin/productos/variantes/' + item._id)}
                                                    />
                                                    <ButtonEdit
                                                        disabled={!this.props.canEdit}
                                                        onClick={() => this.setState({ modalProductos: true, producto_id: item._id })}
                                                    />
                                                    <ButtonDelete
                                                        disabled={!this.props.canDelete}
                                                        onConfirm={() => axios.delete('/productos/', { params: { id: item._id } }).then((response) => {
                                                            message.success(response?.data?.message)
                                                            this.getProductos()
                                                        })
                                                            .catch((error) => {
                                                                message.error(error?.response?.data?.message);
                                                                this.getProductos();
                                                            })
                                                        } />

                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Content>

                    {this.props.canCreate && <>
                        <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalProductos: true })} />
                        <FloatingButton
                            buttonStyle={{
                                width: "40px",
                                height: "40px",
                                bottom: "42px",
                                right: "125px",
                            }}
                            icon={<CopyOutlined style={{ color: "white", fontSize: "20px", left: -6, top: 2, position: "relative" }} />}
                            title="Crear productos por CSV"
                            onClick={() => this.setState({ modalCSV: true })}

                        />
                    </>}


                    <ModalProductos
                        visible={this.state.modalProductos}
                        producto_id={this.state.producto_id}
                        onClose={() => {
                            this.setState({ modalProductos: false, producto_id: undefined })
                            this.getProductos()
                        }}
                    />

                    <ModalCSV
                        visible={this.state.modalCSV}
                        onClose={() => {
                            this.setState({
                                modalCSV: false,
                            })
                        }}

                        onSuccess={(closeModal) => {
                            this.getProductos();

                            if (closeModal)
                                this.setState({ modalCSV: false, })
                        }}
                    />
                </Spin>
            </>
        )
    }
}

export default function (props) {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canEdit: ['productos', 'edit'],
        canDelete: ['productos', 'delete'],
        canCreate: ['productos', 'create']
    })

    return <Productos {...props} setShowSearch={setShow} {...permisos} navigate={useNavigate()} />
}