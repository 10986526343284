import React from 'react'
import { Route, Routes } from "react-router-dom";
import Transacciones from '../../Components/Customer/Transacciones/Transacciones'
import VentaDetalle from '../../Components/Customer/Transacciones/VentaDetalle';

/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterTransacciones(props) {

    

    return (
        <Routes>
            <Route path=""      element={<Transacciones {...props} />} />
            <Route path="details/:venta_id" element={<VentaDetalle {...props} />} />
        </Routes>
    )
}

export default RouterTransacciones;
