import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Layout, message, Space, List, Typography } from 'antd'

import axios from 'axios';
import { useNavigate } from "react-router-dom";


import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import ModalRoles from './ModalRoles'


import Logged from '../../../Hooks/Logged'
import useSearch from '../../../Hooks/useSearch';
import usePermissions from '../../../Hooks/usePermissions';
import PageHeader from '../../Widgets/PageHeader/PageHeader';

const { Content } = Layout;
const { Text } = Typography;

/**
 * @export
 * @class Roles
 * @extends {Component}
 * @description Vista de Roles
 */
export class Roles extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modal_visible: false,

            rol_id: undefined,
            roles: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,
            },
            searching: true,
            filtroSearch: '',

        }
    }

    componentDidMount() {
        this.props.setShowSearch(true)

        this.getRoles()
    }


    /**
     *
     *
     * @memberof Roles
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate(prevProps) {
        if (this.props.search != prevProps.search) {
            this.getRoles()
        }
    }

    /**
     * @methodOf  Roles
     * @method getRoles
     *
     * @description Obtiene las Roles
     * */
    getRoles = ({
        page = this.state.roles.page,
        limit = this.state.roles.limit,
        search = this.props.search
    } = this.state.roles) => {
        this.setState({ loading: true, roles: { ...this.state.roles, page, limit } })
        axios.get('/roles', {
            params: {
                page,
                limit,
                search
            }
        }).then(({ data }) => {

            this.setState({
                roles: { ...this.state.roles, ...data },
            });

        }).catch(error => {
            console.log(error)
            message.error('An error occurred while loading the information')
        }).finally(() => this.setState({ loading: false }))
    }

    render() {
        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title="Roles"
                />
                <Content className="admin-content content-bg pd-1">
                    <List
                        loading={this.state.loading}
                        className="component-list responsive"
                        itemLayout="horizontal"
                        locale={{ emptyText: "Roles not found" }}
                        dataSource={this.state.roles.data}
                        pagination={{
                            current: this.state.roles.page,
                            pageSize: this.state.roles.limit,
                            total: this.state.roles.total,
                            position: 'bottom',
                            className: "flex-left",
                            onChange: (page, limit) => this.getRoles({ page, limit })
                        }}
                        header={<Row className="component-list-header width-100 pr-1 pl-1">
                            <Col xs={24} lg={10} className="center">
                                <Text strong>Name</Text>
                            </Col>
                            <Col xs={24} lg={10} className="center">
                                <Text strong>Description</Text>
                            </Col>
                        </Row>}

                        renderItem={item => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row className="width-100 " gutter={[8, 8]}>

                                        <Col xs={24} lg={10} className="center">
                                            <Text>{item.nombre}</Text>
                                        </Col>
                                        <Col xs={24} lg={10} className="center">
                                            <Text ellipsis>{item.descripcion}</Text>
                                        </Col>
                                        <Col xs={24} lg={4} className="center">
                                            <Space size="small" direction="horizontal">
                                                <Button
                                                    type="primary"
                                                    disabled={!this.props.canEdit}
                                                    icon={<EditOutlined />}
                                                    title="Edit"
                                                    onClick={() => this.setState({ modal_visible: true, rol_id: item._id })}
                                                />
                                                <Popconfirm
                                                    placement="topRight"
                                                    //disabled={!item.editable || !this.props.deleteRoles}
                                                    title="Do you want to delete this role?"
                                                    onConfirm={() => axios.delete('/roles',
                                                        { params: { rol_id: item._id } }).then(() => {
                                                            this.getRoles()
                                                            message.success('Rol deleted')
                                                        }).catch(error => {
                                                            if (error.response.status === 403) {
                                                                message.error(error.response.data.message)
                                                            } else {
                                                                message.error('An error occurred while deleting the role')
                                                            }
                                                        })}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button
                                                        disabled={!this.props.canDelete}
                                                        type="primary"
                                                        icon={<DeleteOutlined />}
                                                        title="Delete"
                                                        danger
                                                    />
                                                </Popconfirm>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

                </Content>

                {this.props.canCreate && <FloatingButton title="New Register" onClick={() => this.setState({ modal_visible: true })} />}

                <ModalRoles
                    visible={this.state.modal_visible}
                    onClose={() => {
                        this.setState({ modal_visible: false, rol_id: undefined })
                        this.getRoles()
                    }}
                    rol_id={this.state.rol_id}
                />
            </>
        )
    }
}

export default function (props) {
    const { setShow } = useSearch()

    let user = React.useContext(Logged)

    let permisos = usePermissions(user?.rol_id?.permisos, {
        canEdit: ["roles", "edit"],
        canCreate: ["roles", "create"],
        canDelete: ["roles", "delete"]
    })


    return <Roles {...props} setShowSearch={setShow} navigate={useNavigate()}  {...permisos} />

}