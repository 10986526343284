import React, { Component, createRef } from 'react';
import { Row, Col, Typography, Spin, Layout, Space, message, Card, List, Tag, Radio, Tooltip } from 'antd'
import { useNavigate } from "react-router-dom";
import { IoGridOutline, IoList } from "react-icons/io5";
import Logged, { User } from "../../../Hooks/Logged";
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import QuotationsSection from './QuotationsSection';

import '../../../Styles/Modules/Admin/quotations.scss'
import QuotationsList from './QuotationsList';

const { Content } = Layout;




/**
 *
 *
 * @export
 * @class Quotations
 * @extends {Component}
 * @description Vista donde se listan todos los quotations
 */
class Quotations extends Component {
    static contextType = Logged;

    /**
     *Creates an instance of Quotations.
     * @param {*} props
     * @memberof Quotations
     */
    constructor(props) {
        super(props)
        this.state = {
            view: 'list',
            loading: false
        }
    }

    sectionRefs = [
        createRef(),
        createRef(),
        createRef(),
        createRef()
    ]

    componentDidUpdate(prevProps, prevState) {
        if (prevState.view === 'list' && this.state.view === 'board') {
            window.addEventListener('scrollend', this.loadMore)
            for (const ref of this.sectionRefs) {
                ref.current?.get({ page: 1 })
            }
        }

        if (prevState.view === 'board' && this.state.view === 'list') {
            window.removeEventListener('scrollend', this.loadMore)
        }
    }

    componentWillUnmount() {
        if (this.state.view === 'board') {
            window.removeEventListener('scrollend', this.loadMore)
        }
    }

    loadMore = () => {
        if (Math.floor(window.scrollY + window.innerHeight) > Math.floor(document.body?.clientHeight - 50)) {
            for (const ref of this.sectionRefs) ref.current?.getNext()
        }
    }

    render() {

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="admin-page-header"
                        title="Quotations"
                    >

                    </PageHeader>
                    <Content className="quotations-content content-bg pd-1">
                        <QuotationsList />
                    </Content>
                </Spin>
            </>
        )
    }
}

export default function (props) {

    const user = React.useContext(User)

    return <Quotations {...props} navigate={useNavigate()} />
}