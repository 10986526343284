import React from 'react'
import { Route, Routes } from "react-router-dom";

import Quotations from '../../Components/Admin/Quotations/Quotations';
import Quotation from '../../Components/Admin/Quotations/Quotation';

/**
 * 
 * @export
 * @function RouterCategorias
 * @description Router for Categorias routes 
 */
function RouterQuotations(props) {
  return (
    <Routes>
      <Route path="" element={<Quotations {...props} />} />
      <Route path=":id" element={<Quotation {...props} />} />
    </Routes>
  )
}

export default RouterQuotations