import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Modal, Spin, Layout, Space, message, Card, List, Tag } from 'antd'
import { MailOutlined } from '@ant-design/icons';
import axios from 'axios';

import Logged, { User } from "../../../Hooks/Logged";
import useSearch from '../../../Hooks/useSearch';

import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

import ModalUsuarios from './ModalUsuarios'
import { ButtonDelete, ButtonEdit } from '../../Widgets/Buttons';
import usePermissions from '../../../Hooks/usePermissions';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import PageHeader from '../../Widgets/PageHeader/PageHeader';


const { Content } = Layout;
const { Text } = Typography

/**
 *
 *
 * @export
 * @class Usuarios
 * @extends {Component}
 * @description Vista donde se listan todos los usuarios
 */
class Usuarios extends Component {
    static contextType = Logged;

    back = () => {
        this.props.history.goBack();
    }


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {

            loading: false,
            usuarios: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
                filters: []
            },
            usuario_id: null
        }
    }

    componentDidMount() {
        this.props.setShowSearch(true)
        this.getUsuarios()
    }

    componentDidUpdate(prevProps) {
        if (this.props.search != prevProps.search) {
            this.getUsuarios()
        }
    }

    /**
     * @methodOf  Usuarios
     * @method sendEmail
     *
     * @description Envia un correo para recuperar la contraseña
     *
     * */
    sendEmail = (email) => {
        this.setState({ loading: true })
        axios.put("/password/recovery", { email: email })
            .then(res => {
                Modal.success({
                    title: 'Restore Password.',
                    content: 'the email has been sent!'
                })
            })
            .catch(res => {
                console.log(res)
                Modal.warning({
                    title: 'Error',
                    content: 'Email can\'t be send. Check that the email is correct'
                });
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })

    }

    /**
    *
    * @memberof RazonesSociales
    * @method getRS
    * @description Obtiene la informacion de los Empresas y actualiza los valores locales de la lista.
    */
    getUsuarios = async ({
        page = this.state.usuarios.page,
        limit = this.state.usuarios.limit,
        search = this.props.search,
    } = this.state.usuarios) => {

        this.setState({ loading: true })
        axios.get('/usuarios', {
            params: {
                limit,
                page,
                search
            }
        })
            .then(({ data }) => {
                this.setState({ usuarios: data })
            })
            .catch(res => {
                message.error(res.response.data.message)
            })
            .finally(() => this.setState({ loading: false }))
    }




    render() {

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="admin-page-header"
                        title="Users"
                    >

                    </PageHeader>

                    <Content className="admin-content content-bg pd-1">
                        <List
                            className="component-list responsive"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Users not found" }}
                            dataSource={this.state.usuarios.data}
                            pagination={{
                                current: this.state.usuarios.page,
                                pageSize: this.state.usuarios.limit,
                                total: this.state.usuarios.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.getUsuarios({ page, limit })

                            }}
                            header={<Row className="component-list-header width-100 pr-1 pl-1" >
                                <Col lg={2} md={1} xs={1} className="center">
                                </Col>
                                <Col lg={7} md={8} xs={12} className="center">
                                    <Text strong>Name</Text>
                                </Col>
                                <Col lg={6} md={6} xs={12} className="center">
                                    <Text strong>Email</Text>
                                </Col>
                                <Col lg={5} md={5} xs={12} className="center">
                                    <Text strong>Rol</Text>
                                </Col>
                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100" gutter={[8, 8]}>
                                            <Col xs={{ span: 8, order: 1 }} lg={{ span: 2, order: 1 }} md={{ span: 2, order: 1 }} className="center">
                                                <CustomAvatar
                                                    name={item.nombre}
                                                    url={item.avatar ? {
                                                        url: axios.defaults.baseURL + '/usuarios/' + item._id,
                                                        avatar: item.avatar?.name,
                                                        logo: item.avatar.file
                                                    } : null}
                                                />
                                            </Col>
                                            <Col xs={{ span: 16, order: 2 }} lg={{ span: 7, order: 2 }} md={{ span: 7, order: 2 }} className="center">
                                                <Text strong ellipsis>{item.nombre} {item.apellidos}</Text>
                                            </Col>
                                            <Col xs={{ span: 12, order: 3 }} lg={{ span: 6, order: 3 }} md={{ span: 6, order: 3 }} className="center">
                                                <Text className="text-gray">{item.email}</Text>
                                            </Col>
                                            <Col xs={{ span: 6, order: 5 }} lg={{ span: 5, order: 4 }} md={{ span: 5, order: 4 }} className="center">
                                                <Tag color={item.rol_id ? "#FF5500" : "#00a859"}>{item.rol_id ? item.rol_id?.nombre : "Client"}</Tag>
                                            </Col>
                                            <Col xs={{ span: 12, order: 4 }} lg={{ span: 3, order: 5 }} md={{ span: 3, order: 5 }} className="flex-right">
                                                <Space>
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="You want to send a password recovery email?" okText="Yes" cancelText="No"
                                                        onConfirm={() => this.sendEmail(item.email)}
                                                    >
                                                        <Button
                                                            disabled={!this.props.canRecovery}
                                                            type="primary"
                                                            icon={<MailOutlined />}
                                                            title="Password Recovery"
                                                        />
                                                    </Popconfirm>
                                                    <ButtonEdit disabled={!this.props.canEdit} onClick={() => this.setState({ modalUsuarios: true, usuario_id: item._id })} />
                                                    <ButtonDelete disabled={!this.props.canDelete} onConfirm={() => axios.delete('/usuarios/', { params: { usuario_id: item._id } }).then((response) => {
                                                        message.success(response?.data?.message)
                                                        this.getUsuarios()
                                                    })
                                                        .catch((error) => {
                                                            message.error(error?.response?.data?.message);
                                                            this.getUsuarios();
                                                        })
                                                    } />

                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Content>

                    {this.props.canCreate && <FloatingButton title="New register" onClick={() => this.setState({ modalUsuarios: true })} />}

                    <ModalUsuarios
                        visible={this.state.modalUsuarios}
                        usuario_id={this.state.usuario_id}
                        onClose={() => {
                            this.setState({ modalUsuarios: false, usuario_id: undefined })
                            this.getUsuarios()
                        }}
                    />
                </Spin>
            </>
        )
    }
}

export default function (props) {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canEdit: ['usuarios', 'edit'],
        canDelete: ['usuarios', 'delete'],
        canCreate: ['usuarios', 'create'],
        canRecovery: ['usuarios', 'recovery'],
    })

    return <Usuarios {...props} setShowSearch={setShow}  {...permisos} />
}