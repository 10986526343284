import React from 'react'
import { Route, Routes } from "react-router-dom";

import Analytics from '../../Components/Admin/Analytics/Analytics';

/**
 * 
 * @export
 * @function RouterAnalytics
 * @description Router for Analytics routes 
 */
function RouterAnalytics(props) {
  return (
    <Routes>
      <Route path="" element={<Analytics {...props} />} />
    </Routes>
  )
}

export default RouterAnalytics