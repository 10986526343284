import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, ColorPicker, Select, message, Upload, Checkbox, Spin, InputNumber } from 'antd';
import { PlusOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';

import axios from "axios"
import SelectSizes from "../../Widgets/Inputs/SelectSizes";
import SelectServicio from "../../Widgets/Inputs/SelectServicios";

const { Title } = Typography;
const { Option } = Select;


class ModalCuentas extends Component {


    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            tipo: 1,
            disablePrice: false
        }
    }

    refModalCompras = React.createRef();

    componentDidMount() {


    }

    /**
    * @memberof Transacciones
    * @function onFinish
    * @description Añade un nuevo producto a la orden de compra
    */
    onFinish = (values) => {
        if (this.props.venta_id) {
            this.addProducto(values)
        }
    }

    /**
   * @memberof Transacciones
   * @function addProducto
   * @description Añade un nuevo producto a la orden de compra
   */
    addProducto = (values) => {
        this.setState({ loading: true })
        axios.post('/carrito', {
            from_admin:true,
            venta_id: this.props.venta_id,
            detalles: {
                servicio_id: values?.servicio_id,
                precio: values.precio,
                cantidad: 1,
            }
        }).then(response => {
            message.success("Item added")
            this.props.onClose(true)
        }).catch(error => {
            message.error(error?.response?.data?.message ?? "There was an error adding the item")
        }).finally(() => this.setState({ loading: false }))
    }

    onSelectServicio = (servicio_id) => {
        this.setState({ loading: true })
        axios.get('/servicio/' + servicio_id).then(({data}) => {
            console.log(data)
            this.setState({
                disablePrice: data.precio ? true : false
            })
            this.refModalCompras.current.setFieldsValue({
                precio: data.precio
            })
        }).catch(error => {
            message.error("There's been an error loading the service data")
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }




    render() {

        return (
            <Form
                layout="vertical"
                name="form-cuenta"
                id="form-cuenta"
                ref={this.refModalCompras}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>
                    <Row justify="center" className="mt-2" gutter={[12, 0]}>
                        <Col xs={24}>
                            <Form.Item
                                label="Type of shipping"
                                name="servicio_id"
                                rules={[{
                                    required: true,
                                    message: "Select the service type"
                                }]}
                            >
                                <SelectServicio params={{shipping:true}} onChange={e => this.onSelectServicio(e)}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Price"
                                name="precio"
                                rules={[{
                                    required: true,
                                    message: "Please input the price for service"
                                }]}
                            >
                                <InputNumber disabled={this.state.disablePrice} className="w-100" />
                            </Form.Item>
                        </Col>
                        

                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, detalle_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={detalle_id ? "Edit item" : "Add item"}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-cuenta', key: 'submit', htmlType: 'submit' }}
    >
        <ModalCuentas {...props} />
    </Modal>

}