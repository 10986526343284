import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Tag, Input, InputNumber, Select, message, Upload, Checkbox, Spin, ColorPicker, Divider, } from 'antd';

import axios from "axios"

const { Title, Text } = Typography;
const { Option } = Select;


class ModalVentas extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingClientInfo: false,
            disableEdit: false,
            devices: []
        }
    }

    componentDidMount() {
        this.getCode()
    }

    /**
     * @memberof ModalCategorias
     * @method getCategoria
     * @description Obtiene una Categoria de la DB
     *
     */
    getCode = () => {
        this.setState({ loading: true })
        axios.get('/square/newDevice').then(response => {

            this.setState({ code: response.data })
            console.log(response)

        }).catch(error => {
            message.error("There's been an error obtaining a code, please try again")
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    formRef = React.createRef();


    render() {

        return (
            <>
                <Row>
                    <Col span={24}>
                        <Text italic> To add a terminal please log in with the code displayed below</Text>
                    </Col>
                </Row>
                <Row justify={"center"}>
                    <Text strong style={{fontSize: '3em'}}>{this.state.code}</Text>
                </Row>
            </>

        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, venta_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        onOk={onClose}
        title={"New Device"}
        destroyOnClose={true}
        cancelText="Cancel"
        okText="Ok"
    >
        <ModalVentas {...props} />
    </Modal>

}