import React, { useState, useEffect } from 'react';
import { Button, Modal, message } from "antd"
import { FaFacebook } from "react-icons/fa";
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

function App(props) {
    
    /**
     * @memberof Login
     *
     * @method renderRedirect
     * @description  Activa el redireccionamiento si el formulario se envio con exito
     *
    **/
    const loginFB = () => {
        if(!window.FB){
        	alert("No hay conexión con Facebook")
        	return	
        } 

        window.FB.getLoginStatus(response => {
        	if(response.status === "connected"){
        		getInfoFB(response)
        	}else{
        		window.FB.login(result => {
        			console.log("result", result);
        			if(result.status === "connected"){
						getInfoFB(result)
        			}
        		},{ scope: 'public_profile,email' })
        	}
        })
    };



	/**
     * @memberof Login
     *
     * @method renderRedirect
     * @description  Activa el redireccionamiento si el formulario se envio con exito
     *
    **/
	const getInfoFB = (response) => {
        window.FB.api('/me', 'GET', { "fields": "email,first_name,middle_name,last_name" }, (me) => {
        	console.log("me", me);
			axios.post('/facebook/login', {
				...me,
				authResponse: response.authResponse
			})
			.then(({ data, headers }) => {

				const { setUser } = props;
				axios.defaults.headers.post["Authorization"] = headers.authorization;
				sessionStorage.setItem('token', headers.authorization);
				axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
				setUser(data.user);
				props.navigate(data.user?.cliente_id ? '/customer/dashboard' : (data.user?.rol_id.permisos.dashboard)? '/admin/dashboard': '/admin/');
					
			})
			.catch(error => {
				message.error(error.response?.data?.message ?? "Error al iniciar sesión con Facebook")
			})
		})
    };

    return (
        <div>
            <Button
				type="primary"
				block
				size="large"
				onClick={loginFB}
				icon={<FaFacebook style={{fontSize: "30px", marginRight: "1rem"}}/>}
				className="center btn-facebook mb-1"
			>Continuar con Facebook</Button>
        </div>
    );
}

export default App;
