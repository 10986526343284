import React from 'react'
import { Route, Routes } from "react-router-dom";
import { Layout, Button } from 'antd';

import ThemeEdit from '../Components/ThemeEdit/index';
import Login from '../Components/Auth/Login';
import Recovery from '../Components/Auth/Recovery';
import UpdatePassword from '../Components/Auth/UpdatePassword';
import Register from '../Components/Auth/Register';

import LandingPage from '../Components/Public/LandingPage';
import Tienda from '../Components/Public/Tienda/Tienda';
import PoliticaCookies from '../Components/Public/PoliticaCookies';
import PoliticaPrivacidad from '../Components/Public/PoliticaPrivacidad';
import TerminosCondiciones from '../Components/Public/TerminosCondiciones';

import ProductoDetalle from '../Components/Public/Tienda/ProductoDetalle';

import { SetCarrito, Carrito } from '../Hooks/Carrito';

/**
 * 
 * @export
 * @function PublicRoutes
 * @description Router for handling all public routes
 */
function PublicRoutes() {
  let [carrito, setCarrito] = React.useState([{
    tax:0,
    subtotal:0
  }]);

  return (
    <Carrito.Provider value={carrito}>
      <SetCarrito.Provider value={setCarrito}>
        <Layout>
          <Routes>
            <Route path='/' element={<LandingPage />} />
            <Route path='/product/detail/:id' element={<ProductoDetalle />} />
            <Route path='/store' element={<Tienda />} />

            <Route path='theme' element={<ThemeEdit />} />
            <Route path='login' element={<Login />} />
            <Route path='password/recovery' element={<Recovery />} />

            <Route path='recovery/:token' element={<UpdatePassword />} />
            <Route path='register/:token' element={<UpdatePassword />} />
            <Route path='register' element={<Register />} />
            <Route path='cookie-policy' element={<PoliticaCookies />} />
            <Route path='privacy-policy' element={<PoliticaPrivacidad />} />
            <Route path='terms-conditions' element={<TerminosCondiciones />} />
          </Routes>
        </Layout>
      </SetCarrito.Provider>
    </Carrito.Provider>
  )
}

export default PublicRoutes;