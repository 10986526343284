
import React, { Component, useContext } from 'react'
import { Form, Input, Button, Row, Col, message, Spin, Typography, Modal } from 'antd';
import { Link } from 'react-router-dom';
import axios from "axios";

import FormLogin from "./FormLogin";
import FormRegister from "./FormRegister";
import { User, SetUser } from '../../../Hooks/Logged';


const { Title, Text } = Typography;



class StepAuth extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            view: 0,
        }
    }


    componentDidMount() {
    }

    renderForm = () => {
        let forms = {
            0: <FormLogin {...this.props} />,
            1: <FormRegister {...this.props} />,
        }

        return forms[this.state.view]
    }

    render() {



        return (

            <Spin spinning={this.state.loading}>
                <Row >
                    <Col xs={24}>
                        {this.renderForm()}
                        {this.state.view === 0 ? <>
                            <Text className="mt-1">Do you not have an account? <Link onClick={() => this.setState({ view: 1 })}>Sign Up</Link></Text>
                        </> : <>
                            <Text>Do you have account? <Link onClick={() => this.setState({ view: 0 })}>Login</Link></Text>
                        </>}
                    </Col>
                </Row>
            </Spin>
        )
    }
}


export default function Vista(props) {
    const setUser = useContext(SetUser)

    return <StepAuth {...props} setUser={setUser} />

}