import React, { useEffect, useState, useContext } from 'react'
import { Modal, Steps, Spin, Button, message } from 'antd';

import StepImagenes from './StepImagenes';
import StepCliente from './StepCliente';
import StepDireccion from './StepDireccion';
import StepCollection from './StepCollection';
import StepFinal from './StepFinal';
import StepLogin from './StepLogin';

import axios from 'axios';
import { User } from "../../../Hooks/Logged"

const contentStyle = {
    minHeight: '260px',
    textAlign: 'center',
    marginTop: 16,
};

/**
/**
 *
 *
 * @export
 * @param {*} props
 * @returns Component Modal
 */
const ModalCotizacion = (props) => {

    const [currentStep, setCurrentStep] = useState(props.current ?? 0)
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)

    const user = useContext(User)
    const [quotation, setQuotation] = useState({
        images: {},
        client: {},
        address: {},
        collection: {},
        payment: {},
    });
    const [response, setResponse] = useState({});

    const updateQuotationData = (step, data) => {
        setQuotation((prevData) => ({
            ...prevData,
            [step]: data,
        }));

    };


    useEffect(() => {
        if (currentStep > 4 && !sending) {
            console.log('enviando....')
            sendQuotation()
        }
    })

    /**Actualiza el step para el siguiente elemento */
    const next = (num = 1) => {
        setCurrentStep(currentStep + num);
    };
    /**Actualiza el step para el anterior elemento */
    const prev = () => {
        setCurrentStep(currentStep - 1);
        setDisabled(false)
    };

    /**
     * @method sendQuotation
     * @description Envia la cotizacion al servidor para su almacenamiento
     */
    const sendQuotation = () => {
        console.log('enviando cotizacion')
        setLoading(true)
        setSending(true)

        const formData = new FormData();

        for (const key in quotation) {
            if (quotation.hasOwnProperty(key)) {
                const value = quotation[key];
                // Si es un objeto, convertirlo a una cadena JSON antes de agregarlo al FormData
                if (typeof value === 'object' && value !== null) {
                    formData.append(key, JSON.stringify(value));
                } else {
                    formData.append(key, value);
                }
            }
        }

        formData.append('servicio', props.servicio._id);

        quotation.images.forEach((i, index) => {
            formData.append(`imagenes`, i.image);
        });

        axios.post('/quotation', formData,
            {
                headers: {
                    "Content-Type": 'multipart/form-data'
                }
            })
            .then(({ data }) => {
                setResponse(data)
                message.success('sending quote successfully')
            })
            .catch(error => {
                console.log('error', error)
                message.error('An error occurred while processing the quote, please try again')
            })
            .finally(() => {
                setLoading(false)
            })
    }


    const renderBottonNext = () => {

        let _disabled = disabled
        let num = 1;

        //Si el usuario esta loegeado se pasa al paso del cliente
        if(currentStep === 0 && user?._id){
            num = 2
        }

        if(currentStep === 1) _disabled = true;

        return <Button 
            type="primary" 
            onClick={() => next(num)} 
            disabled={_disabled} 
        >
            Next        
        </Button>
    }

    /**Lista de pasos a seguir para realizar la cotizacion */
    const steps = [
        {
            title: "Images",//0
            content: <StepImagenes currentStep={currentStep} updateQuotationData={updateQuotationData} setDisabled={setDisabled} images={quotation.images} />
        },
        {
            title: "Log In",//1
            content: <StepLogin 
                currentStep={currentStep} 
                updateQuotationData={updateQuotationData} 
                setDisabled={setDisabled} 
                client={quotation.client}
                next={next}
            />
        },
        {
            title: "Client",//2
            content: <StepCliente currentStep={currentStep} updateQuotationData={updateQuotationData} setDisabled={setDisabled} client={quotation.client} next={next} />
        },
        {
            title: "Address",//3
            content: <StepDireccion 
                currentStep={currentStep} 
                updateQuotationData={updateQuotationData} 
                images={quotation.images}
                setDisabled={setDisabled} 
                address={quotation.address} 
                client={quotation.client} 
            />
        },
        {
            title: "Collection",//4
            content: <StepCollection currentStep={currentStep} updateQuotationData={updateQuotationData} setDisabled={setDisabled} collection={quotation.collection} sending={sending} />
        },
        {
            title: "Finish",//5
            content: <StepFinal currentStep={currentStep} updateQuotationData={updateQuotationData} setDisabled={setDisabled} quotation={response} />
        }]


    return <Modal
        closable={true}
        maskClosable={true}
        open={props.visible}
        footer={null}
        onCancel={props.onCancel}
        width={800}
        destroyOnClose={true}
        title={steps[currentStep]?.title}
    >
        <Steps
            current={currentStep}
            direction='horizontal'
        >
        </Steps>

        <Spin spinning={loading}>
            <div 
                className='width-100 ' 
                style={contentStyle}>{steps[currentStep]?.content}
            </div>
            <div className='mt-1 flex flex-right'>
                {currentStep > 0 && (
                    <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                        Previous
                    </Button>
                )}
                {currentStep < steps.length - 1 && (
                    renderBottonNext()
                )}
                {currentStep === steps.length - 1 && (
                    <Button type="primary" onClick={() => props.onFinish(quotation)}>
                        Done
                    </Button>
                )}

            </div>
        </Spin>
    </Modal>

}
export default ModalCotizacion
