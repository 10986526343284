import React, { useState, useEffect } from 'react';
import { Select, Tooltip, message } from 'antd'
import axios from "axios"

const { Option } = Select

/**
 * @const SelectCategoria
 * @description Select para los usuarios registrados en el sistema
 */
const SelectCategoria = (props) => {

    const {
        value,
        onChange,
        placeholder = "Select a category",
        onSelect = () => { },
        disabled = false,
        className = "",
        params = {},
        bordered,
        allowClear = true,
        onClear = () => { },
        mode = null
    } = props

    const [categorias, setCategorias] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getUsuarios
     * @description Obitiene los usaurios
     */
    const getCategorias = ({
        page,
        limit,
        search,
    } = categorias) => {

        axios.get('/categorias', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setCategorias(data)
        }).catch(error => {
            console.log("ERR, error", error)
            message.error('Error getting categories')
        })
    }

    //componentDidMount
    useEffect(() => {
        getCategorias()
    }, [])

    useEffect(() => {
        if (value)
            onChange(value?.value ?? value)
    }, [value])


    return (
        <Select
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            mode={mode}
            onSearch={(search) => getCategorias({ search })}
            onSelect={(categoria) => {
                if (mode === null) {
                    onChange(categoria.value)
                    onSelect(categoria.value)
                }
            }}
            onChange={(values) => {
                if (mode === "multiple") {
                    let arr = values.map(e => e.value)
                    onChange(arr)
                    onSelect(arr)
                }
            }}
            value={value}
            onClear={() => {
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
        >
            {
                categorias?.data?.map(({ _id, nombre, descripcion }) => <Option value={_id} key={_id}>
                    <Tooltip title={descripcion} >
                        {nombre ?? ""}
                    </Tooltip>
                </Option>)
            }
        </Select>
    );
}



export default SelectCategoria;