import { Select, Space, Typography, message } from "antd"
import CustomAvatar from "../../../Widgets/Avatar/Avatar"
import axios from "axios"
import React from "react"

const { Text } = Typography

class SelectVendedor extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            asesores: {
                data: [],
                page: 1,
                limit: 10,
                search: undefined,
                loading: false
            },
            item: this.props.item,
            cliente_id: this.props.id
        }
    }

    componentDidMount() {
        this.setState({cliente_id: this.props.id})
    }

    componentDidUpdate(){
        if (this.state.item?._id !== this.props.item?._id) {
            this.setState({ item: this.props.item })
        }

    }


    getAsesores = ({ page, limit, search } = this.state.asesores, { asesores } = this.state) => {
        this.setState({ asesores: { ...this.state.asesores, page, limit, loading: true } })
        axios.get('/usuarios', {
            params: {
                page, limit, search,
                clientes: false
            }
        })
            .then(response => {
                asesores.data = (page === 1) ? response.data.data : [...asesores.data, ...response.data.data];
                asesores.total = response.data.total
                asesores.pages = response.data.pages
                asesores.loading = false;

                this.setState({ asesores })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.asesores.loading = false
                    return state
                })
            })
    }

    asignarAsesor = (asesor_id, ids) => {
        if (this.state.cliente_id) {
            ids = [this.state.cliente_id]
        }
        console.log(asesor_id,ids)
        this.setState({ loading: true })

        axios.post('/clientes/asignar-asesor', {
            asesor_id, ids
        }).then(success => {
            message.success("Asesor asignado")
            if (this.props.onUpdate) {
                this.props.onUpdate()
            }
        }).catch(error => {
            message.error("Error al asignar asesor")
        })
    }

    render() {

        const { item } = this.state

        return (
            <Select
                disabled={this.props.disabled}
                ref={e => this.selectRef = e}
                notFoundContent={this.state.vendedores?.loading ? "Cargando Usuarios..." : "Sin datos"}
                loading={this.state.vendedores?.loading}
                bordered={false}
                size={'large'}
                className="w-100 "
                placeholder={"Sin Asesor"}
                value={
                    item ? {
                        key: item?._id,
                        value: item?._id,
                        label: <>{item?.nombres || item.nombre} {item?.apellido_paterno} {item?.apellido_materno}</>
                    } : null
                }
                showSearch
                filterOption={false}
                onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !this.state.vendedores?.loading && this.state.vendedores.page < this.state.vendedores.pages) ? this.getAsesores({ page: this.state.vendedores.page + 1 }) : null}
                onSearch={(search) => this.getAsesores({ search, page: 1 })}
                onSelect={(value) => this.asignarAsesor(value, [item?._id])}
                onDropdownVisibleChange={(e) => {
                    if (!e)
                        this.setState({
                            asesores: {
                                data: [],
                                page: 1,
                                limit: 10,
                                search: undefined,
                                loading: false
                            },
                            value: undefined
                        })
                    else {
                        this.getAsesores()
                    }
                }}
            >
                {
                    this.state.asesores.data.map(asesor => {
                        return (
                            <Select.Option key={asesor._id} value={asesor._id}>
                                {asesor?.nombre} {asesor?.apellidos}
                            </Select.Option>
                        )
                    })
                }
            </Select>
        )
    }
}

export default SelectVendedor