
import React, { Component, useRef } from 'react'
import { List, Avatar, Button, Row, Col, Form, Input, Modal, Typography } from 'antd';
import { DeleteOutlined, } from '@ant-design/icons';
import { AvatarLoader } from '../../Widgets/Uploaders';


const { Text } = Typography


const FormImages = (props) => {
    const refImagenes = useRef(null);
    return (
        <Modal
            open={props.open}
            closable={true}
            onCancel={() => {
                refImagenes.current?.resetFields();
                props.cancel();
            }}
            onOk={refImagenes.current?.resetFields()}
            title="New Image"
            okButtonProps={{ form: 'form-add-file-quotation', key: 'submit', htmlType: 'submit' }}
        >
            <Form
                id="form-add-file-quotation"
                name="form-add-file-quotation"
                layout={"vertical"}
                ref={refImagenes}
                onFinish={props.addFile}
            >
                <Form.Item
                    name="image"
                    wrapperCol={{ lg: { offset: 10, span: 14 }, md: { span: 18,offset:10 },xs: { span: 12,offset:6 } }}
                    rules={[{
                        required: true,
                        message: "Please, upload an image"
                    }]}
                >
                    <AvatarLoader />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                    rules={[{
                        required: true,
                        message: "Please, describe the image"
                    }]}
                >
                    <Input.TextArea></Input.TextArea>
                </Form.Item>

            </Form>
        </Modal>
    )
}
export default class StepImagenes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openAddFile: false,
            imagenes: []
        }
    }

    componentDidMount() {
        if (Array.isArray(this.props.images))
            this.setState({ imagenes: this.props.images })
    }
    onFinish = (values) => {
        this.setState({ openAddFile: false })
        let old_imagenes = this.state.imagenes;
        old_imagenes.push(values)
        this.setState({ imagenes: old_imagenes })

        this.props.updateQuotationData('images', old_imagenes)
    }

    deleteImage = (item) => {

        let old_imagenes = this.state.imagenes;
        let index = old_imagenes.findIndex(i => i == item.imagen)

        old_imagenes.splice(index, 1)
        this.setState({ imagenes: old_imagenes })

        this.props.updateQuotationData('images', old_imagenes)
    }

    render() {
        return (
            <>
                <Row gutter={[8, 0]} className='width-100' >
                    <Col xl={4} lg={4} md={24} xs={24} className='bg-left-modal '></Col>
                    <Col xl={20} lg={20} md={24} xs={24}>
                        <Row>
                            <Button block type='primary' onClick={() => this.setState({ openAddFile: true })}>Click to add image</Button>
                            <List
                                itemLayout="horizontal"
                                className='width-100'
                                dataSource={this.state.imagenes}
                                renderItem={(item, index) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={<Avatar shape="square" size={50} src={URL.createObjectURL(item.image)} />}
                                            description={<Text ellipsis={{ rows: 2, expandable: true }} className='m-1'>{item.description}</Text>}
                                        />
                                        <Button type='primary' size='small' danger icon={<DeleteOutlined />} onClick={() => this.deleteImage(item)}></Button>
                                    </List.Item>
                                )}
                            />
                        </Row>
                    </Col>
                </Row>
                <FormImages open={this.state.openAddFile} cancel={() => this.setState({ openAddFile: false })} addFile={this.onFinish} />
            </>
        )
    }
}

