import React from "react";


function SquareLogo(props) {

    const {size = "50px"} = props

    return <svg version="1.1"
    id="svg2" xmlnsDc="http://purl.org/dc/elements/1.1/" xmlnsCc="http://creativecommons.org/ns#" xmlnsRdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlnsSvg="http://www.w3.org/2000/svg" xmlnsSodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlnsInkscape="http://www.inkscape.org/namespaces/inkscape" sodipodiDocname="Square, Inc. logo.svg" inkscapeVersion="0.48.2 r9819"
    xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width={size}
    height={size} viewBox="1 0.901 53.201 53.199" enable-background="new 1 0.901 53.201 53.199" xmlSpace="preserve">
<g id="layer3" inkscapeLabel="2016-rev" inkscapeGroupmode="layer">
   <g id="g3486">
       <path id="path3010" inkscapeConnector-curvature="0" d="M45.333,0.901H9.868C4.992,0.901,1,4.891,1,9.769v35.466
           C1,50.109,4.992,54.1,9.868,54.1h35.466c4.876,0,8.868-3.99,8.868-8.865V9.769C54.201,4.891,50.209,0.901,45.333,0.901
            M44.527,41.609c0,1.55-1.269,2.815-2.82,2.815H13.492c-1.55,0-2.82-1.266-2.82-2.815V13.395c0-1.55,1.27-2.82,2.82-2.82h28.215
           c1.551,0,2.82,1.27,2.82,2.82V41.609z M34.857,33.143c0,0.889-0.726,1.612-1.61,1.612H21.962c-0.887,0-1.61-0.724-1.61-1.612
           V21.859c0-0.887,0.723-1.611,1.61-1.611h11.284c0.885,0,1.61,0.725,1.61,1.611V33.143z"/>
   </g>
</g>
</svg>

}


export default SquareLogo