import React, { Component } from "react";
import { Layout, Menu, Button } from 'antd';
import { useNavigate } from "react-router-dom";
import {
    MenuUnfoldOutlined, MenuFoldOutlined, AppstoreFilled, 
    TagsOutlined, DashboardOutlined, UserOutlined,DollarOutlined , SettingOutlined
} from "@ant-design/icons";
import { IoAnalytics } from "react-icons/io5";
import { BsBoxSeam, BsShieldCheck,BsBoxes ,BsTools} from "react-icons/bs"
import { TbShoe } from "react-icons/tb";
import SubMenu from "antd/lib/menu/SubMenu";
import usePermissions from "../../../Hooks/usePermissions";
import User from "../../../Hooks/Logged";

const { Sider } = Layout;

class Sidebar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            collapsed: false
        }
    }


    /**
     *
     *
     * @memberof AdminSidebar
     */
    toogle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    render() {

        const { props } = this

        return (
            <Sider
                theme="light"
                width={250}
                collapsedWidth={0}
                breakpoint="lg"
                className="custom-sider sider-width"
            >

                <Menu
                    mode="inline"
                    theme="light"
                    className="heigth-100 "
                    style={{ borderRight: 0 }}
                >
                    <Button ghost onClick={this.toogle} className="no-border">
                        {this.state.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </Button>


                    <Menu.ItemGroup key="navegacion" title="NAVEGACIÓN">

                        {props.accessDashboard && <Menu.Item key="dashboard" icon={<DashboardOutlined />} onClick={() => this.props.navigate("/admin/dashboard", { replace: true })}>
                            Dashboard
                        </Menu.Item>}

                        {(props.accessCRM || props.accessClientes) && <SubMenu onTitleClick={() => props.navigate("/admin/crm", { replace: true })} key="crm" title={"CRM"} icon={<SettingOutlined />}>
                            <Menu.Item key="crm-12clientes" onClick={() => this.props.navigate("/admin/crm/clientes/listado", { replace: true })} icon={<AppstoreFilled />} >
                                Clients
                            </Menu.Item>
                        </SubMenu>}
                        {props.accessQuotation && <Menu.Item key="quotations" icon={<TbShoe />} onClick={() => this.props.navigate("/admin/quotations", { replace: true })}>
                            Quotations
                        </Menu.Item>}
                        {props.accessProductos && <Menu.Item key="productos" icon={<BsBoxSeam />} onClick={() => this.props.navigate("/admin/productos", { replace: true })}>
                            Products
                        </Menu.Item>}
                        {props.accessInventario && <Menu.Item key="inventory" icon={<BsBoxes />} onClick={() => this.props.navigate("/admin/inventory", { replace: true })}>
                            Inventory
                        </Menu.Item>}
                        {props.accessServicios && <Menu.Item key="servicios" icon={<BsTools />} onClick={() => this.props.navigate("/admin/services", { replace: true })}>
                            Services
                        </Menu.Item>}
                        {props.accessVentas && <Menu.Item key="ventas" icon={<DollarOutlined />} onClick={() => this.props.navigate("/admin/sales", { replace: true })}>
                            Sales
                        </Menu.Item>}
                        {props.accessCategorias && <Menu.Item key="categories" icon={<TagsOutlined />} onClick={() => this.props.navigate("/admin/categories", { replace: true })}>
                            Categories
                        </Menu.Item>}
                        {props.accessUsuarios && <Menu.Item key="usuarios" icon={<UserOutlined />} onClick={() => this.props.navigate("/admin/usuarios", { replace: true })}>
                            Users
                        </Menu.Item>}
                        {props.accessRoles && <Menu.Item key="roles" icon={<BsShieldCheck />} onClick={() => this.props.navigate("/admin/roles", { replace: true })}>
                            Roles
                        </Menu.Item>}
                        {props.owner && <Menu.Item key="Analytics" icon={<IoAnalytics />} onClick={() => this.props.navigate("/admin/analytics", { replace: true })}>
                            Analytics
                        </Menu.Item>}



                    </Menu.ItemGroup>

                </Menu>

            </Sider>
        )
    }
}

export default function View(props) {

    const user = React.useContext(User)

    let owner = user?.rol_id?.tipo === 1;

    const permisos = usePermissions(user?.rol_id?.permisos, {
        accessDashboard: ['dashboard'],
        accessUsuarios: ['usuarios', 'access'],
        accessClientes: ['clientes', 'access'],
        accessServicios: ['servicios', 'access'],
        accessProductos: ['productos', 'access'],
        accessCategorias: ['categorias', 'access'],
        accessRoles: ['roles', 'access'],
        accessCRM: ['crm', 'access'],
        accessInventario: ['inventario', 'access'],
        accessVentas: ['ventas', 'access'],
    })

    return <Sidebar {...props} navigate={useNavigate()} {...permisos} owner={owner} />
}