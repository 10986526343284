import React, { Component } from 'react'
import {Row, Col, Form, Input, Button, Modal, Spin, Card, Typography, message} from 'antd';
import { Navigate, useParams, useNavigate } from "react-router-dom";


import axios from 'axios';
const { Title } = Typography

/**
 *
 *
 * @class UpdatePassword
 * @extends {Component}
 */
class UpdatePassword extends Component {



    /**
     *Creates an instance of UpdatePassword.
     * @param {*} props
     * @memberof UpdatePassword
     */
    constructor(props) {
        super(props);
        this.state = {
            log: false,
            isAdmin: false,
            isLogged: false,

            isRecovery: false,
            isRecoveryLoading: false,
            redirect: false,
            form: {},
            loading: false
        }
    }

    recoveryPassword = React.createRef();

    redirectTo = "/"


    /**
     *
     *
     * @memberof UpdatePassword
     */
    async componentDidMount(){
        window.a = this
        const { token } = this.props.params;
        if (token === undefined) {
            this.redirectTo = "/";
            this.setState({ redirect: true })
        } else {
            this.recoveryPassword.current.setFieldsValue({ token });
        }
    }


    /**
     *
     *
     * @memberof UpdatePassword
     */
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Navigate to={this.redirectTo} replace />;
        }
    };


    /**
     *
     *
     * @memberof UpdatePassword
     */
    onFinish = async values => {

        console.log("values", values)
        this.setState({ loading: true })
        axios.put('/password/recovery/update', values)
            .then(() => {
               message.success("Password Updated")
               this.props.navigate('/login')

            })
            .catch((error) => {
                console.log(error.response)
                Modal.error({
                    title: "Could not update.",
                    content: "The token is invalid or the email does not exist."
                })
            })
            .finally(() => this.setState({
                loading: false
            }))
    };


    render() {
        return (
            <Row className='layout-login'>
                <Col span={10} className="col-welcome">
                    <Row>
                        <div>
                            <img width="400" src="/logo.svg" alt="Sneacare" />
                            
                        </div>
                    </Row>
                </Col>
                <Col span={14}>
                    <Spin spinning={this.state.loading}>
                        <div className='login-form-container'>
                            <Form
                                layout={"vertical"} requiredMark={false} className='login-form'
                                name="basic" 
                                onFinish={this.onFinish} 
                                initialValues={this.state.form} 
                                ref={this.recoveryPassword}
                            >
                                <Title level={3} className="h3-title mb-3">Update password</Title>
                                <Form.Item name="token" noStyle >
                                    <Input type="hidden" />
                                </Form.Item>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'Email is not valid',
                                        },
                                        {
                                            required: true,
                                            message: 'Enter the email',
                                        }, {
                                            max: 50,
                                            message: "Maximum 50 characters"
                                        },{
                                            validator: (_, value) => {
                                                // Transformar el valor a minúsculas
                                                const lowercaseValue = value.toLowerCase();
                                                if (value !== lowercaseValue) this.recoveryPassword.current.setFieldsValue({ email: lowercaseValue });
                                                return Promise.resolve();
                                            },
                                        }
                                    ]}
                                >
                                    <Input className="input-login" />
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    label="Password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please,enter your password.',
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password className="input-login" />
                                </Form.Item>

                                <Form.Item
                                    name="confirm"
                                    label="Confirm Password"
                                    dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('Passwords do not match.');
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password className="input-login" />
                                </Form.Item>

                                <Form.Item >
                                    <Button type="primary" htmlType="submit" block size="large" >
                                        Update Password
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Spin>
                </Col>
            </Row>
        )
    }
}



export default function Vista(props) {
    return <UpdatePassword {...props} params={useParams()} navigate={useNavigate()} />

}