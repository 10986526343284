import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, Tag, message, Divider, Spin, List, Image, Space, ColorPicker, Tooltip } from 'antd';
import axios from "axios"
import { useNavigate } from "react-router-dom";
import { renderEstatusVenta, RenderSlot, FolioFormat, tagDiasCreacion } from '../../Utils';
import { ButtonView } from "../../Widgets/Buttons";

import { IconTruck } from '../../Widgets/Iconos';

const Decimal = require('decimal.js');
const { Text, Title, Paragraph } = Typography;

const moment = require('moment');

class ModalVentas extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            venta: {
                detalles: []
            }
        }
    }

    renderTypeMaterial = (tipo) => {
        switch (tipo) {
            case 1:
                return "Fabric";
            case 2:
                return "Leather";
            case 3:
                return "Suede"
            default:
                return "N/A"
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        if (this.props.venta_id) {
            this.getVenta()
        }
    }

    /**
     * @memberof ModalVentaDetalles
     * @method get
     * @description Obtiene una Cuenta de la DB
     *
     */
    getVenta = () => {
        this.setState({ loading: true })
        axios.get('/venta/' + this.props.venta_id)
            .then(({ data }) => {
                console.log("venta", data.direccion);
                this.setState({ venta: data })
            }).catch(error => {
                message.error('Error getting the sale')
                console.log(error)
            }).finally(() => this.setState({ loading: false }))
    }


    render() {

        let { venta } = this.state;

        let place = venta?.direccion?.localizacion?.place_name?.split(',') || []

        return (
            <Form
                layout="vertical"
                name="form-transaccion"
                id="form-transaccion"
                ref={this.formRef}
                onFinish={() => { }}

            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 0]} className="mt-1">
                        <Col xs={24}>
                            <Title level={5} className="m-0">Folio: {venta.folio ?? "-"} {venta.servicio_express ? <Tag color="red">EXPRESS</Tag> : null}</Title>
                        </Col>
                        <Col xs={12}>
                            <Text className="text-gray">Date: {moment(venta.createdAt).format("MM/DD/YYYY HH:mm")}</Text>
                        </Col>
                        <Col xs={12} className="flex-right">
                            {renderEstatusVenta(venta.status)}
                            {tagDiasCreacion(venta.createdAt,venta.status)}
                        </Col>
                        <Col xs={18}>
                            <Text className="text-gray">SQUARE ID: {venta.square_id}</Text>
                        </Col>
                        <Col xs={6} className="flex-right">
                            { venta.pick_and_deliver_flag ? <Tooltip title={"Marked as Pick-Up & Delivery"}>
                                <IconTruck /> 
                            </Tooltip>: null }
                        </Col>
                        <Col xs={12} className="flex-left-column mt-1 mb-1">
                            <Text strong>Client: {venta.cliente_id?.nombre ?? '-'} {venta.cliente_id?.apellidos}</Text>
                            <Text>{venta.cliente_id?.email}</Text>
                            <Text>{venta.cliente_id?.telefono}</Text>
                            <Text>{venta.zip_code ? `ZIP: ${venta.zip_code}` : ""}</Text>
                            <Text>{venta?.direccion?.localizacion?.place_name ?? venta.direccion?.codigo_postal}</Text>
                            <Text>{venta.direccion?.especificaciones}</Text>
                            <Text>{venta.direccion?.notas}</Text>

                        </Col>
                        { venta?.collection_date ? <> <Col xs={12} className="flex-column-right mt-1 mb-1">
                            <Text className="text-right">Collection Date: {moment(venta?.collection_date).format('MM/DD/YYYY')}</Text>
                            <Text className="text-right">Collection time: {RenderSlot(venta?.time_slot)}</Text >
                        </Col> </> : null }
                        { venta.notes ? <Col xs={24}>
                            <Paragraph>
                                <Text strong>Notes:</Text>
                                { venta.notes }
                            </Paragraph>
                        </Col> : null }
                        <Col xs={24} className="mt-1 mb-1">
                            <Text strong>{venta.detalles?.length ?? 0} ITEMS</Text>
                        </Col>
                        <Col span={24}>
                            {
                                venta.detalles?.map(detalle => {
                                    return <Row className="mb-1">
                                        <Col span={11} className="flex-left-column">
                                            {detalle.variante_id ? <Text>{detalle.variante_id?.nombre}</Text> : null}
                                            {detalle.variante_id ? <Text className="text-gray">$ {detalle.variante_id?.precio?.toMoney(true) ?? 0}</Text> : null}
                                            {detalle.servicio_id ? <Text>{detalle.servicio_id?.nombre}</Text> : null}
                                            {detalle.servicio_id ? <Text className="text-gray">$ {detalle.servicio_id?.precio?.toMoney(true) ?? 0}</Text> : null}
                                            {detalle.discount || detalle.general_discount ? <Text className="text-red"> - $ {Decimal(detalle.discount ?? 0).add(detalle.general_discount ?? 0).toNumber().toMoney(true)}</Text> : null}
                                        </Col>
                                        <Col span={5} className="center">
                                            <Text>{detalle.cantidad}</Text>
                                        </Col>
                                        <Col span={8} className="flex-right">
                                            <Text>$ {detalle.total?.toMoney(true)}</Text>
                                        </Col>
                                        {detalle.servicio_id ? <Col span={24}>
                                            <Row>
                                                {detalle.brand ? <>
                                                    <Col span={4}>
                                                        Brand:
                                                    </Col>
                                                    <Col span={8}>
                                                        {detalle.brand ?? "N/A"}
                                                    </Col>
                                                </> : null}
                                                {detalle.model ? <><Col span={4}>
                                                    Model:
                                                </Col>
                                                    <Col span={8}>
                                                        {detalle.model ?? "N/A"}
                                                    </Col></> : null}
                                                {detalle.type_material ? <><Col span={4}>
                                                    Material:
                                                </Col>
                                                    <Col span={8}>
                                                        {this.renderTypeMaterial(detalle.type_material)}
                                                    </Col></> : null}
                                                {detalle.size_id ? <><Col span={4}>
                                                    Size:
                                                </Col>
                                                    <Col span={8}>
                                                        {detalle.size_id.nombre ?? "N/A"}
                                                    </Col></> : null}
                                                {detalle.outsole ? <><Col span={4}>
                                                    Outsole:
                                                </Col>
                                                    <Col span={8}>
                                                        {detalle.outsole ?? "N/A"}
                                                    </Col></> : null}

                                                {detalle.primary_color ? <><Col span={6}>
                                                    Primary Color:
                                                </Col>
                                                    <Col span={6}>
                                                        <ColorPicker value={detalle.primary_color} disabled={true} />
                                                    </Col></> : null}
                                                {detalle.secondary_color ? <><Col span={6}>
                                                    Secondary Color:
                                                </Col>
                                                    <Col span={6}>
                                                        <ColorPicker value={detalle.secondary_color} disabled={true} />
                                                    </Col></> : null}
                                                {detalle.notes ? <> <Col span={24}>
                                                    Notes:
                                                </Col>
                                                    <Col span={24}>
                                                        <Text>
                                                            {detalle.notes}
                                                        </Text>
                                                    </Col></> : null}
                                            </Row>
                                        </Col> : null}
                                        <Divider />
                                    </Row>
                                })
                            }
                        </Col>
                        {venta.cotizacion_id ?
                            <>
                                <Divider />
                                <Col span={24}>
                                    <List
                                        loading={this.state.loading}
                                        itemLayout="horizontal"
                                        className="header-noborder"
                                        header={
                                            <Row>
                                                <Col span={22}>
                                                    <Title level={5} className="m-0">Quotation: {FolioFormat(venta.cotizacion_id?.folio)}</Title >
                                                    <Text className="m-0 text-gray">Quotation Date: {moment(venta.cotizacion_id?.fecha).format('MM/DD/YYYY')}</Text >

                                                </Col>
                                                <Col span={2}>
                                                    <ButtonView
                                                        size="small"
                                                        onClick={() => this.props.navigate(`/admin/quotations/${venta.cotizacion_id._id}`)}
                                                    /></Col>
                                            </Row>
                                        }
                                        footer={
                                            <>
                                                <Row gutter={[8, 8]}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={24}>  <Text className="m-0">Collection Date: {moment(venta.cotizacion_id?.collection_date).format('MM/DD/YYYY')}</Text ></Col>
                                                            <Col span={24}>  <Text className="m-0">Collection time: {RenderSlot(venta.cotizacion_id?.time_slot)}</Text ></Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={24}>  {venta.cotizacion_id.entrega_local ? <Tag color="#f22eef">Store Pickup</Tag> : null}
                                                            </Col>
                                                            <Col span={24}>  {venta.cotizacion_id.pick_and_deliver ? <Tag color="#f22eef">Pick Up and Delivery</Tag> : null}</Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        locale={{ emptyText: "Files not found" }}
                                        dataSource={venta.cotizacion_id?.imagenes}
                                        renderItem={item => <List.Item className="quotation-files-item">
                                            <Row className="width-100" gutter={[6, 8]} align={"middle"}>
                                                <Col span={6}>
                                                    <Image
                                                        src={`${axios.defaults?.baseURL}/cotizacion/${venta.cotizacion_id?._id}?imagen=${item.imagen.file}&Authorization=${sessionStorage.getItem('token')}`}
                                                        placeholder
                                                        maxScale={100}
                                                        className='item-image'

                                                    />
                                                </Col>
                                                <Col span={18}>
                                                    <Space size={4} direction='vertical'>
                                                        <Text strong>Description</Text>
                                                        <Paragraph ellipsis={{ rows: 2, expandable: true }}>{item.descripcion}</Paragraph>
                                                    </Space>
                                                </Col>
                                            </Row>
                                        </List.Item>}
                                    />
                                </Col>
                            </>
                            : null}
                        <Divider />
                        <Col xs={12} className="flex-right">
                            <Text className="text-gray">Subtotal</Text>
                        </Col>
                        <Col xs={12} className="flex-right">
                            <Text strong>$ {venta.subtotal?.toMoney(true) ?? 0}</Text>
                        </Col>
                        <Col xs={12} className="flex-right">
                            <Text className="text-gray">Tax</Text>
                        </Col>
                        <Col xs={12} className="flex-right">
                            <Text strong>$ {venta.tax?.toMoney(true) ?? 0}</Text>
                        </Col>
                        <Col xs={12} className="flex-right">
                            <Text className="text-gray">Total</Text>
                        </Col>
                        <Col xs={12} className="flex-right">
                            <Text strong>$ {venta.total?.toMoney(true) ?? 0}</Text>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, venta_id } = props
    const navigate = useNavigate()

    return <Modal
        open={visible}
        onCancel={onClose}
        title={"Sale details"}
        destroyOnClose={true}
        footer={null}

    >
        <ModalVentas {...props} navigate={navigate} />
    </Modal>

}