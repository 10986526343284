import React, { Component, } from 'react';
import { Row, Col, Layout, Card, Tooltip, Tabs } from 'antd'
import axios from 'axios';
import ReactECharts from 'echarts-for-react';
import { FaCreditCard } from "react-icons/fa";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MdPointOfSale, MdAttachMoney, MdOutlineCleaningServices } from "react-icons/md";

//Componentes
import Ventas from "./Ventas";
import Clientes from "./Clientes";
import useSearch from '../../../Hooks/useSearch';
import { CardStatistics } from '../../Widgets/Cards';
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import DrawerFiltros from '../../Widgets/DrawerFiltros/DrawerFiltros'
import { IconWallet, IconGraphic, IconCuenta, IconShield } from '../../Widgets/Iconos'

const { Content } = Layout;

/**
 *
 *
 * @export
 * @class Analytics
 * @extends {Component}
 * @description Vista donde se muestra un resumen de las estadisticas del sistema
 */
class Analytics extends Component {

    back = () => {
        this.props.navigate(-1)
    }


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            total_ventas: 0,
            ventas_pagadas: 0,
            ventas_pendientes: 0,
            pares_limpiados: 0,



            ventas_count: 0,
            clientes_count: 0,
            cotizaciones_count: 0,
            productos_count: 0,
            ventas_servicio: {},
            ventas_clientes: {},
            serie_anual: [],
            fechas: [],
        }
    }


    componentDidMount() {
        this.props.setShowSearch(false)
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
    }

    render() {

        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title="Analytics"
                />
                <Content className="admin-content content-bg pd-1">
                    <Tabs 
                        defaultActiveKey="1" 
                        items={[
                            {
                                key: '1',
                                label: 'Sales',
                                children: <Ventas/>,
                            },{
                                key: '2',
                                label: 'Clients',
                                children: <Clientes/>,
                            }
                        ]}
                    />
                </Content>

            </>
        )
    }
}

export default props => {
    const { setShow } = useSearch()
    const navigate = useNavigate()
    const [params, setParams] = useSearchParams();



    return <Analytics {...props} setShowSearch={setShow}  navigate={navigate} params={params} />
}
