import React, { Component } from "react";
import { Layout, Menu, Button } from 'antd';
import { useNavigate } from "react-router-dom";
import { MenuUnfoldOutlined, MenuFoldOutlined, DollarOutlined,DashboardOutlined ,SettingOutlined, ProfileOutlined} from "@ant-design/icons";

const { Sider } = Layout;

class Sidebar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            collapsed: false
        }
    }


    /**
     *
     *
     * @memberof Sidebar
     */
    toogle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }
    render() {

        return (
            <Sider
                theme="light"
                width={250}
                collapsedWidth={0}
                breakpoint="lg"
                className="custom-sider sider-width"
            >

                <Menu
                    mode="inline"
                    theme="light"
                    className="heigth-100 "
                    style={{ borderRight: 0 }}
                >
                    <Button ghost onClick={this.toogle} className="no-border">
                        {this.state.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </Button>


                    <Menu.ItemGroup key="navegacion" title="NAVEGACIÓN">

                        <Menu.Item key="dashboard" icon={<DashboardOutlined  />} onClick={() => this.props.navigate("/customer/dashboard", { replace: true })}>
                            Dashboard
                        </Menu.Item>
                        <Menu.Item key="transacciones" icon={<DollarOutlined />} onClick={() => this.props.navigate("/customer/transactions", { replace: true })}>
                            Sales
                        </Menu.Item>
                        <Menu.Item key="quotations" icon={<SettingOutlined  />} onClick={() => this.props.navigate("/customer/quotations", { replace: true })}>
                            Quotations
                        </Menu.Item>
                        <Menu.Item key="direcciones" icon={<ProfileOutlined />} onClick={() => this.props.navigate("/customer/addresses", { replace: true })}>
                            Addresses
                        </Menu.Item>

                    </Menu.ItemGroup>

                </Menu>

            </Sider>
        )
    }
}

export default function View(props) {

    return <Sidebar {...props} navigate={useNavigate()} />
}