import React, { useEffect, useState, Component, useContext } from 'react';
import { Layout, Row, Col, Button, Spin, Typography, Form, Select, message, Statistic, Card } from 'antd';
import { useParams } from "react-router-dom";
import ProductControl from '../../Widgets/ProductControl/ProductControl';
import axios from 'axios'
import { useCookies } from "react-cookie";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import { User } from "../../../Hooks/Logged"
import { Carrito, SetCarrito } from "../../../Hooks/Carrito"

import MyHeader from '../Header';
import Footer from '../Footer';
import '../../../Styles/Modules/Landing.scss'
import { Content } from 'antd/es/layout/layout';

const { Option } = Select
const { Paragraph, Title, Text } = Typography

function InputControlProduct(props) {
    const { limit, onChange } = props
    let [value, setValue] = useState(props.value)


    const onQuantityChange = (index, quantity) => {
        const valueA = parseFloat(quantity)

        if (isNaN(valueA))
            return message.error("Is not a valid number")

        if (valueA > limit)
            return message.error("No more availability.")

        if (valueA <= 0)
            return message.error("Should be at least one product to add to the cart.")

        onChange(valueA)
        setValue(valueA)
    }

    return <ProductControl
        onQuantityChange={onQuantityChange}
        index={null}
        quantity={value}
        className='width-100'
        limit={limit}
        deleteOption={false}

    />
}

/**
 *
 *
 * @export
 * @class ProductoDetalle
 * @extends {Component}
 * @description Pagina tipo tienda del sistema
 */
class ProductoDetalle extends Component {

    formProducto = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            producto_id: this.props.params.id,
            producto: {},
            imagenes: [],
            variantes: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
                filters: []
            },
            variante: {},
            spinning: false,
        }
    };

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProducto()
        this.getVariantes()
        window.scrollTo(0, 0)
    }


    /**
   * @memberof ProductoDetalle
   *
   * @method   getProducto
   * @description  Metodo que realiza la busqueda de productos
   **/
    getProducto = () => {
        this.setState({ loading: true })
        axios.get('/product/' + this.props.params.id)
            .then(async ({ data }) => {

                this.setState({
                    producto: data,
                    imagen: data.imagenes[0],
                    imagenes: data.imagenes,
                })
            }).catch(error => {
                console.log(error)
                message.error('Error obtaining the product.')
            })
            .finally(() => this.setState({ loading: false }))

    }

    /**
   * @memberof ProductoDetalle
   *
   * @method   getVariantes
   * @description  Metodo que realiza la busqueda de variantes del producto
   **/
    getVariantes = () => {
        this.setState({ loading: true })
        axios.get('/variants', {
            params: {
                producto_id: this.props.params.id,
                en_stock: true,
                page: 1,
                limit: 50
            }
        })
            .then(({ data }) => {
                this.setState({
                    variantes: data,
                    variante: data.data[0] ? data.data[0] : {}
                })
                this.getVariante(data.data[0]._id)
                this.formProducto.current.setFieldsValue({
                    variante_id: data.data[0] ? data.data[0]._id : null
                })
            }).catch(res => {
                console.log("error", res);
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof FormVariantes
    *
    * @method getVariantes
    * @description  trae la informacion de una categoria
    *
    **/
    getVariante = (id) => {
        this.setState({ loading: true })
        axios.get('/variant/' + id,{
            params:{
                inventario: true
            }
        }).then(async ({ data }) => {
            this.setState({ variante: data })
        }).catch(res => {
            console.log("error", res);
            message.error('Error when bringing the information ')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof FormVariantes
    *
    * @method onFinish
    * @description  Se ejecuta al hacer click para agregar el elemento al carrito
    *
    **/
    onFinish = (values) => {

        this.setState({ loading: true })

        values.variante_id  = values?.variante_id?.value ?? values.variante_id;

        let variante = this.state.variantes.data.find(v => v._id == values.variante_id)

        if(!variante._id){
            message.error("Error getting variant")
            this.setState({loading: false})
            return
        }

        this.addToCart(values.cantidad, variante)
    }

    /**
    * @memberof LandingPage
    * @description Añade un elemento al carrito
    */
    addToCart = (cantidad, variante = {}) => {
        console.log("cantidad", cantidad);

        const cart = this.props.cookies.cart;
        this.setState({ loading: true })
        axios.post('/carrito', {
            venta_id: cart,
            detalles: {
                cantidad: cantidad,
                variante_id: variante,
            },
            cliente_id: this.props.user?.cliente_id,
        }).then(({ data }) => {
            console.log("data---------------------------------", data);
            this.props.setCookie("cart", data._id)
            this.props.setCart(data)

        }).catch((error) => {
            message.error(error?.response?.data?.message ?? "Could not add item to cart")
        }).finally(()=>this.setState({loading: false}))
        

    }


    render() {

        let { producto, imagenes, variante } = this.state;
        return (
            <>
                <Layout className="store landing"
                    style={{
                        background: 'rgb(127,135,203)',
                        background: 'linear-gradient(180deg, rgba(127,135,203,1) 0%, rgba(255,255,255,1) 100%)',
                        height: '100vh',
                    }}>
                    <MyHeader
                        {...this.props}
                    />
                    <Spin spinning={this.state.loading}>
                        <Content>
                            <Row justify="center" className='pd-1 section-reduce' style={{ marginTop: 100 }}>
                                <Col flex={5} >
                                    <Row gutter={[16, 16]} justify={'space-evenly'} className=" pd-1">
                                        <Col xs={20} lg={10} flex={3} >
                                            <Carousel
                                                showArrows={true}
                                                className='carousel-productos'>
                                                {
                                                    Array.isArray(imagenes) && imagenes.length > 0 ? imagenes?.map(img => {
                                                        return <div>
                                                            <img
                                                                className='carousel-item'
                                                                src={`${process.env.REACT_APP_WEB_SERVICE}/product/${producto._id}/?imagen=${img?.file}`}
                                                            />
                                                        </div>
                                                    })
                                                        : <img
                                                            className='carousel-item'
                                                            src="/img/default.jpg" />

                                                }
                                            </Carousel>
                                        </Col>
                                        <Col xs={20} lg={10} flex={2}>
                                            <Card>
                                                <Row className='pd-1'>
                                                    <Col span={24}><Title level={3}>{producto.nombre}</Title></Col>
                                                    <Col span={24}><Title level={4}>Description</Title></Col>
                                                    <Col span={24}>
                                                        <Paragraph >
                                                            {producto.descripcion}
                                                        </Paragraph>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Text strong>$ {variante.precio ?? 0.00} <small>USD</small></Text>
                                                    </Col>
                                                    <Col span={24} className="mt-1">
                                                        <small style={{color: "black"}}>In Stock: {variante.inventarios?.existencias ?? 0} </small>
                                                    </Col>
                                                </Row>
                                                <Row className='width-100 pd-1'>
                                                    <Form
                                                        layout="vertical"
                                                        ref={this.formProducto}
                                                        onFinish={this.onFinish}
                                                        initialValues={{
                                                            cantidad: 1
                                                        }}
                                                        className='pd-1 width-100'
                                                    >

                                                        <Form.Item
                                                            name="variante_id"
                                                            label="Size"
                                                        >
                                                            <Select
                                                                placeholder="Select option"
                                                                onSelect={(value) => {
                                                                    const selectedKey = value?.key;
                                                                    this.getVariante(selectedKey)
                                                                }}
                                                                labelInValue
                                                                size="large"
                                                            >
                                                                {
                                                                    this.state.variantes?.data?.map(v => {
                                                                        return <Option key={v._id} value={v._id} >{v.nombre}</Option>
                                                                    })
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="cantidad"
                                                            label="Quantity"
                                                        >
                                                            <InputControlProduct
                                                                quantity={1}
                                                                className='width-100'
                                                                limit={variante.inventarios?.existencias ?? 0}
                                                            />
                                                        </Form.Item>

                                                        <Form.Item className="width-100">
                                                            <Button htmlType="submit" type="primary" className="width-100" block size='large' loading={this.state.loading}>
                                                                Add to cart
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Footer />
                        </Content>
                    </Spin>
                </Layout>
            </>
        )
    }
}

export default function Detalle(props) {
    const params = useParams()
    let cart = useContext(Carrito);
    let setCart = useContext(SetCarrito)
    const [cookies, setCookie] = useCookies(['cart']);

    let user = useContext(User)
    return (<ProductoDetalle
        {...props}
        params={params}
        user={user}
        cart={cart}
        cookies={cookies}
        setCookie={setCookie}
        setCart={setCart}
    />);

}