import React, { useState, useEffect } from 'react';
import { ColorPicker, Row, Col } from 'antd';
import { PresetColors } from '@ant-design/colors';

const CustomColorPicker = (props) => {

  const { value, onChange } = props

  const [color, setColor] = useState(value);

  // Define los colores personalizados
  const customColors = [
    {
      value: '#ff0000',
    },
    {
      value: '#0acb12',
    },
    {
      value: '#0900ff',
    },
    {
      value: '#000000',
    },
    {
      value: '#ffffff',
    },
    {
      value: "#eeeb27"
    }
  ];

  useEffect(() => {
      setColor(value)
  }, [value])

  return (
      <ColorPicker
        value={color}
        onChange={(value) => setColor(value.hex)}
        panelRender={(panel) => {
          return <Row>
            {customColors.map(e => {
              return <Col onClick={()=>{
                setColor(e.value)
                onChange(e.value)
              }}>
                <ColorPicker 
                  disabled 
                  value={e.value} 
                  className="hover" 
                  style={{margin:"4px"}}

                />
              </Col>
            })}
          </Row>
        }}
      />
  );
};

export default CustomColorPicker;
