import React, { Component, } from 'react';
import { Row, Col, Layout, Card } from 'antd'
import ReactECharts from 'echarts-for-react';
import axios from 'axios';
import { IconWallet, IconGraphic, IconCuenta, IconShield } from '../../Widgets/Iconos'
import { CardStatistics } from '../../Widgets/Cards'
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSearch from '../../../Hooks/useSearch';
import PageHeader from '../../Widgets/PageHeader/PageHeader';

const { Content } = Layout;

/**
 *
 *
 * @export
 * @class Dashboard
 * @extends {Component}
 * @description Vista donde se muestra un resumen de las estadisticas del sistema
 */
class Dashboard extends Component {

    back = () => {
        this.props.navigate(-1)
    }


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            ventas_count: 0,
            clientes_count: 0,
            cotizaciones_count: 0,
            productos_count: 0,
            ventas_servicio: {},
            ventas_clientes: {},
            serie_anual: [],
            fechas: [],
        }
    }


    componentDidMount() {
        this.props.setShowSearch(false)
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getInfo()
        this.getGrafica()
    }

    /**
    * @memberof Dashboard
    * @method getInfo
    * @description Obtiene la informacion del sistema general
    */
    getInfo = () => {
        this.setState({ loading: true })
        axios.get('/dashboard')
        .then(({data}) => {

            console.log('DATA', data)
            this.setState({
                ventas_count: data.ventas_count,
                clientes_count: data.clientes_count,
                cotizaciones_count: data.cotizaciones_count,
                productos_count: data.productos_count,
                ventas_servicio: data.ventas_servicios_mensual,
                ventas_clientes: data.ventas_servicio_semanal,
            })

        }).catch(error => {
            console.log("error", error);

        }).finally(()=>this.setState({loading: false}))
    }

     /**
    * @memberof Dashboard
    * @method getGrafica
    * @description Obtiene la informacion del sistema general
    */
    getGrafica = () => {
        this.setState({ loading: true })
        axios.get('/dashboard/grafica')
        .then(({data}) => {
            console.log("data", data);

            this.setState({serie_anual: data.info, fechas: data.fechas})

        }).catch(error => {
            console.log("error", error);

        }).finally(()=>this.setState({loading: false}))
    }


    setGrafica = () => {
        var series = this.state.serie_anual
        
        const stackInfo = {};
        
        if(series?.length > 0){
            for (let i = 0; i < series[0].data.length; ++i) {
                for (let j = 0; j < series.length; ++j) {
                    const stackName = series[j].stack;
                    if (!stackName) {
                        continue;
                    }
                    if (!stackInfo[stackName]) {
                        stackInfo[stackName] = {
                            stackStart: [],
                            stackEnd: []
                        };
                    }
                    
                    const info = stackInfo[stackName];
                    const data = series[j].data[i];
                    if (data && data !== '-') {
                        if (info.stackStart[i] == null) {
                            info.stackStart[i] = j;
                        }
                        info.stackEnd[i] = j;
                    }
                }
            }

            for (let i = 0; i < series.length; ++i) {
                const data = series[i].data;
                const info = stackInfo[series[i].stack];
                for (let j = 0; j < series[i].data.length; ++j) {
                    // const isStart = info.stackStart[j] === i;
                    const isEnd = info.stackEnd[j] === i;
                    const topBorder = isEnd ? 20 : 0;
                    const bottomBorder = 0;
                    data[j] = {
                        value: data[j],
                        itemStyle: {
                            borderRadius: [topBorder, topBorder, bottomBorder, bottomBorder]
                        }
                    };
                }
            }
        }
        
        return {
            xAxis: {
                type: 'category',
                data: this.state.fechas,
            },
            tooltip: {
                trigger: 'item'
            },
            yAxis: {
                type: 'value'
            },
            series: series
        }
    }


    render() {


        let option = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                top: '5%',
                left: 'center'
            },
            series: [
                {
                   
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        {
                            value: this.state.ventas_clientes?.total_quantity ,
                            name: 'Pairs',
                            itemStyle: {
                                color: '#ee6666'
                            }
                        },
                        {
                            value: this.state.ventas_clientes?.total_sales,
                            name: 'Sales',
                            itemStyle: {
                                color: '#fac858'
                            }
                        }
                    ],
                    tooltip: {
                        trigger: 'item',
                        formatter(params) {

                            
                          return `${params.marker}${params.name}<span style="float: right; margin-left: 20px"><b>${(params.name == 'Sales')? '$' : ''} ${params.value}</b>`;
                        }
                      },
                    
                    // this.state.ventas_clientes.map(e => {
                    //     return { value: e.count, name: e._id === 1 ? "Customer" : "Influencer" }
                    // })
                }
            ]

        };

        let option_2 = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                top: '5%',
                left: 'center'
            },
            series: [
                {
                   
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    labelLine: {
                        show: false
                    },
                    data:[
                        {
                            value: this.state.ventas_servicio?.total_quantity ,
                            name: 'Pairs',
                            itemStyle: {
                                color: '#ee6666'
                            }
                        },
                        {
                            value: this.state.ventas_servicio?.total_sales ,
                            name: 'Sales',
                            itemStyle: {
                                color: '#fac858'
                            },
                        }
                    ],
                    tooltip: {
                        trigger: 'item',
                        formatter(params) {

                            
                          return `${params.marker}${params.name}<span style="float: right; margin-left: 20px"><b>${(params.name == 'Sales')? '$' : ''} ${params.value}</b>`;
                        }
                      },

                    
                    // this.state.ventas_servicio[0]?.map(e => {
                    //     return { 
                    //         value: e.count, 
                    //         name: e._id === 0 ? "Cleaning" : "Repair",
                    //         itemStyle: { 
                    //             color: e._id === 0 ? "#fac858" : "#ee6666" 
                    //         }
                    //     }
                    // })
                }
            ]

        };

        var emphasisStyle = {
            itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(0,0,0,0.3)'
            }
        };

        let option_3 = {
            legend: {
                data: this.state.plantas?.nombres,
                left: '10%'
            },
            tooltip: {},
            xAxis: {
                data: this.state.plantas?.nombres,
                axisLine: {
                    onZero: true
                },
                splitLine: {
                    show: false
                },
                splitArea: {
                    show: false
                },
                axisLabel: {
                    rotate: 90
                }
            },
            yAxis: {},
            grid: {
                bottom: 100
            },
            series: [{
                name: 'Disponibles',
                type: 'bar',
                barWidth: 30,
                barCategoryGap: 20,
                stack: 'one',
                emphasis: emphasisStyle,
                data: this.state.plantas?.disponibles
            }, {
                name: 'Ocupados',
                type: 'bar',
                barWidth: 30,
                barCategoryGap: 20,
                stack: 'one',
                emphasis: emphasisStyle,
                data: this.state.plantas?.ocupados
            },]
        };
        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title="Dashboard"
                />
                <Content className="admin-content content-bg pd-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={12} lg={6} className="grid">
                            <CardStatistics title="Total Sales" suffix={""} monto={this.state?.ventas_count?.toMoney(true, false) ?? 0} icon={(color) => <IconWallet color={color} />} />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6} className="grid">
                            <CardStatistics title="Total Quotations" suffix={""}  monto={this.state?.cotizaciones_count?.toMoney(true, false) ?? 0} icon={(color) => <IconGraphic color={color} />} />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6} className="grid">
                            <CardStatistics title="Total Products "  monto={this.state?.productos_count?.toMoney(true, false) ?? 0} icon={(color) => <IconCuenta color={color} />} />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6} className="grid">
                            <CardStatistics title="Total Clients " suffix={""}  monto={this.state?.clientes_count?.toMoney(true, false) ?? 0} icon={(color) => <IconShield color={color} />} />
                        </Col>
                        <Col xs={24} md={24} lg={12}>
                            <Card title={'Sales of the week'} style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
                                <Row>
                                    <Col span={24} className="center">
                                        <ReactECharts
                                            option={option}
                                            style={{ width: '100%', maxWidth: 400 }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} md={24} lg={12}>
                            <Card title={'Sales of the month'} style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
                                <Row>
                                    <Col span={24}>
                                        <ReactECharts
                                            option={option_2}
                                            style={{ width: '100%', maxWidth: 700 }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card title={'Annual sale of services'} style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
                                <Row>
                                    <Col span={24} className="center">
                                        <ReactECharts
                                            option={this.setGrafica()}
                                            style={{ width: '100%', maxWidth: 1200 }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                    </Row>
                </Content>

            </>
        )
    }
}

export default props => {
    const { setShow } = useSearch()
    const navigate = useNavigate()
    const [params, setParams] = useSearchParams();



    return <Dashboard {...props} setShowSearch={setShow}  navigate={navigate} params={params} />
}
