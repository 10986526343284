import React, { Component, } from 'react';
import { Row, Col, Layout, Card, Tooltip, Space, Button, Typography, Select, DatePicker } from 'antd'
import axios from 'axios';
import dayjs from "dayjs";
import ReactECharts from 'echarts-for-react';
import { FaUserPlus } from "react-icons/fa6";
import { FaCreditCard, FaUser } from "react-icons/fa";
import { QuestionCircleOutlined, FilterOutlined, DownloadOutlined } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MdPointOfSale, MdAttachMoney, MdOutlineCleaningServices } from "react-icons/md";

//Componentes
import useSearch from '../../../Hooks/useSearch';
import { CardStatistics } from '../../Widgets/Cards';
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import DrawerFiltros from '../../Widgets/DrawerFiltros/DrawerFiltros'
import { IconWallet, IconGraphic, IconCuenta, IconShield } from '../../Widgets/Iconos'

const { Content } = Layout;
const { Text, Title } = Typography;
const { RangePicker } = DatePicker;



/**
 *
 *
 * @export
 * @class Analytics
 * @extends {Component}
 * @description Vista donde se muestra un resumen de las estadisticas del sistema
 */
class Analytics extends Component {

    back = () => {
        this.props.navigate(-1)
    }


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            total_clientes: 0,
            clientes_creados: 0,
            ticket_promedio: { promedioGasto: 0, promedioCantidad: 0 },
            clientes_por_año: [],



            ventas_pendientes: 0,
            pares_limpiados: 0,
            servicios_lux : { ids: [], servicios: 0},
            servicios_everyday: { ids: [], servicios: 0 },
            filters: [],
            fechas: [dayjs().startOf('month'), dayjs().endOf("month")],




            ventas_count: 0,
            clientes_count: 0,
            cotizaciones_count: 0,
            productos_count: 0,
            ventas_servicio: {},
            ventas_clientes: {},
            serie_anual: [],
        }
    }


    componentDidMount() {
        this.props.setShowSearch(false)
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getInfo()
        this.getClientsPerYear()
    }

    /**
    * @memberof Analytics
    * @method getInfo
    * @description Obtiene la informacion del sistema general
    */
    getInfo = ({filters} = {}) => {
        this.setState({ loading: true })
        axios.get('/analytics-dashboard/clientes',{
            params:{
                filters,
                fechas: this.state.fechas,
            }
        })
        .then(({data}) => {
        	this.setState({...data, filters})

        }).catch(error => {
            console.log("error", error);

        }).finally(()=>this.setState({loading: false}))
    }

    /**
    * @memberof Analytics
    * @method getYears
    * @description Retorna una lista de años para el Select de la grafica
    */
    getYears = () => {

        let año_inicio = parseInt(dayjs("2023-01-01").format("YYYY"))
        let año_actual = parseInt(dayjs().format("YYYY"))

        let options = []

        for (let i = año_inicio; i <= año_actual; i++) {
            options.push({
                value: i,
                label: i
            })
        }

        return options

    }

    getClientsPerYear = (year) => {
        this.setState({loading: true})
        axios.get('/analytics-grafica/clientes',{
            params:{
                year
            }
        }).then(response => {
            this.setState({
                ...response.data,
            })

        }).catch(error => {
            console.log("error", error);

        }).finally(()=>{
            this.setState({loading: false})
        })
    }

    /**
     *
     *
     * @memberof FiltrosDrawer
     * @method downloadCSV
     */
    downloadCSV = () => {
        let url = new URL(axios.defaults.baseURL + "/analytics-csv/clientes")

        let { filters } = this.state;

        if (filters) {
            filters.forEach(f => {
                url.searchParams.append('filters[]', JSON.stringify(f))
            });
        }

        if(this.state.fechas){
            url.searchParams.set('fechas', JSON.stringify(this.state.fechas))
        }

        url.searchParams.set('Authorization', sessionStorage.getItem('token'))

        window.open(url, '_blank');
    }

    /**
    * @memberof onChangeDates
    * @description Actualiza las fechas de busqueda
    */
    onChangeDates = (dates) => {
        if (dates) {
            this.setState({ fechas: dates }, () => { this.getInfo() })
        }else{
            this.setState({ fechas: undefined }, () => this.getInfo())
        }
    }

    render() {
        return (
            <>
                <Content>
                    <Row gutter={[16, 16]}>
                    	<Col span={24}>
                    		<Space>
                    			{/*<Button
                            		onClick={()=>this.setState({visibleFilters: true})}
                            		icon={<FilterOutlined />}
                        		/>*/}
                                <RangePicker
                                    style={{ width: "80%" }}
                                    onChange={this.onChangeDates}
                                    defaultValue={this.state.fechas}
                                />
                                <Button
                                    onClick={this.downloadCSV}
                                    icon={<DownloadOutlined color='#' />}
                                >CSV</Button>
                    		</Space> 
                    	</Col>
                        <Col xs={24} sm={12} md={12} lg={8} className="grid">
                            <CardStatistics 
                            	title={<>Total Clients <Tooltip placement="topRight" title={"Total clients recorded in the system"}> <QuestionCircleOutlined style={{fontSize: "14px", color: "black"}}/> </Tooltip></>}
                            	suffix={""} 
                            	monto={this.state?.total_clientes?.toMoney(true, false) ?? 0} 
                            	icon={(color) => <FaUser style={{color: "white"}} />}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} className="grid">
                            <CardStatistics 
                            	title={<>New customers in the period <Tooltip placement="topRight" title={"Customers who registered in the system during this period."}> <QuestionCircleOutlined style={{fontSize: "14px", color: "black"}}/> </Tooltip></>}
                            	suffix={""}  
                            	monto={this.state?.clientes_creados?.toMoney(true, false) ?? 0} 
                            	icon={(color) => <FaUserPlus style={{color: "white"}} />} 
                            />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} className="grid">
                            <CardStatistics
                            	title={<>Average Payouts / Pairs <Tooltip placement="topRight" title={"Shows the average amount spent and pairs purchased by customers for cleaning services"}> <QuestionCircleOutlined style={{fontSize: "14px", color: "black"}}/> </Tooltip></>}
                            	text={<>$ {this.state?.ticket_promedio?.promedioGasto?.toMoney(true, false) ?? 0} <br/> {this.state?.ticket_promedio?.promedioCantidad?.toMoney(true, false) ?? 0} Pairs</>} 
                            	icon={(color) => <FaCreditCard style={{color: "white"}} />} 
                            />
                        </Col>
                        <Col xs={24}>
                        	<Card 
                                title={<Title level={4}>New Customers</Title>}
                                size="small" 
                                extra={<Space>
                                    <Select
                                        style={{minWidth: "100px"}}
                                        defaultValue={2024}
                                        options={this.getYears()}
                                        onChange={(year)=>this.getClientsPerYear(year)}
                                    />
                                </Space>}
                            >
                                <ReactECharts
                                    option={{
                                        grid: {
                                            containLabel: true,
                                        },
                                        title: {
                                            text: ''
                                        },
                                        tooltip: {
                                            formatter: '{b0}: {c0}',
                                        },
                                        xAxis: {
                                            type: 'category',
                                            data: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                                        },
                                        yAxis: {},
                                        series: [
                                            {
                                              data: this.state.clientes_por_año.map(e => e.count),
                                              type: 'bar'
                                            }
                                        ]
                                    }}
                                    style={{ height: 450 }}
                                    //opts={{ renderer: 'svg' }}
                                           
                                />
                        	</Card>
                        </Col>
                    </Row>
                </Content>
                <DrawerFiltros
                    title="Filter Sales"
                    visible={this.state.visibleFilters}
                    onClose={() => this.setState({ visibleFilters: false })}
                    updateFilters={filters => this.getInfo({
                        filters
                    })}
                    fechas={true}
                    clientes={true}
                    servicios={true}
                />
            </>
        )
    }
}

export default props => {
    const { setShow } = useSearch()
    const navigate = useNavigate()
    const [params, setParams] = useSearchParams();



    return <Analytics {...props} setShowSearch={setShow}  navigate={navigate} params={params} />
}
