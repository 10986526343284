import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, ColorPicker, Select, message, Upload, Checkbox, Spin, InputNumber } from 'antd';
import { PlusOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';

import axios from "axios"
import SelectProducto from "../../Widgets/Inputs/SelectProducto";
import SelectVariante from "../../Widgets/Inputs/SelectVariante";

const { Title } = Typography;
const { Option } = Select;


class ModalCuentas extends Component {


    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            tipo: 1,
            producto: null,
        }
    }

    refModalCompras = React.createRef();

    componentDidMount() {


    }

    /**
    * @memberof Transacciones
    * @function onFinish
    * @description Añade un nuevo producto a la orden de compra
    */
    onFinish = (values) => {
        if (this.props.venta_id) {
            this.addProducto(values)
        }
    }

    /**
   * @memberof Transacciones
   * @function addProducto
   * @description Añade un nuevo producto a la orden de compra
   */
     addProducto = (values) => {
        this.setState({ loading: true })
        axios.post('/carrito', {
            venta_id: this.props.venta_id,
            from_admin:true,
            detalles:{
                variante_id: values?.variante_id,
                cantidad: values?.cantidad,
            }
        }).then(response => {
            message.success("Item added")
            this.props.onClose(true)
        }).catch(error => {
            message.error(error?.response?.data?.message ?? "There was an error adding the item")
        }).finally(() => this.setState({ loading: false }))
    }




    render() {

        return (
            <Form
                layout="vertical"
                name="form-cuenta"
                id="form-cuenta"
                ref={this.refModalCompras}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>
                    <Row justify="center" className="mt-2" gutter={[12, 0]}>
                        <Col xs={24}>
                            <Form.Item
                                label="Select Product"
                                name="producto_id"
                                rules={[{
                                    required: true,
                                    message: "Select the product"
                                }]}
                            >
                                <SelectProducto onChange={e => {this.setState({producto: e})}}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>

                            <Form.Item
                                label="Select Variant"
                                name="variante_id"
                                rules={[{
                                    required: true,
                                    message: "Select the variant"
                                }]}
                            >
                                <SelectVariante disabled={!this.state.producto} params={{producto_id: this.state.producto}}/>
                            </Form.Item>
                        </Col>
                       
                        <Col xs={24}>
                            <Form.Item
                                label="Quantity"
                                name="cantidad"
                                rules={[{
                                    required: true,
                                    message: "Specify the quantity"
                                },{
                                    type:"number",
                                    min: 1,
                                    message: "Minimum quantity is 1"
                                }]}
                            >
                                <InputNumber className="w-100" placeholder="Quantity" type="number"/>
                            </Form.Item>
                        </Col>

                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, detalle_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={detalle_id ? "Edit item" : "Add item"}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-cuenta', key: 'submit', htmlType: 'submit' }}
    >
        <ModalCuentas {...props} />
    </Modal>

}