import React, { Component } from 'react';
import {
    Row, Col, Typography, Button,
    Layout, Space, message, List, Card, Dropdown, Tag, Menu, Tooltip, Radio, Badge
} from 'antd'
import { Link, useNavigate } from "react-router-dom";
import axios, { } from 'axios';
import { UnorderedListOutlined, MenuOutlined, CloseCircleOutlined, CheckCircleOutlined, FilterOutlined} from '@ant-design/icons';
import SquareLogo from '../../../Widgets/Icons/Square';
import User from '../../../../Hooks/Logged'
import usePermissions from '../../../../Hooks/usePermissions';
import useSearch from '../../../../Hooks/useSearch';
import PageHeader from '../../../Widgets/PageHeader/PageHeader';
import ModalCliente from '../../Clientes/ModalClientes';
import DrawerFiltros from '../../../Widgets/DrawerFiltros/DrawerFiltros'
import FloatingButton from '../../../Widgets/Floating Button/FloatingButton'
import { IconEye } from '../../../Widgets/Iconos'
import Sort from "../../../Widgets/Sort"
import { ButtonDelete, ButtonEdit } from '../../../Widgets/Buttons';

import '../../../../Styles/Modules/Admin/clientes.scss'
import moment from 'moment';
import { getResponseError } from '../../../Utils';

const { Content } = Layout;
const { Text, Title } = Typography


/**
 *
 *
 * @export
 * @class Clientes
 * @extends {Component}
 * @description Vista donde se listan todos los Clientes
 */
class ClientesList extends Component {



    /**
     *Creates an instance of Clientes.
     * @param {*} props
     * @memberof Clientes
     */
    constructor(props) {
        super(props)
        //let { page, limit, filters, search, sort } = this.props.params
        this.state = {
            loading: false,

            clientes: {
                data: [],
                page: 1,
                limit: 20,
                filters: [],
                total: 0,
                sort: {
                    createdAt: -1
                },
            },
            asesores: {
                data: [],
                limit: 10,
                page: 1,
                search: undefined,
                loading: false
            },
            vendedor_id: undefined,
            seleccionados: {},
            panels: [],
            modalCliente: false,
            search: this.props.search,

            clearCheckbox: false,
            visibleFilters: false,
            optionsVisible: false
        }
    }

    drawerFiltros = React.createRef()

    componentDidMount() {
        this.props.setShowSearch(true)

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getClientes({ filters: this.state.filters, search: this.props.search });
        this.getAsesores()

    }

    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.getClientes({ page: 1, search: this.props.search })
        }
    }



    /**
    * @memberOf Clientes
    * @method getClientes
    * @description Obtiene ls lista de Clientes
    */
    getClientes = ({

        page = this.state.clientes.page,
        limit = this.state.clientes.limit,
        search = this.state.clientes.search,
        filters = this.state.clientes.filters,
        sort = this.state.clientes.sort,

    } = this.state.clientes, { clientes } = this.state
    ) => {
        const params = { page, limit }
        if (sort && Object.values(sort).length > 0) params.sort = JSON.stringify(sort)
        if (search) params.search = search
        if (filters && filters?.length > 0) params.filters = JSON.stringify(filters)

        let clearCheckbox = (page === 1)

        this.setState({ clientes: { ...this.state.clientes, page, limit, search, filters, sort }, clearCheckbox, loading: true })
        axios.get('/clientes', {
            params: {
                page: clientes.data.length < 1 && page !== 1 ? 1 : page,
                limit: clientes.data.length < 1 && page !== 1 ? limit * page : limit,
                search,
                filters,
                sort
            }
        })
            .then(({ data }) => {
                this.setState({
                    clientes: {
                        ...this.state.clientes,
                        data: data.data,
                        total: data.total,
                        search,
                        filters
                    },
                })

            })
            .catch(error => {
                message.error(getResponseError(error.response, 'Error gettint the information'))
            })
            .finally(() => this.setState({ loading: false, clearCheckbox: false }))
    }


    getAsesores = ({ page, limit, search } = this.state.asesores, { asesores } = this.state) => {

        asesores.loading = true;
        asesores.page = page;
        asesores.limit = limit;

        this.setState({ asesores })

        axios.get('/usuarios', {
            params: {
                page, limit, search,
                clientes: false
            }
        })
            .then(response => {

                asesores.data = (page === 1) ? response.data.data : [...asesores.data, ...response.data.data];
                asesores.total = response.data.total
                asesores.pages = response.data.pages
                asesores.loading = false;

                this.setState({ asesores })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.asesores.loading = false
                    return state
                })
            })
    }

    renderEstatus = (status) => {

        switch (status) {
            case 0:
                return <Tag color="#FF4D4F">Desisted / Lost</Tag>
            case 1:
                return <Tag color="#FFE608">Registered</Tag>
            case 2:
                return <Tag color="#5465FF">Contacted</Tag>
            case 3:
                return <Tag color="#F2802E">Already bought something</Tag>
            case 4:
                return <Tag color="#00CD98">Paid</Tag>
            default:
                return <Tag color="#FFE608">Registered</Tag>
        }
    }


    updateEstatus = (cliente_id, estatus) => {
        this.setState({ loading: true })
        axios.put('/clientes', {
            cliente_id,
            estatus
        }).then(success => {
            message.success("Status updated")
            this.getClientes()
        }).catch(error => {
            message.error("Error updating")
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Clientes
     * @description Renderizamos los filtros que estén en el arreglo.
     */
    renderFiltros = ({ filters = [] } = this.state.clientes) => {

        return filters.map(filtro => {
            return <Tag
                closable
                closeIcon={<div className="point" onClick={event => this.deleteFilter(event, filtro)}></div>}
                style={{ lineHeight: '15px', paddingTop: '3px', paddingBottom: '3px' }}
            >
                <small>{filtro?.objectName?.toUpperCase().replace("_", " ")}<br></br></small>
                {filtro?.filter?.range?.join(' - ')}
                {filtro?.label || filtro?.nombre || filtro?.razon_social || filtro?.email || filtro?.folio}
            </Tag>
        });
    }

    /**
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Clientes
     * @description Para eliminar los filtros
     */
    deleteFilter = (event, filtro) => {
        event.preventDefault()
        try {
            let { filters } = this.state.clientes
            let { _id, name } = filtro

            filters = filters.filter(f => {
                if (f.name === name) {
                    if (f.name === 'fecha') return false
                    return (f._id !== _id)
                }
                return true
            })

            this.setState({ clientes: { ...this.state.clientes, filters } }, () => this.getClientes())
        } catch (error) {
            console.log(error)
            this.setState({ visibleFilters: true })
        }
    }

    asignarVendedor = (asesor_id) => {
        this.setState({ loading: true })

        axios.post('/clientes/asignar-asesor', {
            asesor_id, ids: Object.keys(this.state.seleccionados)
        }).then(success => {
            message.success("Vendedor asignado")
            this.getClientes({ page: 1 })
            this.getAsesores({ search: undefined })
        }).catch(error => {
            message.error("Error al asignar vendedor")
            this.getClientes({ page: 1 })
            this.getAsesores({ search: undefined })
        })
    }

    /**
     *
     *
     * @param {*} key
     * @memberof ClientesList
    */
    setSort = (key) => {
        let value;
        switch (this.state.clientes.sort[key]) {
            case 1:
                value = -1
                break;
            case -1:
                value = undefined
                break;
            default:
                value = 1
                break;
        }
        this.getClientes({
            sort: {
                ...this.state.clientes.sort,
                [key]: value
            }
        })
    }

    /**
    * @memberOf Clientes
    * @method onChange
    * @description Cambia de vista de clientes de listados a agrupados
    */
    onChange = ({ target: { value } }) => {
        if (value === "group") this.props.navigate('/admin/crm/clientes')
    };


    render() {
        const { clientes, loading } = this.state
        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title={<>
                        Clients
                    </>}
                    extra={<Space>
                        <Radio.Group
                            options={[
                                { label: <MenuOutlined />, value: "list" },
                                { label: <UnorderedListOutlined />, value: 'group' },
                            ]}
                            value={"list"}
                            onChange={this.onChange}
                            optionType="button"
                            buttonStyle="solid"
                        />
                        <Button
                            onClick={()=>this.setState({visibleFilters: true})}
                            icon={<FilterOutlined />}
                        />
                    </Space>}
                />
                <Content
                    className="admin-content content-bg clientes pd-1"
                    id="clientes-layout"
                >
                    <Row gutter={[24, 24]}>
                        <Col xs={24} className="">
                            <List
                                loading={this.state.loading}
                                className="component-list responsive"
                                itemLayout="horizontal"
                                locale={{ emptyText: "Clients not found" }}
                                dataSource={clientes.data}
                                pagination={{
                                    current: clientes.page,
                                    pageSize: clientes.limit,
                                    total: clientes.total,
                                    pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                                    position: 'bottom',
                                    className: "flex-left ml-1",
                                    showSizeChanger: true,
                                    onChange: (page, limit) => this.getClientes({ page, limit })
                                }}
                                header={
                                    <Row align="middle">
                                        <Col flex="auto">
                                            <Row className="width-100 " align="middle" gutter={[0, 12]}>
                                                <Col xs={{ span: 12, order: 1 }} md={{ span: 4, order: 1 } } style={{ color: "gray" }} >
                                                    <Sort
                                                        sort={this.state.clientes?.sort && this.state.clientes?.sort['nombre']} onClick={() => this.setSort("nombre")} >
                                                        <Text ellipsis strong>Full Name</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={{ span: 12, order: 3 }} md={{ span: 4, order: 2 }} >
                                                    <Sort
                                                        sort={this.state.clientes?.sort && this.state.clientes?.sort["telefono"]} onClick={() => this.setSort("telefono")} >
                                                        <Text ellipsis strong>Phone</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={{ span: 12, order: 4 }} md={{ span: 4, order: 3 }} >
                                                    <Sort
                                                        sort={this.state.clientes?.sort && this.state.clientes?.sort["email"]} onClick={() => this.setSort("email")} >
                                                        <Text ellipsis strong>Email</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={{ span: 12, order: 2 }} md={{ span: 4, order: 5 }} >
                                                    <Sort
                                                        sort={this.state.clientes?.sort && this.state.clientes?.sort["createdAt"]} onClick={() => this.setSort("createdAt")} >
                                                        <Text ellipsis strong>Registration date</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={{ span: 12, order: 5 }} md={{ span: 4, order: 4 }} >
                                                    <Sort
                                                        sort={this.state.clientes?.sort && this.state.clientes?.sort["estatus"]} onClick={() => this.setSort("estatus")} >
                                                        <Text ellipsis strong>Status</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={{ span: 12, order: 6 }} md={{ span: 4, order: 6 }} >
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                }

                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <Row align="middle">
                                                <Col flex="auto">
                                                    <Row className="width-100 " align="middle" gutter={[0, 12]}>
                                                        <Col xs={{ span: 16, order: 1 }} md={{ span: 5, order: 1 }} lg={{ span: 4, order: 1 }} >
                                                            <Text ellipsis strong>{item.nombre} {item.apellidos}</Text>
                                                        </Col>
                                                        <Col xs={{ span: 12, order: 3 }} md={{ span: 4, order: 2 }} lg={{ span: 4, order: 2 }} >
                                                            <Text ellipsis >{item.telefono ? item.telefono : "-"}</Text>
                                                        </Col>
                                                        <Col xs={{ span: 12, order: 4 }} md={{ span: 8, order: 3 }} lg={{ span: 4, order: 3 }} >
                                                            <Text ellipsis >{item.email}</Text>
                                                        </Col>
                                                        <Col xs={{ span: 8, order: 2 }} md={{ span: 24, order: 6 }} lg={{ span: 4, order: 4 }} >
                                                            <Dropdown
                                                                overlay={<Menu>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 0)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`clientes-circle small circle-1`} />
                                                                            <Text>Desisted / Lost</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 1)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`clientes-circle small circle-2`} />
                                                                            <Text>Registered</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 2)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`clientes-circle small circle-3`} />
                                                                            <Text>Contacted</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 3)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`clientes-circle small circle-4`} />
                                                                            <Text>Already bought something</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 4)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`clientes-circle small circle-5`} />
                                                                            <Text>Paid</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                </Menu>}
                                                                className="clientes-dropdown"
                                                                trigger={['click']}
                                                                disabled={!this.props.canEdit}
                                                            >
                                                                {this.renderEstatus(item.estatus)}
                                                            </Dropdown>
                                                        </Col>
                                                        <Col xs={{ span: 16, order: 5 }} md={{ span: 4, order: 4 }} lg={{ span: 4, order: 5 }} >
                                                            <Text ellipsis >{moment(item.createdAt).format("MM/DD/YYYY")}</Text>
                                                        </Col>
                                                        <Col xs={{ span: 8, order: 6 }} md={{ span: 3, order: 5 }} lg={{ span: 4, order: 6 }} >
                                                            <Space wrap direction='horizontal'>
                                                                <Tooltip title={`This client is ${item.square_id ? "" : "not"} registered on square`}>
                                                                    <Badge count={item.square_id ? <CheckCircleOutlined color='#31A078' /> : <CloseCircleOutlined color='red' />}>
                                                                        <Button type='primary'
                                                                            disabled={true}
                                                                            icon={<SquareLogo size="20px" />}
                                                                        />
                                                                    </Badge>
                                                                </Tooltip>
                                                                <Button
                                                                    type='primary'
                                                                    className='btn-primary'
                                                                    icon={<IconEye />}
                                                                    onClick={() => this.props.navigate(`/admin/crm/clientes/detalle/${item._id}`)}
                                                                />
                                                                <ButtonEdit
                                                                    disabled={!this.props.canEdit}
                                                                    onClick={() => this.setState({ modalCliente: true, cliente_id: item._id })} />
                                                                <ButtonDelete
                                                                    disabled={!this.props.canDelete}
                                                                    onConfirm={() => {
                                                                        axios.delete('/clientes', { params: { cliente_id: item._id } })
                                                                            .then((response) => {
                                                                                message.success("It was deleted correctly")
                                                                                this.getClientes()
                                                                            })
                                                                            .catch((error) => {
                                                                                message.error(getResponseError(error.response, "Delete error"));
                                                                                this.getClientes()
                                                                            })
                                                                    }} />

                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </Content>
                {/* </Spin> */}

                {this.props.canCreate && <FloatingButton title="New Client" disabled={!this.props.canCreate} onClick={() => this.setState({ modalCliente: true, cliente_id: null })} />}

                <DrawerFiltros
                    ref={this.drawerFiltros}
                    title="Filter clients"
                    updateFilters={filters => this.getClientes({
                        page: 1,
                        filters
                    })}
                    onClose={() => this.setState({ visibleFilters: false })}
                    visible={this.state.visibleFilters}
                    fechas={true}
                    estatus={{
                        type: 'list',
                        data: [{
                            _id: 0,
                            label: "Desisted / Lost"
                        }, {
                            _id: 1,
                            label: "Registered"
                        }, {
                            _id: 2,
                            label: "Contacted"
                        }, {
                            _id: 3,
                            label: "Alredy bought something"
                        }, {
                            _id: 4,
                            label: "Paid"
                        }]
                    }}
                    csv={"clientes/csv"}
                    params={{filters: this.state.clientes.filters}}
                />

                <ModalCliente
                    visible={this.state.modalCliente}
                    cliente_id={this.state.cliente_id}
                    onClose={(flag) => {
                        this.setState({ modalCliente: false, cliente_id: undefined })
                        if (flag === true)
                            this.getClientes()
                    }}

                />
            </>
        )
    }
}



export default function (props) {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canCreate: ["clientes", "create"],
        canEdit: ["clientes", "edit"],
        canDelete: ["clientes", "delete"],
        canViewDetails: ["clientes", "cliente-detalles", "access"],
        canAssignAsesor: ["clientes", "assign-asesor"]
    })

    const navigate = useNavigate();


    return <ClientesList {...props}
        {...permisos}
        setShowSearch={setShow}
        navigate={navigate}
    />
}