import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Divider, Button, Select } from 'antd';
import axios from "axios"
import dayjs from "dayjs";

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import FormDirecciones from "./FormDirecciones"
import { getResponseError } from "../../Utils";
import Busqueda from "../../Widgets/Busqueda";



/**
 * @class ModalClientes
 * @description Modal para el CRUD de Clientes
 */
class ModalClientes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            asesores: [],
            estados: [],
            paises: [],
            cliente: {
                direcciones: []
            }

        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.cliente_id)
            this.getCliente()

    }

    formRef = React.createRef();

    /**
    * @method getCliente
    * @description Obtener al cliente
    */
    getCliente = () => {
        this.setState({ loading: true })
        axios.get('/clientes/' + this.props.cliente_id)
            .then(({ data }) => {
                this.formRef.current.setFieldsValue({
                    ...data
                })
                this.setState({ cliente: data })
            }).catch(error => {
                message.error('Error getting client')
            }).finally(() => this.setState({ loading: false }))
    }


    /**
    * @method addCliente
    * @description Añade un nuevo registro de cliente
    */
    addCliente = (values) => {
        this.setState({ loading: true })
        axios.post('/clientes', values)
            .then(response => {
                message.success('Created Client!')
                this.props.onClose(true)
                //regresa el _id del cliente para que carge en el modal de ventas
                if(this.props.addNewCliente) this.props.addNewCliente(response.data._id)
            }).catch(error => {
                console.log("error", error);
                message.error(getResponseError(error.response, 'Error creating Client'))
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method updateCliente
    * @description Actualiza un registro de cliente
    */
    updateCliente = (values) => {
        this.setState({ loading: true })
        axios.put('/clientes', values)
            .then(response => {
                message.success('Updated Client!')
                this.props.onClose(true)
            }).catch(error => {
                console.log("error", error);
                message.error(getResponseError(error.response, 'Error updating Client'))
            }).finally(() => this.setState({ loading: false }))
    }


    setPC = (values, index) => {
        if(Array.isArray(values.context)){
            let postal_code = null;
    
            for (let context of values.context) {
                // Verificar si el valor de "id" contiene la palabra postcode
                if (context.id.includes("postcode")) {
                    postal_code = context.text;
                    break;
                }
            }
    
            if(postal_code){

                
                //this.props.refPadre.current.setFieldsValue({ codigo_postal: postal_code, nombre : values.text })
                let av = this.formRef.current.getFieldsValue();
                if(!av.direcciones.length){
                    av.direcciones.push({})
                }
                if(!av.direcciones[index]){
                    av.direcciones[index] ={}
                }
                av.direcciones[index].nombre= values.text;
                av.direcciones[index].codigo_postal = postal_code
                this.formRef.current.setFieldsValue(av)
            }
        }
    }

    /**
   * @method onFinish
   * @description Se ejecuta al hacer submit al formulario
   */
    onFinish = (values) => {
        if (this.props.cliente_id) {
            values.cliente_id = this.props.cliente_id
            this.updateCliente(values)
        } else {
            this.addCliente(values)
        }
    }


    render() {
        window.refPadre = this.formRef
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    className="mt-3"
                    id="form-cliente"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row justify="center" className="center">

                        <Col span={20}>

                            <Form.Item
                                name="nombre"
                                label="Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Name is required',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="apellidos"
                                label="Last Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Last Name is required',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Email is not valid',
                                    },
                                    {
                                        required: true,
                                        message: 'Enter the email',
                                    }, {
                                        max: 50,
                                        message: "Maximum 50 characters"
                                    },{
                                        validator: (_, value) => {
                                            // Transformar el valor a minúsculas
                                            const lowercaseValue = value.toLowerCase();
                                            if (value !== lowercaseValue) this.formRef.current.setFieldsValue({ email: lowercaseValue });
                                            return Promise.resolve();
                                        },
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>



                            <Form.Item
                                name="telefono"
                                label="Phone"
                                rules={[{
                                    required:true,
                                    message:"A phone number is required"
                                }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name='referal'
                                label='Referral'
                                rules={[{
                                    required:true,
                                    message:"The Referral is required"
                                }]}
                            >
                                <Select
                                    options={[
                                        {
                                            value: 1,
                                            label: "Instagram"
                                        },
                                        {
                                            value: 2,
                                            label: "Facebook"
                                        },
                                        {
                                            value: 3,
                                            label: "Google"
                                        },
                                        {
                                            value: 4,
                                            label: "Influencer"
                                        },
                                        {
                                            value: 6,
                                            label: "Referral"
                                        },
                                        {
                                            value: 7,
                                            label: "Street"
                                        },
                                        {
                                            value: 5,
                                            label: "Other"
                                        },
                                    ]}
                                />
                            </Form.Item>

                            <Form.Item
                                name="estatus"
                                label="Status"
                                rules={[
                                    {
                                        required: true,
                                        message: 'status is required',
                                    },
                                ]}
                            >
                                <Select
                                    options={[
                                        {
                                            value: 0,
                                            label: "Desisted / Lost"
                                        },
                                        {
                                            value: 1,
                                            label: "Registered"
                                        },
                                        {
                                            value: 2,
                                            label: "Contacted"
                                        },
                                        {
                                            value: 3,
                                            label: "Already bought something"
                                        },
                                        {
                                            value: 4,
                                            label: "Paid"
                                        }
                                    ]}
                                />
                            </Form.Item>

                            <Form.Item
                                name="tipo"
                                label="Type Customer"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Type Customer is required',
                                    },
                                ]}
                            >
                                <Select
                                    options={[
                                        {
                                            value: 1,
                                            label: "Customer"
                                        },
                                        {
                                            value: 2,
                                            label: "Influencer"
                                        },
                                    ]}
                                />
                            </Form.Item>


                            <Divider> Addresses </Divider>

                            <Form.List name="direcciones">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }, index) => (
                                            <Row gutter={[8, 8]}>
                                            <Col span={24}>
                                              <Form.Item
                                                {...restField}
                                                name={[name, "nombre"]}
                                                rules={[{ 
                                                  //required: true,
                                                   message: "Name is required" }]}
                                              >
                                                <Input placeholder="Name" />
                                              </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                              <Form.Item
                                                {...restField}
                                                name={[name, "codigo_postal"]}
                                                rules={[{ required: true, message: "Zip Code is required" }]}
                                              >
                                                <Input placeholder="Zip Code" />
                                              </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                              <Form.Item {...restField} name={[name, "localizacion"]}>
                                                <Busqueda 
                                                  onSelect={(value) => this.setPC(value, name)} 
                                                />
                                              </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                              <Form.Item {...restField} name={[name, "notas"]}>
                                                <Input.TextArea rows={3} placeholder="Specifications" />
                                              </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                              <Form.Item {...restField} name={[name, "especificaciones"]}>
                                                <Input.TextArea rows={2} placeholder="Unit Number" />
                                              </Form.Item>
                                            </Col>
                                            <Divider />
                                            <Form.Item noStyle {...restField} name={[name, "_id"]}>
                                                <input type="hidden"/>
                                              </Form.Item>
                                          </Row>
                                          
                                        
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Add Address
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>

                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { visible = false, onClose = () => { }, cliente_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={cliente_id ? "Edit Client" : "Create Client"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancel"
        okText="Save"
        okButtonProps={{ form: 'form-cliente', key: 'submit', htmlType: 'submit' }}
        width={700}
    >
        <ModalClientes  {...props} />
    </Modal>

}