import React, { Component } from 'react';
import {
    Row, Col, Typography, Button, Popconfirm,
    Layout, Space, message, List, Card, Dropdown, Tag, Menu, Collapse, Select, Checkbox, Tooltip, Radio,
    Badge
} from 'antd'
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { DeleteOutlined, PlusOutlined, UnorderedListOutlined, MenuOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import SquareLogo from '../../../Widgets/Icons/Square';
import User from '../../../../Hooks/Logged'
import usePermissions from '../../../../Hooks/usePermissions';
import useSearch from '../../../../Hooks/useSearch';


import ModalCliente from '../../Clientes/ModalClientes';
import DrawerFiltros from '../../../Widgets/DrawerFiltros/DrawerFiltros'
import FloatingButton from '../../../Widgets/Floating Button/FloatingButton'
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import { ButtonDelete, ButtonEdit } from '../../../Widgets/Buttons';
import { IconEye } from '../../../Widgets/Iconos'
import PageHeader from '../../../Widgets/PageHeader/PageHeader';

import '../../../../Styles/Modules/Admin/clientes.scss'

import moment from 'moment';
import SelectVendedor from './SelectVendedor';

const { Content } = Layout;
const { Text } = Typography
const { Option } = Select
/**
 *
 *
 * @export
 * @class Clientes
 * @extends {Component}
 * @description Vista donde se listan todos los Clientes
 */
class Clientes extends Component {



    /**
     *Creates an instance of Clientes.
     * @param {*} props
     * @memberof Clientes
     */
    constructor(props) {
        super(props)

        this.state = {
            loading: false,

            clientes: {
                data: [],
                skip: 0,
                limit: 50,
                filters: [],
                total: 0,
            },
            asesores: {
                data: [],
                page: 1,
                limit: 10,
                total: 0
            },
            sort: 1,
            panels: [0, 1, 2, 3, 4],
            seleccionados: {},
            clearCheckbox: false,
            modalCliente: false,
            search: this.props.search,
            visibleFilters: false,
            optionsVisible: false,
            first: true
        }
    }

    drawerFiltros = React.createRef()

    componentDidMount() {

        this.props.setShowSearch(true)

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getClientes({ filters: this.state.filters, search: this.props.search });
        this.getAsesores()

        const self = this
        window.onscroll = function () {
            if (Math.ceil(window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 50) && !self.state.loading && self.state.clientes.skip < self.state.clientes.total) {
                self.getClientes({ skip: (parseInt(self.state.clientes.skip) + parseInt(self.state.clientes.limit)) })
            }
        }
    }

    componentWillUnmount() {
        window.onscroll = null
    }

    componentDidUpdate(prevProps) {
        if (prevProps.search !== this.props.search) {
            this.getClientes({ search: this.props.search, skip: 0 })
        }
    }

    /**
    * @memberOf Clientes
    * @method getClientes
    * @description Obtiene ls lista de Clientes
    */
    getClientes = ({
        skip = this.state.clientes.skip,
        limit = this.state.clientes.limit,
        search = this.state.clientes.search,
        filters = this.state.clientes.filters,
        panels = this.state.panels,
        sort = this.state.sort
    } = this.state.clientes, { } = this.state
    ) => {
        const params = { limit, skip }
        if (search) params.search = search
        console.log("search", search);
        if (filters && filters?.length > 0) params.filters = JSON.stringify(filters)
        let clearCheckbox = (skip === 0)

        this.setState({ loading: true, panels, clearCheckbox, sort })
        axios.get('/clientes', {
            params: {
                skip: this.state.first ? 0 : skip,
                limit: (this.state.first && skip > 0) ? skip : limit,
                panels,
                search: this.props.search,
                filters,
                group: "estatus",
                sort
            }
        })
            .then(({ data }) => {
                this.setState({
                    clientes: {
                        ...data,
                        data: {
                            0: (skip == 0) ? (data.data[0] ?? []) : this.state.clientes.data[0].concat(data.data[0] ?? []),
                            1: (skip == 0) ? (data.data[1] ?? []) : this.state.clientes.data[1].concat(data.data[1] ?? []),
                            2: (skip == 0) ? (data.data[2] ?? []) : this.state.clientes.data[2].concat(data.data[2] ?? []),
                            3: (skip == 0) ? (data.data[3] ?? []) : this.state.clientes.data[3].concat(data.data[3] ?? []),
                            4: (skip == 0) ? (data.data[4] ?? []) : this.state.clientes.data[4].concat(data.data[4] ?? []),
                        },
                        skip,
                        limit,
                        filters: filters
                    },
                    first: false
                })
            })
            .catch(error => {
                console.log(error)
                message.error('Error getting information')
            })
            .finally(() => this.setState({ loading: false, clearCheckbox: false }))
    }

    /**
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Clientes
     * @description Para eliminar los filtros
     */
    deleteFilter = (event, filtro) => {
        event.preventDefault()
        try {
            let { filters } = this.state.clientes
            let { _id, name } = filtro

            filters = filters.filter(f => {
                if (f.name === name) {
                    if (f.name === 'fecha') return false
                    return (f._id !== _id)
                }
                return true
            })
            this.getClientes({ filters, skip: 0 })
        } catch (error) {
            console.log(error)
            this.setState({ visibleFilters: true })
        }
    }

    /**
     *
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Clientes
     * @description Renderizamos los filtros que estén en el arreglo.
     */
    renderFiltros = ({ filters } = this.state.clientes) => {
        return filters.map(filtro => {
            return <Tag
                closable
                closeIcon={<div className="point" onClick={event => this.deleteFilter(event, filtro)}></div>}
                style={{ lineHeight: '15px', paddingTop: '3px', paddingBottom: '3px' }}
            >
                <small>{filtro?.objectName?.toUpperCase().replace("_", " ")}<br></br></small>
                {filtro?.filter?.range?.join(' - ')}
                {filtro?.label || filtro?.nombre || filtro?.razon_social || filtro?.email || filtro?.folio}
            </Tag>
        });
    }

    getAsesores = ({ page, limit, search } = this.state.asesores, { asesores } = this.state) => {

        asesores.loading = true;
        asesores.page = page;
        asesores.limit = limit;

        this.setState({ asesores })

        axios.get('/usuarios', {
            params: {
                page, limit, search,
                clientes: false
            }
        })
            .then(response => {
                asesores.data = (page === 1) ? response.data.data : [...asesores.data, ...response.data.data];
                asesores.total = response.data.total
                asesores.pages = response.data.pages
                asesores.loading = false;

                this.setState({ asesores })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.asesores.loading = false
                    return state
                })
            })
    }

    canAssignAsesor = (asesor_id) => {
        this.setState({ loading: true })

        axios.post('/clientes/asignar-asesor', {
            asesor_id, ids: Object.keys(this.state.seleccionados)
        }).then(success => {
            message.success("Asesor asignado")
            this.getClientes({ skip: 0 })
            this.getAsesores({ search: undefined })
        }).catch(error => {
            message.error("Error al asignar asesor")
        })
    }

    /**
   * @memberOf Clientes
   * @method onChange
   * @description Cambia de vista de clientes de listados a agrupados
   */
    onChange = ({ target: { value } }) => {
        if (value === "list") this.props.navigate('/admin/crm/clientes/listado')
    };

    render() {

        const { clientes, loading } = this.state

        let estatus = [
            {
                _id: 0,
                name: 'cancelados',
                label: "Desisted / Lost"
            },
            {
                _id: 1,
                name: 'registrados',
                label: "Registered"
            },
            {
                _id: 2,
                name: 'contactado',
                label: "Contacted"
            },
            {
                _id: 3,
                name: 'comprado',
                label: "Already bought something"
            },
            {
                _id: 4,
                name: 'pagado',
                label: "Paid"
            }
        ]


        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title={"Clients"}
                    extra={<Space>
                        <Radio.Group
                            options={[
                                { label: <MenuOutlined />, value: "list" },
                                { label: <UnorderedListOutlined />, value: 'group' },
                            ]}
                            value={"group"}
                            onChange={this.onChange}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </Space>}
                />
                <Content
                    className="admin-content content-bg clientes pd-1"
                    id="clientes-layout"
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Collapse
                                bordered={false}
                                className="clientes-collapse w-100"
                                activeKey={this.state.panels}
                                onChange={e => this.getClientes({ skip: 0, panels: e })}
                            >
                                {estatus.map(({ _id, name, label }) => {
                                    return <Collapse.Panel
                                        key={_id}
                                        header={label}
                                        className={`clientes-tab tab-${name}`}
                                    >
                                        <List
                                            loading={clientes.loading}
                                            className="component-list"
                                            itemLayout="horizontal"
                                            locale={{ emptyText: this.state.loading ? "Loading" : "Clients not found" }}
                                            dataSource={this.state.clientes.data[_id] ?? []}
                                            renderItem={(cliente, index) => (
                                                <List.Item className="component-list-item" key={cliente._id}>
                                                    <Card className="card-list">
                                                        <Row align="middle">

                                                            <Col flex="auto">
                                                                <Row className="width-100 " gutter={[0, 12]}>
                                                                    <Col xs={5} className="center" >
                                                                        <Text ellipsis strong>{cliente.nombre} {cliente.apellidos}</Text>
                                                                    </Col>
                                                                    <Col xs={5} className="center" >
                                                                        <Text ellipsis >{cliente.email}</Text>
                                                                    </Col>
                                                                    <Col xs={5} className="center" >
                                                                        <Text ellipsis >{cliente.telefono ?? "-"}</Text>
                                                                    </Col>
                                                                    <Col xs={5} className="center" >
                                                                        <Text ellipsis >{moment(cliente.createdAt).format("MM/DD/YYYY")}</Text>
                                                                    </Col>
                                                                    <Col xs={4} className="center" >
                                                                        <Space wrap>
                                                                            <Tooltip title={`This client is ${cliente.square_id ? "" : "not"} registered on square`}>
                                                                                <Badge count={cliente.square_id ? <CheckCircleOutlined color='#31A078' /> : <CloseCircleOutlined color='red' />}>
                                                                                    <Button type='primary'
                                                                                        disabled={true}
                                                                                        icon={<SquareLogo size="20px" />}
                                                                                    />
                                                                                </Badge>
                                                                            </Tooltip>
                                                                            <Link to={`/admin/crm/clientes/detalle/${cliente._id}`} >
                                                                                <Button type='primary'
                                                                                    disabled={false}
                                                                                    className='btn-primary' icon={<IconEye />} />
                                                                            </Link>
                                                                            <ButtonEdit onClick={() => this.setState({ modalCliente: true, cliente_id: cliente._id })}
                                                                                disabled={!this.props.canEdit}
                                                                            />
                                                                            <ButtonDelete disabled={!this.props.canDelete} onConfirm={() => axios.delete('/clientes', { params: { cliente_id: cliente._id } })
                                                                                .then((response) => {
                                                                                    message.success(response?.data?.message)
                                                                                    this.getClientes()
                                                                                })
                                                                                .catch((error) => {
                                                                                    message.error(error?.response?.data?.message);
                                                                                })} />
                                                                        </Space>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </List.Item>
                                            )}
                                        />
                                    </Collapse.Panel>
                                })}
                            </Collapse>
                        </Col>
                    </Row>
                </Content>

                {this.props.canCreate && <FloatingButton title="New Client" disabled={!this.props.canCreate} onClick={() => this.setState({ modalCliente: true, cliente_id: null })} />}

                <DrawerFiltros
                    ref={this.drawerFiltros}
                    title="Filter Clients"
                    updateFilters={filters => this.getClientes({
                        skip: 0,
                        filters
                    })}
                    onClose={() => this.setState({ visibleFilters: false })}
                    visible={this.state.visibleFilters}
                    fechas={true}
                    asesores={true}
                    estatus={{
                        type: 'list',
                        data: [{
                            _id: 0,
                            label: "Desisitío / Perdido"
                        }, {
                            _id: 1,
                            label: "Registrado"
                        }, {
                            _id: 2,
                            label: "Contactado"
                        }, {
                            _id: 3,
                            label: "Ya compró algo"
                        }, {
                            _id: 4,
                            label: "Pagado"
                        }]
                    }}
                />

                <ModalCliente
                    visible={this.state.modalCliente}
                    cliente_id={this.state.cliente_id}
                    onClose={(flag) => {
                        this.setState({ modalCliente: false, cliente_id: undefined })
                        if (flag === true)
                            this.getClientes({ skip: 0, limit: 50, })
                    }}

                />


            </>
        )
    }
}

export default function Vista(props) {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canCreate: ["clientes", "create"],
        canEdit: ["clientes", "edit"],
        canDelete: ["clientes", "delete"],
        canViewDetails: ["clientes", "cliente-detalles"],
        canAssignAsesor: ["clientes", "assign-asesor"]
    })

    const navigate = useNavigate();
    const location = useLocation();


    return <Clientes {...props}
        user={user}
        {...permisos}
        setShowSearch={setShow}
        navigate={navigate}
        location={location}
    />
}